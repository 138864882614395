import React from 'react'
import { useAsync } from 'react-async'
import { Link } from 'react-router-dom'
import { getCourses } from '../../lib/api'
import styled from 'styled-components'
import Allies from '../../components/Allies'
import Card from '../../components/Card'
import Carousel, { getResponsiveConfig } from '../../components/Carousel'
import Heading from '../../components/Heading'
import Icon from '../../components/Icon'
import Loading from '../Loading'
import Page from '../../components/Page'
import Text from '../../components/Text'
import { useNotification } from '../../context/notificationContext'
import { colors, spacing } from '../../tokens'
import ImageCourseDefault from '../../imagenes/course-default.png'
import Banner from './components/Banner'

interface Course {
  _id: string
  nombre: string
  descripcion: string
  slug: string
  imagen: string
  inscritos: number
  tieneCosto: boolean
}

function Courses() {
  const { notify } = useNotification()
  const { data = [], isLoading } = useAsync({
    promiseFn: getCourses,
    onReject: error => notify(error.message, 'error'),
  })

  if (isLoading) {
    return <Loading />
  }

  return (
    <Page>
      <Banner />
      {data.map((category: any, index: number) => (
        <div key={index}>
          <TitleCourses key={category._id}>
            <Heading size={2}>{category.name}</Heading>
          </TitleCourses>
          <Carousel
            responsive={getResponsiveConfig({
              largeDesktop: 4,
              desktop: 3,
              tablet: 2,
              mobile: 1,
            })}
          >
            {category.courses.map((course: Course, index: number) => (
              <WrapperLink
                data-testid="wrapper-div-course"
                key={index}
                to={`/cursos/${course.slug}`}
              >
                {!course.tieneCosto && (
                  <WrapperPro>
                    <Text status={'white'}>GRATIS</Text>
                  </WrapperPro>
                )}
                <Card fillContent={true}>
                  <Card.Header
                    backgroundImage={course.imagen || ImageCourseDefault}
                  />
                  <Card.Body>
                    <Detail>
                      <div>
                        <Heading size={2}>{course.nombre}</Heading>
                        <Text>{course.descripcion}</Text>
                      </div>
                      <EnrolledUsers>
                        <Icon name="users" status="primary" />
                        <Text status="primary">{`${course.inscritos ||
                          '0'}`}</Text>
                      </EnrolledUsers>
                    </Detail>
                  </Card.Body>
                </Card>
              </WrapperLink>
            ))}
          </Carousel>
        </div>
      ))}
      <Allies />
      <Separator />
    </Page>
  )
}

const TitleCourses = styled.div`
  padding: ${spacing.large} ${spacing.xsmall} ${spacing.medium};
`

const EnrolledUsers = styled.div`
  align-items: center;
  display: flex;
  justify-content: flex-end;
  margin-top: ${spacing.xsmall};
`

const WrapperLink = styled(Link)`
  display: block;
  height: 100%;
  position: relative;
  margin: 0 ${spacing.xsmall};
  text-decoration: none;
  &:hover {
    opacity: 0.9;
  }
`

const Detail = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
`

const WrapperPro = styled.div`
  background: ${colors.success().color};
  border-radius: 0px 3px 0px 3px;
  padding: ${spacing.xxsmall} ${spacing.xsmall};
  position: absolute;
  top: 0px;
  right: 0px;
`

const Separator = styled.div`
  margin: ${spacing.xlarge} 0px;
`

export default Courses
