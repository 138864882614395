import React from 'react'
import styled from 'styled-components'
import Heading from '../../../../components/Heading'
import Text from '../../../../components/Text'
import WhatsAppLink from '../../../../components/WhatsAppLink'
import BankAccount from '../BankAccount'
import PayPal from '../PayPal'
import { spacing } from '../../../../tokens'

interface Props {
  paypalId: string
  amountPaid?: string
}

function TypesOfPayment({ paypalId, amountPaid }: Props) {
  return paypalId === '' ? (
    <Heading size={3}>
      Por favor escríbenos a{' '}
      <strong>
        <a href="mailto:l.gomez@alau.org">l.gomez@alau.org</a>
      </strong>{' '}
      y uno de nuestros asesores educativos se pondrá en contacto en seguida.
    </Heading>
  ) : (
    <>
      <PayPal id={paypalId} />
      <Heading size={3}>
        <strong>Pago con despósito o transferencia</strong>
      </Heading>
      <Text>{amountPaid}</Text>
      <BankAccount
        bank={'pichincha'}
        account={'# Cuenta corriente: 2100116577'}
        description={'BANCO DEL PICHINCHA - TIENDAS MI VECINO'}
      />
      <BankAccount
        bank={'produbanco'}
        account={'# Cuenta ahorros: 12071060893'}
        description={'PRODUBANCO - SERVIPAGOS - PAGO ÁGIL'}
      />
      <BankGeneralInfo>
        <Heading size={3}>Datos para el depósito ó transferencia</Heading>
        <Text>Nombre: Instituto de Educación Online alaU</Text>
        <br />
        <Text>RUC: 0190416704001</Text>
        <br />
        <Text>Correo: info@alau.org</Text>
        <br />
        <Text>Confirma tu pago al WhatsApp +593968098077</Text>
        <WhatsAppLink
          title="Confirma tu pago aquí"
          messageBody="Quiero confirmar el pago de mi suscripción"
        />
      </BankGeneralInfo>
    </>
  )
}

const BankGeneralInfo = styled.div`
  padding: ${spacing.small} 0px;
`
export default TypesOfPayment
