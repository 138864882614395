import React from 'react'
import styled from 'styled-components'

import { colors, spacing } from '../../../../tokens'
import { ContentListItem } from '../../../../lib/formatApiObjects'
import ContentWithProgress from '../ContentWithProgress'
import Text from '../../../../components/Text'
import { useCourseContext } from '../../context/CourseProvider'
import { SET_CURRENT_CONTENT } from '../../reducers/courseReducer'

interface Props {
  contents: ContentListItem[]
  onClickContent(contentId: string): void
}

function ContentMenu({ contents, onClickContent }: Props) {
  const { courseWithContents, dispatchCourse } = useCourseContext()
  const currentContent = courseWithContents.currentContent

  function handleClickContent(contentId: string): void {
    dispatchCourse({
      type: SET_CURRENT_CONTENT,
      payload: contentId,
    })
    onClickContent(contentId)
  }

  if (!contents || !contents.length) {
    return (
      <WrapperMessage>
        <Text>No se han encontrado contenidos para este curso</Text>
      </WrapperMessage>
    )
  }

  const contentColors = [
    colors.primary().color,
    colors.success().color,
    colors.warning().color,
    colors.danger().color,
    colors.info().color,
  ]

  function getColor(index: number): string {
    if (index > contentColors.length - 1) {
      const newIndex = index % contentColors.length
      return contentColors[newIndex]
    }
    return contentColors[index]
  }

  function calculatePercentage(progress: number, total: number): number {
    if (total === 0) {
      return 100
    }
    return Math.round((progress * 100) / total)
  }

  return (
    <>
      {contents.map((content: ContentListItem, index: number) => {
        const calculatedPercentage = calculatePercentage(
          content.progress,
          content.lessons,
        )
        return (
          <ContentWithProgress
            key={index}
            _id={content._id}
            title={content.title}
            percentage={calculatedPercentage}
            progress={`${calculatedPercentage}%`}
            color={getColor(index)}
            onClick={() => handleClickContent(content._id || '')}
            isCurrent={content._id === currentContent ? true : false}
          />
        )
      })}
    </>
  )
}

const WrapperMessage = styled.div`
  padding: ${spacing.large};
  text-align: center;
`

export default ContentMenu
