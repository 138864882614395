import React, { useEffect } from 'react'
import ReactDOM from 'react-dom'
import styled, { keyframes, css } from 'styled-components'
import Icon from '../../components/Icon'
import { colors, spacing } from '../../tokens'

interface ModalProps {
  /** The content to display inside the card. */
  children?: React.ReactNode
  /** If the modal has white background. */
  hasBackground?: boolean
  /** Handler to be called when the close button is clicked */
  onClose?(): void
}

const modalRoot = document.createElement('div')
modalRoot.setAttribute('id', 'modal-root')
document.body.appendChild(modalRoot)

function Modal({ children, onClose, hasBackground = true }: ModalProps) {
  const el = document.createElement('div')

  useEffect(() => {
    modalRoot.appendChild(el)
    document.body.style.overflow = 'hidden'

    return () => {
      document.body.style.overflow = ''
      modalRoot.removeChild(el)
    }
  })

  return el
    ? ReactDOM.createPortal(
        <Overlay hasBackground={hasBackground}>
          <Body>{children}</Body>
          <CloseButton onClick={onClose}>
            <Icon name="close" size="small" />
          </CloseButton>
        </Overlay>,
        el,
      )
    : null
}

const fadeIn = keyframes`from { opacity: 0; }`

const Overlay = styled.div<{ hasBackground: boolean }>`
  animation: ${fadeIn} 200ms ease-out;
  ${props =>
    props.hasBackground
      ? css`
          background-color: ${colors.white().color};
        `
      : css`
          background-color: ${colors.base.grayBackground};
        `}
  display: block;
  height: 100%;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000;
`

const CloseButton = styled.div`
  background: ${colors.base.white};
  border-radius: 50%;
  cursor: pointer;
  position: absolute;
  right: ${spacing.xsmall};
  top: ${spacing.xsmall};
`

const Body = styled.div`
  padding: ${spacing.small} ${spacing.small};
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
`

export default Modal
