import React from 'react'
import styled from 'styled-components'
import { mediaQueries } from '../../tokens'

import Section from './Section'

interface Props {
  /** The content to display inside the layout. */
  children?: React.ReactNode
}

const Layout = ({ children }: Props) => {
  return <Wrapper data-testid="wrapper-div">{children}</Wrapper>
}

Layout.Section = Section

const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: stretch;
  @media ${mediaQueries.largeMin} {
    flex-wrap: nowrap;
  }
`

export default Layout
