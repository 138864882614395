import React from 'react'
import styled from 'styled-components'
import Button from '../../components/Button'
import Heading from '../../components/Heading'
import Text from '../../components/Text'
import WhatsAppLink from '../../components/WhatsAppLink'
import ImageAccessPro from '../../imagenes/image-access-pro.png'
import { colors, mediaQueries, spacing } from '../../tokens'

function PaymentFailed() {
  return (
    <Wrapper>
      <Content>
        <Image alt="Subscripción" src={ImageAccessPro} />
        <Header>
          <Heading size={2}>Problemas con tu pago</Heading>
          <Subtitle>
            <Text>
              Al parece tu pago no se completó, por favor inténtalo nuevamente o
              escríbenos a nuestro WhatsApp +593968098077
            </Text>
          </Subtitle>
          <WhatsAppLink messageBody="Dudas acerca del pago de mi suscripción" />
          <Button to="/" replace>
            Ir a la página principal
          </Button>
        </Header>
      </Content>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  background: ${colors.base.white};
  display: flex;
  position: relative;
  min-height: 100%;
  width: 100%;
  @media ${mediaQueries.mediumMax} {
    height: auto;
  }
`

const Content = styled.div`
  margin: 0 auto;
  width: 100%;
  position: relative;
  padding: 0px ${spacing.small} ${spacing.small};
  @media ${mediaQueries.mediumMin} {
    width: 768px;
  }
`

const Image = styled.img`
  height: 120px;
  margin: 0 auto;
  margin-top: ${spacing.small};
  display: block;
  @media ${mediaQueries.mediumMin} {
    height: 180px;
  }
`

const Header = styled.div`
  text-align: center;
`

const Subtitle = styled.div`
  margin-top: ${spacing.xsmall};
`

export default PaymentFailed
