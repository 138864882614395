import React from 'react'
import styled from 'styled-components'
import Heading from '../../../components/Heading'
import { colors, spacing } from '../../../tokens'
import Monthly from './Colombia/Monthly'
import Monthly5 from './Colombia/Monthly5'
import Monthly50 from './Colombia/Monthly50'
import Annual from './Colombia/Annual'
import Annual5 from './Colombia/Annual5'
import Annual50 from './Colombia/Annual50'

function PayU({ typeOfPlan }) {
  return (
    <Wrapper>
      <Heading size={3}>
        <strong>Pago con tarjeta de crédito</strong>
      </Heading>
      <ContentPayU>
        {typeOfPlan === 'mensual' && <Monthly />}
        {typeOfPlan === 'mensual5' && <Monthly5 />}
        {typeOfPlan === 'mensual50' && <Monthly50 />}
        {typeOfPlan === 'anual' && <Annual />}
        {typeOfPlan === 'anual5' && <Annual5 />}
        {typeOfPlan === 'anual50' && <Annual50 />}
      </ContentPayU>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: block;
  padding: 0px 0px ${spacing.small};
  margin-bottom: ${spacing.small};
  :not(:last-child) {
    border-bottom: 1px solid ${colors.light().background};
  }
`

const ContentPayU = styled.div`
  margin-top: ${spacing.small};
`

export default PayU
