import React from 'react'

export default function Monthly() {
  return (
    <form
      method="post"
      action="https://gateway.payulatam.com/ppp-web-gateway/pb.zul"
      acceptCharset="UTF-8"
    >
      <input
        type="image"
        border="0"
        alt=""
        src="https://www.payulatam.com/img-secure-2015/boton_pagar_mediano.png"
        onClick="this.form.urlOrigen.value = window.location.href;"
      />
      <input
        name="buttonId"
        type="hidden"
        value="0fFkLKuvKShFjhEzWQdP3I6Kjb8Z6XVF/0SZ5c6GMp+R1ucyiQIhrg=="
      />
      <input name="merchantId" type="hidden" value="648157" />
      <input name="accountId" type="hidden" value="650624" />
      <input name="description" type="hidden" value="Suscripción anual alaU" />
      <input name="referenceCode" type="hidden" value="02" />
      <input name="amount" type="hidden" value="180000.00" />
      <input name="tax" type="hidden" value="0.00" />
      <input name="taxReturnBase" type="hidden" value="0.00" />
      <input name="currency" type="hidden" value="COP" />
      <input name="lng" type="hidden" value="es" />
      <input
        name="approvedResponseUrl"
        type="hidden"
        value="https://alau.org/payment_success"
      />
      <input
        name="declinedResponseUrl"
        type="hidden"
        value="https://alau.org/payment_failed"
      />
      <input
        name="pendingResponseUrl"
        type="hidden"
        value="https://alau.org/payment_success"
      />
      <input name="sourceUrl" id="urlOrigen" value="" type="hidden" />
      <input name="buttonType" value="SIMPLE" type="hidden" />
      <input
        name="signature"
        value="d69c1ce6f8066440556d311cc678977f51746fde848892b109ac0de74a7edff2"
        type="hidden"
      />
    </form>
  )
}
