const hasNumber = value => {
  return new RegExp(/[0-9]/).test(value)
}

const hasMixed = value => {
  return new RegExp(/[a-z]/).test(value) && new RegExp(/[A-Z]/).test(value)
}

const hasSpecial = value => {
  return new RegExp(/[!#@$%^&*)(+=._-]/).test(value)
}

export const strengthIndicator = value => {
  let strengths = 0
  if (value.length > 5) strengths++
  if (value.length > 7) strengths++
  if (hasNumber(value)) strengths++
  if (hasSpecial(value)) strengths++
  if (hasMixed(value)) strengths++
  return strengths
}

export const strengthMessage = count => {
  if (count < 2) return 'Contraseña insegura'
  if (count < 3) return 'Aún puedes mejorar tu contraseña'
  if (count < 4) return 'Aún puedes mejorar tu contraseña'
  if (count < 5) return 'Contraseña casi perfecta'
  if (count < 6) return ''
}

export const strengthColor = count => {
  if (count < 2) return 'danger'
  if (count < 3) return 'warning'
  if (count < 4) return 'warning'
  if (count < 5) return 'success'
  if (count < 6) return 'success'
}
