import React from 'react'
import styled from 'styled-components'
import Link from '../Link'
import ImageWhatsapp from '../../imagenes/whatsapp.png'
import { spacing } from '../../tokens'

interface Props {
  phoneNumber?: string
  title?: string
  messageBody?: string
}

function WhatsAppLink({
  phoneNumber = '593968098077',
  title = 'Escríbenos aquí',
  messageBody = '',
}: Props) {
  return (
    <Wrapper>
      <Link
        href={`https://wa.me/${phoneNumber}?text=${encodeURIComponent(
          messageBody,
        )}`}
      >
        <Image src={ImageWhatsapp} alt={title} />
        <br />
        {title}
      </Link>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  margin: ${spacing.small} 0px;
`

const Image = styled.img`
  width: 42px;
`

export default WhatsAppLink
