import {
  FETCH_PRACTICE_REQUEST,
  FETCH_PRACTICE_SUCCESS,
  FETCH_PRACTICE_FAILURE,
} from '../actions/types'
import { combineReducers } from 'redux'

const attemp = (state = {}, action) => {
  switch (action.type) {
    case FETCH_PRACTICE_SUCCESS:
      return { ...action.response.simulador }
    default:
      return state
  }
}

const isFetching = (state = false, action) => {
  switch (action.type) {
    case FETCH_PRACTICE_REQUEST:
      return true
    case FETCH_PRACTICE_SUCCESS:
      return false
    default:
      return state
  }
}

const errorMessage = (state = null, action) => {
  switch (action.type) {
    case FETCH_PRACTICE_FAILURE:
      return action.message
    case FETCH_PRACTICE_REQUEST:
    case FETCH_PRACTICE_SUCCESS:
      return null
    default:
      return state
  }
}

const practice = combineReducers({
  attemp,
  isFetching,
  errorMessage,
})

export default practice
