import React from 'react'
import styled from 'styled-components'
import { colors, fonts, spacing } from '../../tokens'

interface RadioProps {
  /** HTML ID of the component */
  id?: string
  /** The name of the radio */
  name: string
  /** Horizontal align for items */
  align?: 'left' | 'center'
  /** The direction in which the options should be laid out */
  direction?: 'horizontal' | 'vertical'
  /** The value of the currently-selected option */
  selected?: string
  /** If true all options in the group will be disabled */
  readOnly?: boolean
  /** Callback function which is called when the user selects an option */
  onChange?: Function
  children: JSX.Element | JSX.Element[]
}

const Radio = ({
  align = 'left',
  direction = 'horizontal',
  name,
  selected,
  readOnly,
  onChange,
  children,
}: RadioProps) => (
  <Radio.Element direction={direction} align={align}>
    {React.Children.map(children, child =>
      React.cloneElement(child, {
        name: name,
        checked: selected === child.props.value,
        readOnly: readOnly || child.props.readOnly,
        onChange: onChange,
      }),
    )}
  </Radio.Element>
)

export interface RadioOptionProps {
  id?: string
  value?: any
  readOnly?: boolean
  children: string | JSX.Element | JSX.Element[]
  direction?: 'horizontal' | 'vertical'
}

const Label = styled.div``
const CheckMark = styled.div``
const RadioOption = ({
  direction = 'horizontal',
  readOnly = false,
  children,
  ...props
}: RadioOptionProps) => (
  <RadioOption.Element readOnly={readOnly} direction={direction}>
    <input id={props.id} type="radio" readOnly={readOnly} {...props} />
    {direction === 'horizontal' ? (
      <>
        <CheckMark />
        <Label>{children}</Label>
      </>
    ) : (
      <>
        <Label>{children}</Label>
        <CheckMark />
      </>
    )}
  </RadioOption.Element>
)

RadioOption.Element = styled.label<{
  readOnly: boolean
  direction: 'horizontal' | 'vertical'
}>`
  cursor: pointer;
  user-select: none;
  pointer-events: ${props => (props.readOnly ? 'none' : null)};
  display: flex;
  flex-direction: ${props =>
    props.direction === 'horizontal' ? 'row' : 'column'};
  align-items: center;
  justify-content: flex-start;
  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
  }
  ${Label} {
    opacity: ${props => (props.readOnly ? 0.5 : null)};
    font-size: ${fonts.size.default};
    padding-top: ${spacing.xxsmall};
    margin-left: ${props =>
      props.direction === 'horizontal' ? spacing.xsmall : '0px'};
  }
  ${CheckMark} {
    transform: translateY(20%);
    height: 20px;
    width: 20px;
    background-color: ${props =>
      props.readOnly ? colors.light().background : colors.white().color};
    border: 1px solid
      ${props =>
        props.readOnly ? colors.light().background : colors.light().color};
    border-radius: 50%;
  }
  input:checked ~ ${CheckMark} {
    background-color: ${props =>
      props.readOnly ? colors.light().color : colors.white().color};
    border: 1px solid
      ${props =>
        props.readOnly ? colors.light().color : colors.primary().color};
  }
  ${CheckMark}:after {
    content: '';
    position: absolute;
    display: none;
  }
  input:checked ~ ${CheckMark}:after {
    display: block;
  }
  ${CheckMark}:after {
    background-color: ${colors.primary().color};
    width: 10px;
    height: 10px;
    border-radius: 50%;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
`

Radio.Element = styled.div<{
  direction?: 'horizontal' | 'vertical'
  align?: 'left' | 'center'
}>`
  width: 100%;
  display: flex;
  flex-direction: ${props =>
    props.direction === 'horizontal' ? 'row' : 'column'};
  justify-content: ${props =>
    props.align === 'left' ? 'flex-start' : 'center'};
  ${RadioOption.Element} {
    margin: ${props =>
      props.direction === 'horizontal' ? `0px ${spacing.small}` : '0px'};
  }
`

Radio.Option = RadioOption

export default Radio
