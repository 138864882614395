import axios from 'axios'
import { loadState } from '../util/localStorage'
import {
  FETCH_COMMENT_REQUEST,
  FETCH_COMMENT_SUCCESS,
  FETCH_COMMENT_FAILURE,
  CREATE_COMMENT_REQUEST,
  CREATE_COMMENT_SUCCESS,
  CREATE_COMMENT_FAILURE,
  REACTION_COMMENT_SUCCESS,
  REACTION_COMMENT_FAILURE,
} from './types'
const API_URL = process.env.REACT_APP_API_URL

// Comments
export const fetchComment = question => async (dispatch, getState) => {
  try {
    dispatch({
      type: FETCH_COMMENT_REQUEST,
    })

    const token = loadState('__alau_token__')

    const response = await axios.get(`${API_URL}/comments/${question}`, {
      headers: {
        Authorization: `bearer ${token}`,
      },
    })

    dispatch({
      type: FETCH_COMMENT_SUCCESS,
      response: response.data,
    })
  } catch (e) {
    dispatch({
      type: FETCH_COMMENT_FAILURE,
      message: e.response.data.message || 'Algo salió mal.',
    })
  }
}

//Create Comment
export const createComment = (question, description, type) => async (
  dispatch,
  getState,
) => {
  try {
    dispatch({
      type: CREATE_COMMENT_REQUEST,
    })

    const token = loadState('__alau_token__')

    const response = await axios.post(
      `${API_URL}/comments/`,
      { exercise: question, description, type },
      {
        headers: {
          Authorization: `bearer ${token}`,
        },
      },
    )
    dispatch({
      type: CREATE_COMMENT_SUCCESS,
      response: response.data,
    })
  } catch (e) {
    dispatch({
      type: CREATE_COMMENT_FAILURE,
      message: e.response.data.message || 'Algo salió mal.',
    })
  }
}

//Like and Dislike Comment
export const reactionComment = (comment, type) => async (
  dispatch,
  getState,
) => {
  try {
    dispatch({
      type: REACTION_COMMENT_SUCCESS,
      comment,
      reaction: type,
    })

    const token = loadState('__alau_token__')

    await axios.put(
      `${API_URL}/comments/${comment}`,
      { type },
      {
        headers: {
          Authorization: `bearer ${token}`,
        },
      },
    )
  } catch (e) {
    dispatch({
      type: REACTION_COMMENT_FAILURE,
      message: e.response.data.message || 'Algo salió mal.',
    })
  }
}
