import React from 'react'
import styled from 'styled-components'
import { colors } from '../../tokens'

const Container = styled.div`
  height: ${props => (props.error ? '40px' : '0px')};
  overflow: hidden;
  background-image: linear-gradient(-224deg, #c074c7 0%, #895ee4 100%);
  color: ${colors.base.white};
  font-size: 14px;
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 9999;
  transition: height 1s;

  .content {
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`

const Alert = ({ error = false }) => {
  return (
    <Container error={error}>
      <div className="content">
        <p>{error}</p>
      </div>
    </Container>
  )
}

export default Alert
