import React from 'react'

export default function Monthly5() {
  return (
    <form
      method="post"
      action="https://gateway.payulatam.com/ppp-web-gateway/pb.zul"
      acceptCharset="UTF-8"
    >
      <input
        type="image"
        border="0"
        alt=""
        src="http://www.payulatam.com/img-secure-2015/boton_pagar_mediano.png"
        onClick="this.form.urlOrigen.value = window.location.href;"
      />
      <input
        name="buttonId"
        type="hidden"
        value="GLQ/X+dw1dZsZxYJ8bXdtu2YCuDgR4yye89FnEZuyc+qYVW5kkItsA=="
      />
      <input name="merchantId" type="hidden" value="648157" />
      <input name="accountId" type="hidden" value="650624" />
      <input
        name="description"
        type="hidden"
        value="Suscripción mensual alaU"
      />
      <input name="referenceCode" type="hidden" value="003" />
      <input name="amount" type="hidden" value="19000.00" />
      <input name="tax" type="hidden" value="0.00" />
      <input name="taxReturnBase" type="hidden" value="0.00" />
      <input name="currency" type="hidden" value="COP" />
      <input name="lng" type="hidden" value="es" />
      <input
        name="approvedResponseUrl"
        type="hidden"
        value="https://app.alau.org/payment_success"
      />
      <input
        name="declinedResponseUrl"
        type="hidden"
        value="https://app.alau.org/payment_failed"
      />
      <input
        name="pendingResponseUrl"
        type="hidden"
        value="https://app.alau.org/payment_failed"
      />
      <input name="sourceUrl" id="urlOrigen" value="" type="hidden" />
      <input name="buttonType" value="SIMPLE" type="hidden" />
      <input
        name="signature"
        value="e0552f16817ea4b3d6f0a9d1409bf502129bb78205cb75805f753880edea9ff6"
        type="hidden"
      />
    </form>
  )
}
