import axios from 'axios'
import {
  RESET_AUTH_SUCCESS,
  FETCH_AUTH_REQUEST,
  FETCH_AUTH_SUCCESS,
  RESET_CHANGED_PASSWORD,
  FETCH_AUTH_FAILURE,
} from './types'
const API_URL = process.env.REACT_APP_API_URL

//reset form to reset password
export const resetFormPassword = () => async dispatch => {
  try {
    dispatch({
      type: RESET_AUTH_SUCCESS,
    })
  } catch (e) {
    // Error
  }
}

//Send email with reset passwords instruccions
export const sendResetPassword = email => async dispatch => {
  try {
    dispatch({
      type: FETCH_AUTH_REQUEST,
    })

    const response = await axios.post(`${API_URL}/usuarios/password`, {
      email,
    })
    dispatch({
      type: FETCH_AUTH_SUCCESS,
      response: response.data,
    })
  } catch (e) {
    dispatch({
      type: FETCH_AUTH_FAILURE,
      message: e.response.data.message || 'Algo salió mal.',
    })
  }
}

//Validate token
export const validateToken = token => async dispatch => {
  try {
    dispatch({
      type: FETCH_AUTH_REQUEST,
    })

    const response = await axios.get(`${API_URL}/usuarios/password/${token}`)
    dispatch({
      type: FETCH_AUTH_SUCCESS,
      response: response.data,
    })
    dispatch({
      type: RESET_CHANGED_PASSWORD,
    })
  } catch (e) {
    dispatch({
      type: FETCH_AUTH_FAILURE,
      message: e.response.data.message || 'Algo salió mal.',
    })
  }
}

export const changePassword = (token, password) => async dispatch => {
  try {
    dispatch({
      type: FETCH_AUTH_REQUEST,
    })

    const response = await axios.put(`${API_URL}/usuarios/password/${token}`, {
      password,
    })
    dispatch({
      type: FETCH_AUTH_SUCCESS,
      response: response.data,
    })
    dispatch({
      type: RESET_AUTH_SUCCESS,
    })
  } catch (e) {
    dispatch({
      type: FETCH_AUTH_FAILURE,
      message: e.response.data.message || 'Algo salió mal.',
    })
  }
}
