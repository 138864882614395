const userIsProInCourse = (cursos, id) => {
  let proCurso = false
  cursos.forEach(curso => {
    if (curso.curso === id) {
      proCurso = curso.pro
    }
  })
  return proCurso
}

export default userIsProInCourse
