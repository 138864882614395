import React from 'react'
import styled from 'styled-components'
import Heading from '../../../components/Heading'
import { colors, fonts, spacing, mediaQueries } from '../../../tokens'

export interface Props {
  image?: string
  title: string
  description: string
}

function DetailRequirements({ image, title, description }: Props) {
  return (
    <WrapperContent>
      {image && <Image src={`${image}`} />}
      <Heading size={2}>{title}</Heading>
      <Description
        dangerouslySetInnerHTML={{
          __html: description,
        }}
      />
    </WrapperContent>
  )
}

const WrapperContent = styled.div`
  @media ${mediaQueries.mediumMin} {
    margin: auto 0;
  }
`

const Image = styled.img`
  display: block;
  margin: ${spacing.small} auto;
`

const Description = styled.div`
  color: ${colors.base.default};
  font-family: ${fonts.family.default};
  font-size: ${fonts.size.default};
  font-weight: ${fonts.weight.normal};
`

export default DetailRequirements
