const mediaQueries = {
  xlargeMin: '(min-width: 1200px)',
  largeMax: '(max-width: 1199px)',
  largeMin: '(min-width: 992px)',
  mediumMax: '(max-width: 991px)',
  mediumMin: '(min-width: 768px)',
  smallMax: '(max-width: 767px)',
  smallMin: '(min-width: 576px)',
  xsmallMax: '(max-width: 575px)',
}

export default mediaQueries
