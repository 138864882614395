import React from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'
import Icon from '../Icon'
import Link from '../Link'
import Logo from '../Logo'
import Text from '../Text'
import { useUser } from '../../context/userContext'
import { logout } from '../../lib/api'
import { colors, mediaQueries } from '../../tokens'

interface Props {
  removeDataToAuthReduxState: () => void
}

function HeaderComponent({ removeDataToAuthReduxState }: Props) {
  const { user, reloadUser } = useUser()
  const name = user ? user.nombre || user.email : 'No user'
  const image =
    user && user.fbid
      ? `https://graph.facebook.com/v3.1/${user.fbid}/picture?type=square&width=50`
      : null

  function handleLogout(e: React.MouseEvent<HTMLAnchorElement>): void {
    e.preventDefault()
    logout(removeDataToAuthReduxState)
    reloadUser()
  }

  return (
    <Wrapper data-testid="wrapper-div">
      <WrapperLogo>
        <Link to={'/'}>
          <Logo />
        </Link>
      </WrapperLogo>
      <WrapperMenu>
        {user ? (
          <>
            <User>
              <ImageContainer>
                {image ? (
                  <Image src={image} alt={'nombre'} />
                ) : (
                  <Icon name="avatar" />
                )}
              </ImageContainer>
              <Text>{name}</Text>
            </User>
            <Menu>
              <Link to={'/mis-cursos'}>Mis Cursos</Link>
              <Link to={'/'}>Cursos</Link>
              <Link href="https://blog.alau.org">Blog</Link>
              <Link onClick={handleLogout}>Salir</Link>
            </Menu>
          </>
        ) : (
          <Menu>
            <Link href="https://blog.alau.org">Blog</Link>
            <Link to={'/iniciar-sesion'}>Entrar</Link>
            <Link to={'/registro'}>Registrarse</Link>
          </Menu>
        )}
      </WrapperMenu>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  background-color: ${colors.base.white};
  box-shadow: 0px 0px 3px 0px ${colors.base.grayLightest};
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 0 auto;
  min-height: 80px;
  padding: 15px;
  width: 100%;
  @media ${mediaQueries.xsmallMax} {
    flex-direction: column;
  }
  z-index: 999;
`

const WrapperLogo = styled.div`
  display: flex;
  @media ${mediaQueries.xsmallMax} {
    justify-content: center;
  }
`

const WrapperMenu = styled.div`
  align-items: center;
  display: flex;
  flex-basis: 100%;
  flex: 2;
  justify-content: flex-end;
  @media ${mediaQueries.xsmallMax} {
    flex-direction: column;
    > * {
      margin: 3px 0px;
    }
  }
`

const Menu = styled.div`
  margin-left: 10px;
  > * {
    padding: 0 10px;
    &:not(:first-child) {
      border-left: 1px solid ${colors.base.gray};
    }
  }
  @media ${mediaQueries.xsmallMax} {
    margin-left: 0px;
    text-align: center;
  }
`

const User = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

const ImageContainer = styled.div`
  margin-right: 5px;
`

const Image = styled.img`
  border-radius: 50%;
  border: 2px solid ${colors.base.grayLightest};
  height: 30px;
  width: 30px;
`

/* Backward compability with redux */
const mapDispatchToProps = (dispatch: any) => ({
  removeDataToAuthReduxState: () => {
    dispatch({
      type: 'RESET_AUTH_SUCCESS',
    })
  },
})

const Header = connect(null, mapDispatchToProps)(HeaderComponent) as any
/* Backward compability with redux */

export default Header
