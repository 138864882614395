import React from 'react'
import styled, { css } from 'styled-components'
import { colors, fonts } from '../../tokens'

interface Props {
  /** Inner content of the span tag */
  children: React.ReactNode
  /** Text Status */
  status?:
    | 'default'
    | 'light'
    | 'white'
    | 'primary'
    | 'success'
    | 'warning'
    | 'danger'
}

const Text = ({ children, status = 'default' }: Props) => (
  <Wrapper status={status}>{children}</Wrapper>
)

const Wrapper = styled.span<Props>`
  font-family: ${fonts.family.default};
  font-size: ${fonts.size.default};
  font-weight: ${fonts.weight.normal};

  ${props =>
    props.status &&
    css`
      color: ${colors[props.status]().color};
    `}
`

export default Text
