import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Button from '../Button'
import BackgroundImage from '../../imagenes/bg-resultado.png'
import Heading from '../Heading'
import Text from '../Text'
import { secondsToTime } from '../../util/date'
import { colors, fonts, spacing } from '../../tokens'

function Instructions(props) {
  const { name, description, seconds, onClick } = props
  const time = secondsToTime(seconds)
  const { h, m, s } = time
  return (
    <Container>
      <Heading>{name}</Heading>
      <DescriptionHTML
        dangerouslySetInnerHTML={{
          __html: description,
        }}
      />
      <Heading size={2}>Antes de iniciar</Heading>
      <Text>Ten en cuenta las siguientes recomendaciones:</Text>
      <br />
      <Text>
        Puedes utilizar las flechas de tu teclado para navegar entre las
        preguntas.
      </Text>
      <br />
      <Text>{`Tienes ${h}:${m}:${s} para finalizarlo.`}</Text>
      <br />
      <Text>
        El tiempo empezará a correr una vez que hagas clic en Iniciar.
      </Text>
      <br />
      <WrapperButton>
        <Button onClick={onClick}>Iniciar</Button>
      </WrapperButton>
    </Container>
  )
}

const Container = styled.div`
  background: url(${BackgroundImage}) no-repeat center center;
  background-size: contain;
  background-size: center;
  height: 100%;
  padding-top: ${spacing.large};
  width: 100%;
  text-align: center;
`

const DescriptionHTML = styled.div`
  color: ${colors.default().color};
  font-family: ${fonts.family.default};
  font-size: ${fonts.size.default};
  font-weight: ${fonts.weight.normal};
  img {
    max-width: 728px;
  }
`

const WrapperButton = styled.div`
  margin: ${spacing.small} 0px;
`

Instructions.propTypes = {
  name: PropTypes.string.isRequired,
  description: PropTypes.string,
  seconds: PropTypes.number.isRequired,
  onClick: PropTypes.func.isRequired,
}

export default Instructions
