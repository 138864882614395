const unit = 4

const spacing = {
  xxsmall: unit + 'px',
  xsmall: unit * 2 + 'px',
  small: unit * 4 + 'px',
  medium: unit * 6 + 'px',
  large: unit * 8 + 'px',
  xlarge: unit * 10 + 'px',
  xxlarge: unit * 14 + 'px',
}

export default spacing
