import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { colors, fonts } from '../../tokens'

const Container = styled.span`
  font-family: ${fonts.family.default};
  background: ${props => props.background};
  color: ${props => props.color};
  display: inline;
  text-align: center;
  font-size: 12px;
  padding: 3px 5px;
  border-radius: 25px;
`

const Label = ({
  children = '',
  primary = false,
  success = false,
  warning = false,
  danger = false,
  neutral = false,
}) => {
  let background = colors.base.white
  let color = colors.base.purple
  if (primary) {
    background = colors.base.purple
    color = colors.base.white
  }
  if (success) {
    background = colors.base.green
    color = colors.base.white
  }
  if (warning) {
    background = colors.base.yellow
    color = colors.base.white
  }
  if (danger) {
    background = colors.base.red
    color = colors.base.white
  }
  if (neutral) {
    background = colors.base.grayLight
    color = colors.base.white
  }
  return (
    <Container color={color} background={background}>
      {children}
    </Container>
  )
}

Label.propTypes = {
  children: PropTypes.string.isRequired,
  primary: PropTypes.bool,
  success: PropTypes.bool,
  warning: PropTypes.bool,
  danger: PropTypes.bool,
  neutral: PropTypes.bool,
}

export default Label
