import React from 'react'
import { NotificationProvider } from './notificationContext'
import { UserProvider } from './userContext'
import { NotificationState } from '../components/Notifications/state'

interface Props {
  children: React.ReactNode
  notificationState: NotificationState
}

function AppProviders({ children, notificationState }: Props) {
  return (
    <NotificationProvider state={notificationState}>
      <UserProvider>{children}</UserProvider>
    </NotificationProvider>
  )
}

export default AppProviders
