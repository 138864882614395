import React from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { useAsync } from 'react-async'
import { useNotification } from '../../context/notificationContext'
import {
  PracticeProvider,
  usePracticeContext,
} from './context/PracticeProvider'
import {
  INITIALIZE_PRACTICE,
  CHOOSE_ANSWER,
  VALIDATE_ANSWER_REQUEST,
  VALIDATE_ANSWER_SUCCESS,
  SHOW_NEXT_QUESTION,
} from './types'
import { createPractice, createAnswer } from '../../lib/api'
import Header from './components/Header'
import Loading from '../Loading'
import Layout from '../../components/Layout'
import Question from './components/Question'
import Comments from '../../containers/Comments'

function Practice() {
  const history: any = useHistory()
  const params: any = useParams()
  const { content, slug } = params
  const { notify } = useNotification()

  const { practice, dispatchPractice } = usePracticeContext()

  const { isLoading } = useAsync({
    promiseFn: createPractice,
    body: { contenido: content },
    onReject: error => notify(error.message, 'error'),
    onResolve: (data: any) => {
      dispatchPractice({
        type: INITIALIZE_PRACTICE,
        payload: data,
      })
    },
  })

  const validateAnswer = useAsync({
    deferFn: args => createAnswer({ body: args[0] }),
    onReject: error => notify(error.message, 'error'),
    onResolve: (data: any) => {
      dispatchPractice({
        type: VALIDATE_ANSWER_SUCCESS,
        payload: data.correcta._id,
      })
    },
  })

  if (isLoading) {
    return <Loading />
  }

  function onChangeAnswer(event: React.ChangeEvent<HTMLInputElement>) {
    const target = event.target
    const value = target.value
    dispatchPractice({
      type: CHOOSE_ANSWER,
      payload: value,
    })
  }

  function onSubmitAnswer(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault()
    const newAnswer = {
      simulador: practice.simulator._id,
      ejercicio: practice.question._id,
      opcion: practice.chosenAnswer !== '0' ? practice.chosenAnswer : null,
      nose: practice.chosenAnswer === '0' ? true : false,
    }
    dispatchPractice({
      type: VALIDATE_ANSWER_REQUEST,
    })
    validateAnswer.run(newAnswer)
  }

  function onShowNextQuestion() {
    const newIndex = practice.index + 1
    if (newIndex >= practice.total) {
      history.push(`/resultado/${practice.simulator._id}`)
    } else {
      dispatchPractice({
        type: SHOW_NEXT_QUESTION,
        payload: newIndex,
      })
      window.scrollTo(0, 0)
    }
  }

  return (
    <>
      <Header
        content={content}
        slug={slug}
        index={practice.index}
        total={practice.total}
      />
      <Layout>
        <Layout.Section centerContent={true}>
          {practice.question && (
            <Question
              onChangeAnswer={onChangeAnswer}
              onSubmitAnswer={onSubmitAnswer}
              onShowNextQuestion={onShowNextQuestion}
            />
          )}
          {practice.correctAnswer && practice.question && (
            <Comments question={practice.question._id} />
          )}
        </Layout.Section>
      </Layout>
    </>
  )
}

const PracticeWithProvider = () => (
  <PracticeProvider>
    <Practice />
  </PracticeProvider>
)

export default PracticeWithProvider
