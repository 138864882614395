import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import Logo from './Logo'

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`
const LogoSimulador = ({ slug = '' }) => (
  <Container>
    <Link to={`/cursos/${slug}`}>
      <Logo />
    </Link>
  </Container>
)

export default LogoSimulador
