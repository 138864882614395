import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import Radio from '../../../../components/Radio'
import MonthlyPlan from '../MonthlyPlan'
import { mediaQueries, spacing } from '../../../../tokens'
import prices from '../prices.json'

function hasKey<O>(obj: O, key: keyof any): key is keyof O {
  return key in obj
}

interface Props {
  category: string
  onChangeCategory: Function
  onClickPlan: Function
  discount: number
}

function TypesOfPlans({
  onClickPlan,
  onChangeCategory,
  category,
  discount,
}: Props) {
  const [monthlyPrice, setMonthyPrice] = useState<number>(0)
  const [annualPrice, setAnnualPrice] = useState<number>(0)

  useEffect(() => {
    const mPrice = prices.ecuador.monthlyPrice
    const aPrice = prices.ecuador.annualPrice
    if (discount) {
      const monthlyPriceDiscount = mPrice - (mPrice * discount) / 100
      setMonthyPrice(monthlyPriceDiscount)
      const annualPriceDiscount = aPrice - (aPrice * discount) / 100
      setAnnualPrice(annualPriceDiscount)
    } else {
      setMonthyPrice(mPrice)
      setAnnualPrice(aPrice)
    }
  }, [discount])

  return (
    <>
      <SwitchPlan>
        <Radio
          name="category"
          selected={category}
          onChange={onChangeCategory}
          align="center"
        >
          <Radio.Option value={'individual'}>Individual</Radio.Option>
          <Radio.Option value={'institucional'}>Institucional</Radio.Option>
        </Radio>
      </SwitchPlan>
      {category === 'individual' ? (
        <Plans>
          <MonthlyPlan
            title={'Plan Mensual'}
            description={'paga mes a mes'}
            price={`$${monthlyPrice}`}
            onClick={() =>
              onClickPlan(
                `Individual - Plan Mensual: $${monthlyPrice} x mes`,
                `${
                  hasKey(prices.ecuador.monthlyCodes, discount)
                    ? prices.ecuador.monthlyCodes[discount]
                    : prices.ecuador.monthlyCodes[0]
                }`,
                `Recuerda que el valor a pagar es de $${monthlyPrice}`,
              )
            }
          />
          <MonthlyPlan
            title={'Plan Anual'}
            description={`paga todo un año y ahorra $${(
              monthlyPrice * 12 -
              annualPrice * 12
            ).toFixed(2)}`}
            price={`$${annualPrice}`}
            onClick={() =>
              onClickPlan(
                `Individual - Plan Anual: $${annualPrice} x mes`,
                `${
                  hasKey(prices.ecuador.anualCodes, discount)
                    ? prices.ecuador.anualCodes[discount]
                    : prices.ecuador.anualCodes[0]
                }`,
                `Recuerda que el valor a pagar es de $${annualPrice * 12}`,
              )
            }
          />
        </Plans>
      ) : (
        <Plans>
          <MonthlyPlan
            title={'Plan Mensual'}
            description={'por estudiante'}
            price={'$7'}
            onClick={() => onClickPlan('Institucional', '')}
          />
          <MonthlyPlan
            title={'Plan Anual'}
            description={'por estudiante, $72 al año un solo pago'}
            price={'$6'}
            onClick={() => onClickPlan('Institucional', '')}
          />
        </Plans>
      )}
    </>
  )
}

const SwitchPlan = styled.div`
  display: flex;
  margin-top: ${spacing.small};
  justify-content: center;
`

const Plans = styled.div`
  align-self: center;
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 0 auto;
  @media ${mediaQueries.mediumMin} {
    width: 575px;
  }
`

export default TypesOfPlans
