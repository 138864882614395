import React from 'react'
import NewPasswordContainer from '../containers/NewPassword'
import Main from '../components/layouts/Main'

const NewPassword = () => (
  <Main figuras={true}>
    <NewPasswordContainer />
  </Main>
)

export default NewPassword
