const colors = {
  base: {
    white: '#FFF',
    black: '#000',
    default: '#252529',

    //grayDark: '#636363',
    gray: '#4E5974',
    grayLight: '#A0AAAD',
    grayLightest: '#E1E6EA',
    grayBackground: '#F8F9FB',

    //purpleDark: '#097093',
    purple: '#662482',
    purpleLight: '#C074C7',
    //purpleLightest: '#f5f7f9',

    blueDark: '#097093',
    blue: '#3BC0F2',
    blueLight: '#DBF4FD',

    greenDark: '#00946e',
    green: '#3FD5AE',
    greenLight: '#aaf3e0',
    //greenLightest: '#f5f7f9',

    yellowDark: '#987800',
    yellow: '#FFC900',
    yellowLight: '#ffeca4',
    //yellowLightest: '#f5f7f9',

    redDark: '#a22b31',
    red: '#FE6068',
    redLight: '#ffbbbe',
    //redLightest: '#f5f7f9',
  },
  default() {
    return {
      color: this.base.default,
      darkColor: this.base.default,
      background: this.base.default,
      buttonColor: this.base.white,
    }
  },
  white() {
    return {
      color: this.base.white,
      darkColor: this.base.white,
      background: this.base.white,
      buttonColor: this.base.purple,
    }
  },
  light() {
    return {
      color: this.base.grayLight,
      darkColor: this.base.gray,
      background: this.base.grayLightest,
      buttonColor: this.base.white,
    }
  },
  primary() {
    return {
      color: this.base.purple,
      darkColor: this.base.purpleDark,
      background: this.base.purpleLight,
      buttonColor: this.base.white,
    }
  },
  info() {
    return {
      color: this.base.blue,
      darkColor: this.base.blueDark,
      background: this.base.blueLight,
      buttonColor: this.base.white,
    }
  },
  success() {
    return {
      color: this.base.green,
      darkColor: this.base.greenDark,
      background: this.base.greenLight,
      buttonColor: this.base.white,
    }
  },
  warning() {
    return {
      color: this.base.yellow,
      darkColor: this.base.yellowDark,
      background: this.base.yellowLight,
      buttonColor: this.base.gray,
    }
  },
  danger() {
    return {
      color: this.base.red,
      darkColor: this.base.redDark,
      background: this.base.redLight,
      buttonColor: this.base.white,
    }
  },
  // text: {
  //   default: '#212121',
  //   inputs: '#555',
  //   secondary: '#757575',
  //   subtle: '#BDBDBD',
  //   error: '#FF0000'
  // }
}

export default colors
