import React from 'react'
import styled from 'styled-components'
import Heading from '../Heading'
import GalleryLayout from '../GalleryLayout'
import { mediaQueries, spacing } from '../../tokens'
import ImageAllied1 from '../../imagenes/01-mit.png'
import ImageAllied2 from '../../imagenes/02-telefonica.png'
import ImageAllied3 from '../../imagenes/03-cedia.png'
import ImageAllied4 from '../../imagenes/04-unae.png'
import ImageAllied5 from '../../imagenes/05-agora.png'
import ImageAllied6 from '../../imagenes/06-aei.png'
import ImageAllied7 from '../../imagenes/07-youtube.png'
import ImageAllied8 from '../../imagenes/08-jardin-azuayo.png'
import ImageAllied9 from '../../imagenes/09-almendra.png'
import ImageAllied10 from '../../imagenes/10-integrar.png'

const Allies = () => {
  return (
    <Wrapper>
      <AlliedTitle>
        <Heading>Nuestros Aliados</Heading>
      </AlliedTitle>
      <GalleryLayout cols={6}>
        <Allied>
          <ImageAllied alt="MIT" src={ImageAllied1} />
        </Allied>
        <Allied>
          <ImageAllied alt="Telefónica" src={ImageAllied2} />
        </Allied>
        <Allied>
          <ImageAllied alt="Red Cedia" src={ImageAllied3} />
        </Allied>
        <Allied>
          <ImageAllied alt="UNAE" src={ImageAllied4} />
        </Allied>
        <Allied>
          <ImageAllied alt="Agora" src={ImageAllied5} />
        </Allied>
        <Allied>
          <ImageAllied alt="AEI" src={ImageAllied6} />
        </Allied>
        <Allied>
          <ImageAllied alt="Youtube" src={ImageAllied7} />
        </Allied>
        <Allied>
          <ImageAllied alt="Jardín Azuayo" src={ImageAllied8} />
        </Allied>
        <Allied>
          <ImageAllied alt="Almendra" src={ImageAllied9} />
        </Allied>
        <Allied>
          <ImageAllied alt="Integrar" src={ImageAllied10} />
        </Allied>
      </GalleryLayout>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  padding: ${spacing.xsmall};
`

const AlliedTitle = styled.div`
  margin: ${spacing.xxlarge} 0px;
  text-align: center;
`

const Allied = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
`
const ImageAllied = styled.img`
  display: block;
  margin: 0 auto;
  max-width: 40%;
  @media ${mediaQueries.mediumMin} {
    height: auto;
    max-width: 80%;
  }
`

export default Allies
