import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import Radio from '../../../../components/Radio'
import MonthlyPlan from '../MonthlyPlan'
import { mediaQueries, spacing } from '../../../../tokens'
import prices from '../prices.json'

interface Props {
  category: string
  onChangeCategory: Function
  onClickPlan: Function
  discount: number
}

function TypesOfPlans({
  onClickPlan,
  onChangeCategory,
  category,
  discount,
}: Props) {
  const [monthlyPrice, setMonthyPrice] = useState<number>(
    prices.colombia.monthlyPrice,
  )
  const [annualPrice, setAnnualPrice] = useState<number>(
    prices.colombia.annualPrice,
  )

  useEffect(() => {
    const mPrice = prices.colombia.monthlyPrice
    const aPrice = prices.colombia.annualPrice
    if (discount) {
      const monthlyPriceDiscount = mPrice - (mPrice * discount) / 100
      setMonthyPrice(monthlyPriceDiscount)
      const annualPriceDiscount = aPrice - (aPrice * discount) / 100
      setAnnualPrice(annualPriceDiscount)
    } else {
      setMonthyPrice(mPrice)
      setAnnualPrice(aPrice)
    }
  }, [discount])

  return (
    <>
      <SwitchPlan>
        <Radio
          name="category"
          selected={category}
          onChange={onChangeCategory}
          align="center"
        >
          <Radio.Option value={'individual'}>Individual</Radio.Option>
          <Radio.Option value={'institucional'}>Institucional</Radio.Option>
        </Radio>
      </SwitchPlan>
      {category === 'individual' ? (
        <Plans>
          <MonthlyPlan
            title={'Plan Mensual'}
            description={'paga mes a mes'}
            price={`COP ${monthlyPrice}`}
            onClick={() =>
              onClickPlan(
                `Individual - Plan Mensual: COP ${monthlyPrice} x mes`,
                `${discount ? 'mensual' + discount : 'mensual'}`,
                `Recuerda que el valor a pagar es de COP ${monthlyPrice}`,
              )
            }
          />
          <MonthlyPlan
            title={'Plan Anual'}
            description={`paga todo un año y ahorra COP ${monthlyPrice * 12 -
              annualPrice * 12}`}
            price={`COP ${annualPrice}`}
            onClick={() =>
              onClickPlan(
                `Individual - Plan Anual: COP ${annualPrice} x mes`,
                `${discount ? 'anual' + discount : 'anual'}`,
                `Recuerda que el valor a pagar es de COP ${annualPrice * 12}`,
              )
            }
          />
        </Plans>
      ) : (
        <Plans>
          <MonthlyPlan
            title={'Plan Mensual'}
            description={'por estudiante'}
            price={'COP 10000'}
            onClick={() => onClickPlan('Institucional', '')}
          />
          <MonthlyPlan
            title={'Plan Anual'}
            description={'por estudiante, COP 84000 al año un solo pago'}
            price={'COP 7000'}
            onClick={() => onClickPlan('Institucional', '')}
          />
        </Plans>
      )}
    </>
  )
}

const SwitchPlan = styled.div`
  display: flex;
  margin-top: ${spacing.small};
  justify-content: center;
`

const Plans = styled.div`
  align-self: center;
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 0 auto;
  @media ${mediaQueries.mediumMin} {
    width: 575px;
  }
`

export default TypesOfPlans
