import React from 'react'
import ResultadoContainer from '../../containers/Resultado'
import Main from '../layouts/Main'

const Resultado = () => (
  <Main figuras={false}>
    <ResultadoContainer />
  </Main>
)

export default Resultado
