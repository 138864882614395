import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { validateToken, changePassword } from '../actions/auth'
import NewPasswordComponent from '../components/NewPassword'

const mapStateToProps = state => ({
  validToken: state.auth.validToken,
  changedPassword: state.auth.changedPassword,
  isFetching: state.auth.isFetching,
  errorMessage: state.auth.errorMessage,
})

const mapDispatchToProps = dispatch => ({
  validateToken: token => {
    dispatch(validateToken(token))
  },
  changePassword: (token, password) => {
    dispatch(changePassword(token, password))
  },
})

const NewPassword = withRouter(
  connect(mapStateToProps, mapDispatchToProps)(NewPasswordComponent),
)

export default NewPassword
