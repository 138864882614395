import React from 'react'
import ResetPasswordContainer from '../containers/ResetPassword'
import Main from '../components/layouts/Main'

const ResetPassword = () => (
  <Main figuras={true}>
    <ResetPasswordContainer />
  </Main>
)

export default ResetPassword
