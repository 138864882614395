import React from 'react'
import styled from 'styled-components'

import Icon from '../../../../components/Icon'
import Heading from '../../../../components/Heading'
import Text from '../../../../components/Text'
import { colors, mediaQueries, spacing } from '../../../../tokens'
import { secondsToTime, formatDate } from '../../../../util/date'

interface StyledProps {
  completed: boolean | null
}

interface Props {
  name: string
  slug: string
  duration: number
  questions: number
  startAt: string
  correct: number
  total: number
  isAccessPro: boolean
  approved: boolean
  gradeToApproved: number
  isPonderable: boolean
  userIsPro: boolean
  onClick(slug: string): void
  onRestrictContent(): void
}

function QuizLesson({
  name,
  slug,
  duration,
  questions,
  startAt,
  correct,
  total,
  isAccessPro,
  approved,
  gradeToApproved,
  isPonderable,
  userIsPro,
  onClick,
  onRestrictContent,
}: Props) {
  const time = secondsToTime(duration)
  const { h, m, s } = time

  let locked = false
  if (!userIsPro) {
    locked = isAccessPro
  }

  const completed = startAt ? true : false

  function handleClickItem(): void {
    if (!locked) {
      onClick(slug)
    } else {
      onRestrictContent()
    }
  }

  function getCornerIcon(): React.ReactNode {
    if (locked) {
      return (
        <Status completed={false}>
          <Icon name="padlock" status="default" />
        </Status>
      )
    }

    let cornerIcon: React.ReactNode | null = null
    if (isPonderable) {
      cornerIcon =
        approved || !completed ? (
          <Status completed={completed}>
            <Icon name="check" status="white" />
          </Status>
        ) : (
          <Status completed={null}>
            <Icon name="warning" status="danger" />
          </Status>
        )
    }
    return cornerIcon
  }

  return (
    <Wrapper onClick={handleClickItem}>
      <IconContainer>
        <Icon name="quiz" size="large" status="white" />
      </IconContainer>
      <Info>
        <Heading size={3}>{name}</Heading>
        {duration && (
          <Features>
            <Icon name="watch" />
            <FeatureDescription>
              <Text status="light">{`${h}:${m}:${s}`}</Text>
            </FeatureDescription>
          </Features>
        )}
        <Features>
          <Icon name="questions" />
          <FeatureDescription>
            <Text status="light">{`${questions} preguntas`}</Text>
          </FeatureDescription>
        </Features>
        {completed && (
          <Features>
            <Icon name="results" />
            <FeatureDescription>
              <Text status="light">{`Nota: ${correct}/${total} | `}</Text>
              <Text status="light">{` Realizado el: ${formatDate(
                new Date(startAt),
              )} `}</Text>
              {isPonderable && !approved ? (
                <>
                  <Text status="light"> | </Text>
                  <Text status="danger">{`Nota mínima para aprobar: ${gradeToApproved}/${questions}`}</Text>
                </>
              ) : null}
            </FeatureDescription>
          </Features>
        )}
      </Info>
      {getCornerIcon()}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  align-items: flex-start;
  cursor: pointer;
  display: flex;
  flex-wrap: wrap;
`

const IconContainer = styled.div`
  align-items: center;
  align-self: center;
  background-color: ${colors.primary().color};
  display: flex;
  height: 160px;
  justify-content: center;
  width: 100%;
  @media ${mediaQueries.smallMin} {
    width: 250px;
    height: 130px;
  }
`

const Info = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: flex-start;
  padding: ${spacing.small};
  @media ${mediaQueries.smallMin} {
    padding: ${spacing.xsmall} ${spacing.small};
  }
`

const Features = styled.div`
  align-items: flex-start;
  display: flex;
  margin-top: ${spacing.xxsmall};
`

const FeatureDescription = styled.div`
  margin-left: ${spacing.xxsmall};
`

const Status = styled.div<StyledProps>`
  align-items: center;
  background-color: ${props => {
    if (props.completed === true) {
      return colors.success().color
    }
    if (props.completed === false) {
      return colors.light().background
    }
    if (props.completed === null) {
      return 'transparent'
    }
    return 'transparent'
  }};
  border-radius: 50%;
  display: flex;
  justify-content: center;
  margin: ${spacing.small};
  @media ${mediaQueries.smallMin} {
    margin: ${spacing.xsmall};
  }
`

export default QuizLesson
