import React from 'react'
import { useAsync } from 'react-async'

import Banner from '../../../../components/Banner'
import { getCourseBanners } from '../../../../lib/api'
import Loading from '../../../Loading'
import { useNotification } from '../../../../context/notificationContext'

function CourseBanner({ course }: { course: string }) {
  const { notify } = useNotification()
  const { data = [], isLoading } = useAsync({
    promiseFn: getCourseBanners,
    course,
    onReject: error => notify(error.message, 'error'),
  })

  if (isLoading) {
    return <Loading />
  }

  return data.length ? <Banner items={data} /> : null
}

export default CourseBanner
