export const INITIALIZE_CONTENT = 'INITIALIZE_CONTENT'
export const LESSON_COMPLETED = 'LESSON_COMPLETED'
export const FETCHING_CONTENT = 'FETCHING_CONTENT'

const initialState = {
  id: '',
  title: '',
  lessons: [],
  practice: {},
  isFetching: false,
}

const contentReducer = (state = initialState, action) => {
  switch (action.type) {
    case INITIALIZE_CONTENT:
      return {
        ...state,
        ...action.payload,
        isFetching: false,
      }
    case LESSON_COMPLETED: {
      const newLesson = state.lessons.map(lesson => {
        if (lesson._id === action.payload) {
          return { ...lesson, completed: true }
        } else {
          return lesson
        }
      })
      return {
        ...state,
        lessons: [...newLesson],
      }
    }
    case FETCHING_CONTENT:
      return {
        ...state,
        isFetching: true,
      }
    default:
      return state
  }
}

export default contentReducer
