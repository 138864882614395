import React from 'react'
import styled, { css } from 'styled-components'
import Header from './Header'
import { colors } from '../../tokens'
import Body from './Body'

interface Props {
  /** The content to display inside the card. */
  children?: React.ReactNode
  /** Align content for the card */
  alignCenter?: boolean
  /** Fill content inside the card */
  fillContent?: boolean
}

const Card = ({ children, alignCenter, fillContent }: Props) => (
  <Wrapper
    alignCenter={alignCenter}
    fillContent={fillContent}
    data-testid="wrapper-div"
  >
    {children}
  </Wrapper>
)

Card.Header = Header
Card.Body = Body

const Wrapper = styled.div<Props>`
  ${props =>
    props.fillContent &&
    css`
      height: 100%;
    `}
  text-align: inherit;
  background-color: ${colors.base.white};
  border-radius: 3px;
  box-shadow: 0 0 0 1px rgba(63, 63, 68, 0.05),
    0 1px 3px 0 rgba(63, 63, 68, 0.15);

  ${props =>
    props.alignCenter &&
    css`
      text-align: center;
    `}
`

export default Card
