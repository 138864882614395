import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { colors, fonts } from '../../tokens'

const Description = styled.p`
  font-family: ${fonts.family.default};
  text-align: left;
  line-height: 1.3;
  font-weight: 400;
  color: ${colors.base.gray};
  padding-top: 30px;
  table {
    margin: 10px auto;
  }
  b {
    font-weight: normal;
  }
  img {
    max-width: 100%;
    display: block;
    height: auto;
    margin: auto;
  }
  img.formula {
    height: 30px !important;
    margin-bottom: -5px;
    display: inline-block !important;
  }
  img.formula-lg {
    height: 60px !important;
    display: inline-block !important;
    margin-bottom: -5px;
  }
  @media screen and (max-width: 767px) {
    padding-top: 0px;
  }
`

const Options = styled.div`
  font-family: ${fonts.family.default};
  display: flex;
  flex-direction: column;

  p {
    margin-top: 0px;
    margin-bottom: 0px;
  }

  /* Radiobutton */
  .radio {
    display: inline-block;
    position: relative;
    padding-left: 30px;
    padding-right: 20px;
    margin-top: 12px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 16px;
    color: ${colors.base.gray};
    user-select: none;
  }

  /* Hide the browser's default checkbox */
  .radio input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
  }

  /* Create a custom checkbox */
  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 21px;
    width: 21px;
    background-color: ${colors.base.white};
    border-radius: 50%;
    border: 1px solid ${colors.base.grayLightest};
    display: flex;
    justify-content: center;
    align-items: center;
  }

  /* When the checkbox is checked, add background */
  .radio input:checked ~ .checkmark {
    background-color: ${colors.base.white};
  }

  /* Create the checkmark/indicator (hidden when not checked) */
  .checkmark:after {
    content: '';
    position: absolute;
    display: none;
  }

  /* Show the checkmark when checked */
  .radio input:checked ~ .checkmark:after {
    display: block;
  }

  /* Style the checkmark/indicator */
  .radio .checkmark:after {
    width: 11px;
    height: 11px;
    border-radius: 50%;
    background-color: ${colors.base.purple};
  }
`

function Question(props) {
  const { question, options, answer, onChange } = props
  return (
    <form>
      <Description
        dangerouslySetInnerHTML={{
          __html: question.descripcion,
        }}
      />

      <Options>
        {options.map((option, key) => (
          <label key={key} className="radio">
            <span dangerouslySetInnerHTML={{ __html: option.descripcion }} />
            <input
              type="radio"
              name="answer"
              value={option._id}
              onChange={onChange}
              checked={answer === option._id}
              required
            />
            <span className="checkmark" />
          </label>
        ))}
      </Options>
    </form>
  )
}

Question.propTypes = {
  question: PropTypes.object.isRequired,
  options: PropTypes.array.isRequired,
  answer: PropTypes.string,
  onChange: PropTypes.func.isRequired,
}

export default Question
