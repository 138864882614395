import React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import { colors, fonts } from '../../tokens'

const Container = styled.div`
  background: ${colors.base.grayBackground};
  text-align: center;
  padding: 15px 5px;

  @media screen and (max-width: 767px) {
    display: none;
  }
`
const Button = styled.button`
  font-family: ${fonts.family.default};
  background: ${colors.base.white};
  border: 1px solid ${colors.base.grayLight};
  color: ${colors.base.grayLight};
  font-weight: 700;
  width: 36px;
  height: 36px;
  margin: 2px;
  text-align: center;
  border-radius: 50%;
  font-size: 12px;
  cursor: pointer;
  opacity: 1;
  transition: all 0.25s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  :focus {
    outline: 0;
    transform: translateY(-1px);
    opacity: 0.9;
  }
  :hover {
    transform: translateY(-1px);
    opacity: 0.9;
  }

  ${props =>
    props.completed &&
    css`
      background: ${colors.base.grayLight};
      border: 1px solid ${colors.base.grayLight};
      color: ${colors.base.white};
    `} ${props =>
    props.active &&
    css`
      background: ${colors.base.green};
      border: 1px solid ${colors.base.green};
      color: ${colors.base.white};
    `};
`

function Navigation(props) {
  const currentIndex = props.index
  return (
    <Container>
      {props.items.map((item, index) => (
        <Button
          onClick={() => props.navigate(index)}
          key={index}
          active={currentIndex === index}
          completed={item.option}
        >
          {index + 1}
        </Button>
      ))}
    </Container>
  )
}

Navigation.propTypes = {
  index: PropTypes.number.isRequired,
  items: PropTypes.array.isRequired,
  navigate: PropTypes.func.isRequired,
}

export default Navigation
