import React from 'react'
import styled, { css } from 'styled-components'
import { colors, mediaQueries, spacing } from '../../tokens'

interface Props {
  /** The content to display inside the section. */
  children?: React.ReactNode
  /** Add white background */
  background?: boolean
  /** Limit layout width. */
  centerContent?: boolean
  /** Add padding div. */
  padding?: boolean
  /** Display the section as a secondary div. */
  secondary?: boolean
  /** Make div scrollable with fix height. */
  heightOfViewPort?: boolean
}

const Section = ({
  children,
  background = false,
  centerContent = false,
  padding = true,
  secondary = false,
  heightOfViewPort = false,
}: Props) => (
  <Wrapper
    background={background}
    centerContent={centerContent}
    padding={padding}
    secondary={secondary}
    heightOfViewPort={heightOfViewPort}
    data-testid="wrapper-div"
  >
    {children}
  </Wrapper>
)

const Wrapper = styled.div<Props>`
  /* css for primary column */
  flex-grow: 1;
  height: 100%;
  overflow-y: scroll;
  ${props =>
    props.heightOfViewPort &&
    css`
      @media ${mediaQueries.largeMin} {
        height: calc(100vh - 80px);
      }
    `}

  ${props =>
    props.background &&
    css`
      background: ${colors.base.white};
    `}

  ${props =>
    props.padding &&
    css`
      padding: ${spacing.medium};
      @media ${mediaQueries.mediumMin} {
        padding: ${spacing.large};
      }
    `}

  @media ${mediaQueries.mediumMax} {
    padding-top: 0px;
  }

  ${props =>
    props.secondary &&
    css`
      /* overwrite css for secondary column */
      flex-grow: 0;
      flex-basis: auto;
      width: 100%;
      @media ${mediaQueries.largeMin} {
        max-width: 450px;
        width: 25%;
      }
    `}
  
  ${props =>
    props.centerContent &&
    css`
      /* overwrite css for centerContent column */
      margin: 0 auto;
      flex: 1;
      width: 100%;
      @media ${mediaQueries.smallMin} {
        max-width: 576px;
      }

      @media ${mediaQueries.mediumMin} {
        max-width: 768px;
      }

      @media ${mediaQueries.largeMin} {
        max-width: 992px;
      }

      @media ${mediaQueries.xlargeMin} {
        max-width: 1200px;
      }
    `}
`

export default Section
