import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter as Router } from 'react-router-dom'
import { Provider } from 'react-redux'
import AppProviders from './context'
import store from './store'
import './index.css'
import './utilities.css'
import * as serviceWorker from './serviceWorker'
import { NotificationState } from './components/Notifications/state'
import App from './App'

const notificationState = new NotificationState()

ReactDOM.render(
  <Provider store={store}>
    <AppProviders notificationState={notificationState}>
      <Router basename="/">
        <App />
      </Router>
    </AppProviders>
  </Provider>,
  document.getElementById('root'),
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
