import React, { Component } from 'react'

class IconoCorrecto extends Component {
  render() {
    return (
      <svg
        width="41px"
        height="41px"
        viewBox="0 0 41 41"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xlink="http://www.w3.org/1999/xlink"
      >
        <g
          id="Page-1"
          stroke="none"
          strokeWidth="1"
          fill="none"
          fillRule="evenodd"
        >
          <g
            id="1.-login-copy-5"
            transform="translate(-433.000000, -536.000000)"
            stroke="#3FD5AE"
            strokeWidth="2"
          >
            <g id="Group-2" transform="translate(408.000000, 508.000000)">
              <g
                id="thin-0154_ok_successful_check"
                transform="translate(25.000000, 28.000000)"
              >
                <g id="Group" transform="translate(1.000000, 1.000000)">
                  <circle
                    id="Oval"
                    fill="#FFFFFF"
                    cx="19.5196458"
                    cy="19.5042708"
                    r="19.4445917"
                  />
                  <polyline
                    id="Shape"
                    points="11.0844083 20.089375 16.7533417 27.1769083 28.9604083 11.9153417"
                  />
                </g>
              </g>
            </g>
          </g>
        </g>
      </svg>
    )
  }
}

export default IconoCorrecto
