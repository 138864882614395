import React from 'react'
import styled from 'styled-components'
import ReactPlayer from 'react-player'

import Heading from '../../../../components/Heading'
import Card from '../../../../components/Card'
import { colors, fonts, mediaQueries, spacing } from '../../../../tokens'

export interface Props {
  name: string
  description: string
  url: string
  category: string
}

function containsOnlyNumbers(str: string) {
  return /^\d+$/.test(str)
}

function VideoModal({ name, description, url, category }: Props) {
  const urlVideo = containsOnlyNumbers(url)
    ? `https://vimeo.com/${url}`
    : `https://youtu.be/${url}`
  return (
    <Wrapper>
      {category === 'video' && (
        <WrapperVideo>
          <ReactPlayer
            className="react-player"
            url={urlVideo}
            playing
            controls={true}
            width="100%"
            height="100%"
          />
        </WrapperVideo>
      )}
      <Card>
        <Card.Body paddingSize="small">
          <Heading size={2}>{name}</Heading>
          <DescriptionHTML
            dangerouslySetInnerHTML={{
              __html: description,
            }}
          />
        </Card.Body>
      </Card>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  margin: 0 auto;
  @media ${mediaQueries.mediumMin} {
    max-width: 100%;
  }

  @media ${mediaQueries.largeMin} {
    max-width: 992px;
  }

  @media ${mediaQueries.xlargeMin} {
    max-width: 1200px;
  }
`

const WrapperVideo = styled.div`
  margin: ${spacing.small} 0px;
  position: relative;
  padding-top: 56.25%; /* 720 / 1280 = 0.5625 */
  .react-player {
    position: absolute;
    top: 0;
    left: 0;
  }
`

const DescriptionHTML = styled.div`
  color: ${colors.default().color};
  font-family: ${fonts.family.default};
  font-size: ${fonts.size.default};
  font-weight: ${fonts.weight.normal};
  margin-top: ${spacing.xsmall};
`

export default VideoModal
