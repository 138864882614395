export const INITIALIZE_COURSE = 'INITIALIZE_COURSE'
export const ADD_PROGRESS = 'ADD_PROGRESS'
export const SET_CURRENT_CONTENT = 'SET_CURRENT_CONTENT'

const initialState = {
  id: null,
  actual: '',
  title: '',
  slug: '',
  contents: [],
  currentContent: null,
  hasFinalQuiz: false,
  finalQuiz: null,
  urlWebSite: '',
}

const courseReducer = (state = initialState, action) => {
  switch (action.type) {
    case INITIALIZE_COURSE:
      return action.payload
    case ADD_PROGRESS:
      return {
        ...state,
        contents: state.contents.map(content => {
          if (content._id === action.payload) {
            return { ...content, progress: content.progress + 1 }
          } else {
            return content
          }
        }),
      }
    case SET_CURRENT_CONTENT:
      return {
        ...state,
        currentContent: action.payload,
      }
    default:
      return state
  }
}

export default courseReducer
