import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import { useAsync } from 'react-async'
import { validateDiscountCode } from '../../../lib/api'
import Button from '../../../components/Button'
import Card from '../../../components/Card'
import Heading from '../../../components/Heading'
import Icon from '../../../components/Icon'
import Text from '../../../components/Text'
import TextInput from '../../../components/TextInput'
import Radio from '../../../components/Radio'
import EcuadorPlans from './Ecuador/TypesOfPlans'
import EcuadorPayment from './Ecuador/TypesOfPayment'
import ColombiaPlans from './Colombia/TypesOfPlans'
import ColombiaPayment from './Colombia/TypesOfPayment'
import OtherCountryPayment from './OtherCountry/TypesOfPayment'
import ImageAccessPro from '../../../imagenes/image-access-pro.png'
import ImageEcuador from '../../../imagenes/ec.png'
import ImageColombia from '../../../imagenes/co.png'
import ImageOtherCountry from '../../../imagenes/other-country.png'
import { mediaQueries, spacing } from '../../../tokens'

interface Props {
  countryParam?: string
}

function SubscriptionPlan({ countryParam = 'ecuador' }: Props) {
  const history = useHistory()
  const [country, setCountry] = useState<string>()
  const [category, setCategory] = useState<string>('individual')
  const [showPayment, setShowPayment] = useState<boolean>(false)
  const [amountPaid, setAmountPaid] = useState<string>('')
  const [paypalId, setPaypalId] = useState<string>('')
  const [payUType, setPayUType] = useState<string>('')
  const [titleOfPlan, setTitleOfPlan] = useState<string>('')
  const [discountCode, setDiscountCode] = useState<string>('')
  const [isValidCode, setIsValidCode] = useState<boolean | null>(null)
  const [discount, setDiscount] = useState<number>(0)
  const [countryName, setCountryName] = useState<string>('')

  useEffect(() => {
    setCountry(countryParam)
    setDiscount(0)
    setDiscountCode('')
    setIsValidCode(null)
  }, [countryParam])

  function handlePlanEcuador(
    titleOfPlan: string,
    paypalId: string,
    amountPaid: string,
  ): void {
    setShowPayment(true)
    setPaypalId(paypalId)
    setAmountPaid(amountPaid)
    setTitleOfPlan(titleOfPlan)
  }

  function handlePlanColombia(
    titleOfPlan: string,
    typeOfPlan: string,
    amountPaid: string,
  ): void {
    setShowPayment(true)
    setPayUType(typeOfPlan)
    setAmountPaid(amountPaid)
    setTitleOfPlan(titleOfPlan)
  }

  function handleClosePayment(): void {
    setShowPayment(false)
    setPaypalId('')
  }

  function handleRadioCountry(event: React.ChangeEvent<HTMLInputElement>) {
    history.replace(`/suscripcion/${event.currentTarget.value}`)
  }

  function handleRadioCategory(event: React.ChangeEvent<HTMLInputElement>) {
    setCategory(event.currentTarget.value)
  }

  function handleDiscountCode(value: string) {
    setIsValidCode(null)
    setDiscountCode(value)
    setDiscount(0)
  }

  const { error, isLoading, run } = useAsync({
    deferFn: args => validateDiscountCode(args[0]),
    onResolve: (data: any) => {
      if (
        (data.country && data.countryName.toLowerCase() === country) ||
        (!data.country && data.code)
      ) {
        setIsValidCode(true)
        setDiscount(data.discount)
        setCountryName('')
      } else {
        setIsValidCode(false)
        setDiscount(0)
        setCountryName(data.countryName)
      }
    },
  })

  function handleSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault()
    run(discountCode)
  }

  return (
    <Wrapper>
      <Image alt="Subscripción" src={ImageAccessPro} />
      <Header>
        <Heading size={2}>Construye tu futuro hoy</Heading>
        <Subtitle>
          <Text>Suscríbete y accede a TODOS los cursos de alaU.</Text>
        </Subtitle>
      </Header>
      {!showPayment && (
        <>
          {country === 'ecuador' || country === 'otro' ? (
            <EcuadorPlans
              onClickPlan={handlePlanEcuador}
              onChangeCategory={handleRadioCategory}
              category={category}
              discount={discount}
            />
          ) : null}
          {country === 'colombia' && (
            <ColombiaPlans
              onClickPlan={handlePlanColombia}
              onChangeCategory={handleRadioCategory}
              category={category}
              discount={discount}
            />
          )}
          {category === 'individual' && (
            <>
              <Form onSubmit={handleSubmit}>
                <TextInput
                  label="Código de descuento"
                  name="discountCode"
                  onChange={handleDiscountCode}
                  inline={true}
                  required={true}
                  type="text"
                  value={discountCode}
                  placeholder="Ingresa tu código"
                />
                <ButtonContainer>
                  <Button
                    slim
                    status="success"
                    disabled={isLoading}
                    type="submit"
                  >
                    Aplicar
                  </Button>
                </ButtonContainer>
              </Form>
              {(error || (isValidCode !== null && !isValidCode)) && (
                <MessageContainer>
                  <Text status="danger">
                    {countryName
                      ? `El código no es válido para tu país`
                      : 'Código inválido'}
                  </Text>
                </MessageContainer>
              )}
              {!error && isValidCode && (
                <MessageContainer>
                  <Text status="success">
                    Se aplicó un descuento del {discount}% <br /> Recuerda que
                    el código solo aplica para planes individuales
                  </Text>
                </MessageContainer>
              )}
            </>
          )}
          <SwitchCountries>
            <Radio
              name="country"
              selected={country}
              onChange={handleRadioCountry}
              align="center"
            >
              <Radio.Option value={'ecuador'} direction="vertical">
                <CountryFlag>
                  <FlagImage src={ImageEcuador} alt="Ecuador" />
                  Ecuador
                </CountryFlag>
              </Radio.Option>
              <Radio.Option value={'colombia'} direction="vertical">
                <CountryFlag>
                  <FlagImage src={ImageColombia} alt="Ecuador" />
                  Colombia
                </CountryFlag>
              </Radio.Option>
              <Radio.Option value={'otro'} direction="vertical">
                <CountryFlag>
                  <FlagImage src={ImageOtherCountry} alt="Otro país" />
                  Otro
                </CountryFlag>
              </Radio.Option>
            </Radio>
          </SwitchCountries>
        </>
      )}
      {showPayment ? (
        <>
          <BackButton onClick={handleClosePayment}>
            <Icon name={'left-arrow'} size={'small'} />
            <Heading size={3}>{titleOfPlan}</Heading>
          </BackButton>
          <Card alignCenter={true}>
            <Card.Body>
              {country === 'ecuador' && (
                <EcuadorPayment paypalId={paypalId} amountPaid={amountPaid} />
              )}
              {country === 'colombia' && (
                <ColombiaPayment
                  typeOfPlan={payUType}
                  amountPaid={amountPaid}
                />
              )}
              {country === 'otro' && (
                <OtherCountryPayment paypalId={paypalId} />
              )}
            </Card.Body>
          </Card>
        </>
      ) : null}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  margin: 0 auto;
  width: 100%;
  position: relative;
  padding: 0px ${spacing.small} ${spacing.small};
  @media ${mediaQueries.mediumMin} {
    width: 768px;
  }
`

const Image = styled.img`
  height: 120px;
  margin: 0 auto;
  margin-top: ${spacing.small};
  display: block;
  @media ${mediaQueries.mediumMin} {
    height: 180px;
  }
`

const FlagImage = styled.img`
  height: 30px;
  margin: 0 auto;
`

const CountryFlag = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
`

const Header = styled.div`
  text-align: center;
`

const Subtitle = styled.div`
  margin-top: ${spacing.xsmall};
`

const SwitchCountries = styled.div`
  display: flex;
  margin-top: ${spacing.small};
  justify-content: center;
  margin-top: ${spacing.medium};
`

const BackButton = styled.div`
  align-items: center;
  cursor: pointer;
  display: flex;
  margin: ${spacing.small} 0px;
`

const Form = styled.form`
  align-items: center;
  display: flex;
  margin-top: ${spacing.medium};
  justify-content: center;
  @media ${mediaQueries.smallMax} {
    flex-direction: column;
  }
`

const ButtonContainer = styled.div`
  margin: 0px ${spacing.xsmall};
  @media ${mediaQueries.smallMax} {
    margin-top: ${spacing.xsmall};
  }
`

const MessageContainer = styled.div`
  margin-top: ${spacing.xsmall};
  text-align: center;
`

export default SubscriptionPlan
