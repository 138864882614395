import React from 'react'
import styled from 'styled-components'

import { colors, mediaQueries, spacing } from '../../../../tokens'
import Icon from '../../../../components/Icon'

interface Props {
  handleMenu: () => void
  toggleMenu: boolean
}
function TriggerMobileMenu({ handleMenu, toggleMenu }: Props) {
  return (
    <Wrapper>
      <ButtonIcon onClick={handleMenu}>
        <Icon status="primary" name={toggleMenu ? 'close' : 'menu'} />
      </ButtonIcon>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  background: ${colors.base.grayBackground};
  display: flex;
  @media ${mediaQueries.largeMin} {
    display: none;
  }
`

const ButtonIcon = styled.div`
  cursor: pointer;
  padding: ${spacing.small};
  padding-left: ${spacing.medium};
`

export default TriggerMobileMenu
