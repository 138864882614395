import React from 'react'
import styled from 'styled-components'
import Icon from '../Icon'
import Text from '../Text'
import { useUser } from '../../context/userContext'
import { colors, spacing } from '../../tokens'

function UserAvatar() {
  const { user } = useUser()
  const name = user ? user.nombre || user.email : 'No user'
  const image =
    user && user.fbid
      ? `https://graph.facebook.com/v3.1/${user.fbid}/picture?type=square&width=50`
      : null

  return (
    <User>
      <ImageContainer>
        {image ? <Image src={image} alt={'nombre'} /> : <Icon name="avatar" />}
      </ImageContainer>
      <Text>{name}</Text>
    </User>
  )
}

const User = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: ${spacing.small};
`

const ImageContainer = styled.div`
  margin-right: 5px;
`

const Image = styled.img`
  border-radius: 50%;
  border: 2px solid ${colors.base.grayLightest};
  height: 30px;
  width: 30px;
`

export default UserAvatar
