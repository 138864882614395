import React from 'react'

function IconoWarning() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="41"
      height="41"
      viewBox="0 0 41 41"
    >
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <g fill="#662482" fillRule="nonzero">
          <path d="M20.5 0C9.197 0 0 9.196 0 20.5S9.197 41 20.5 41 41 31.804 41 20.5 31.803 0 20.5 0zm0 39.423c-10.434 0-18.923-8.49-18.923-18.923 0-10.434 8.49-18.923 18.923-18.923 10.434 0 18.923 8.49 18.923 18.923 0 10.434-8.49 18.923-18.923 18.923z"></path>
          <path d="M20.5 7.885a.788.788 0 00-.788.788V26.02a.788.788 0 101.576 0V8.673a.788.788 0 00-.788-.788zM20.5 29.173a.788.788 0 00-.788.789v1.576a.788.788 0 101.576 0v-1.576a.788.788 0 00-.788-.789z"></path>
        </g>
      </g>
    </svg>
  )
}

export default IconoWarning
