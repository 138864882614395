// Auth
export const RESET_AUTH_SUCCESS = 'RESET_AUTH_SUCCESS'
export const FETCH_AUTH_REQUEST = 'FETCH_AUTH_REQUEST'
export const FETCH_AUTH_SUCCESS = 'FETCH_AUTH_SUCCESS'
export const RESET_CHANGED_PASSWORD = 'RESET_CHANGED_PASSWORD'
export const FETCH_AUTH_FAILURE = 'FETCH_AUTH_FAILURE'
export const REACTION_COMMENT_SUCCESS = 'REACTION_COMMENT_SUCCESS'
export const REACTION_COMMENT_FAILURE = 'REACTION_COMMENT_FAILURE'

//Comment
export const FETCH_COMMENT_REQUEST = 'FETCH_COMMENT_REQUEST'
export const FETCH_COMMENT_SUCCESS = 'FETCH_COMMENT_SUCCESS'
export const FETCH_COMMENT_FAILURE = 'FETCH_COMMENT_FAILURE'
export const CREATE_COMMENT_REQUEST = 'CREATE_COMMENT_REQUEST'
export const CREATE_COMMENT_SUCCESS = 'CREATE_COMMENT_SUCCESS'
export const CREATE_COMMENT_FAILURE = 'CREATE_COMMENT_FAILURE'

//Curso
export const FETCH_CURSO_SUCCESS = 'FETCH_CURSO_SUCCESS'
export const FETCH_COURSE_SUCCESS = 'FETCH_COURSE_SUCCESS'
export const FETCH_COURSE_SUCCESS_COMPATIBILITY =
  'FETCH_COURSE_SUCCESS_COMPATIBILITY'
export const FETCH_CURSO_REQUEST = 'FETCH_CURSO_REQUEST'
export const FETCH_CURSO_FAILURE = 'FETCH_CURSO_FAILURE'

// Practice
export const FETCH_PRACTICE_REQUEST = 'FETCH_PRACTICE_REQUEST'
export const FETCH_PRACTICE_SUCCESS = 'FETCH_PRACTICE_SUCCESS'
export const FETCH_PRACTICE_FAILURE = 'FETCH_PRACTICE_FAILURE'

//Purchase
export const VALIDATE_COURSE_REQUEST = 'VALIDATE_COURSE_REQUEST'
export const VALIDATE_COURSE_SUCCESS = 'VALIDATE_COURSE_SUCCESS'
export const VALIDATE_COURSE_FAILURE = 'VALIDATE_COURSE_FAILURE'
export const ACTIVATE_COURSE_REQUEST = 'ACTIVATE_COURSE_REQUEST'
export const ACTIVATE_COURSE_SUCCESS = 'ACTIVATE_COURSE_SUCCESS'
export const ACTIVATE_COURSE_FAILURE = 'ACTIVATE_COURSE_FAILURE'

//Quiz
export const FETCH_QUIZ_REQUEST = 'FETCH_QUIZ_REQUEST'
export const FETCH_QUIZ_SUCCESS = 'FETCH_QUIZ_SUCCESS'
export const FETCH_QUIZ_FAILURE = 'FETCH_QUIZ_FAILURE'
export const CREATE_QUIZ_REQUEST = 'CREATE_QUIZ_REQUEST'
export const CREATE_QUIZ_SUCCESS = 'CREATE_QUIZ_SUCCESS'
export const CREATE_QUIZ_FAILURE = 'CREATE_QUIZ_FAILURE'
export const RESET_QUIZ_SUCCESS = 'RESET_QUIZ_SUCCESS'
