import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Button from '../Button'
import { H4 } from '../textos/Heading'
import { colors, fonts } from '../../tokens'

const Container = styled.div`
  font-family: ${fonts.family.default};
  border-bottom: 1px solid ${colors.base.grayLightest};
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 0px;

  .title {
    flex: 1;

    h4 {
      margin: 0px;
    }

    .small {
      font-family: ${fonts.family.default};
      color: ${colors.base.grayLight};
      line-height: 1.3;
      font-weight: 600;
      padding: 5px 0px;
      font-size: 14px;
    }
  }

  .nav {
    margin: 5px;
  }

  @media screen and (max-width: 767px) {
    flex-direction: column;
    margin-top: 20px;

    .title {
      text-align: center;
    }
  }
`

function Header(props) {
  const { index, total, answered, name, onClick, onSubmit } = props
  return (
    <Container>
      <div className="title">
        <H4>{name}</H4>
        <div className="small">
          Item {index + 1} de {total}
        </div>
      </div>
      <div className="nav">
        <Button onClick={e => onClick(index - 1, e)} disabled={index === 0}>
          Anterior
        </Button>
      </div>
      <div className="nav">
        <Button
          onClick={e => onClick(index + 1, e)}
          disabled={index + 1 === total}
        >
          Siguiente
        </Button>
      </div>
      {total === answered && (
        <div className="nav">
          <Button onClick={onSubmit} status="success">
            Finalizar
          </Button>
        </div>
      )}
    </Container>
  )
}

Header.propTypes = {
  index: PropTypes.number.isRequired,
  total: PropTypes.number.isRequired,
  answered: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
}

export default Header
