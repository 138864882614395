import React from 'react'
import styled from 'styled-components'
import Logo from '../components/Logo'
import Heading from '../components/Heading'
import Text from '../components/Text'

function Error({ error }: { error?: Error }) {
  let errorMessage = ''
  if (error) {
    errorMessage = error.message ? error.message : 'Error desconocido'
  }
  return (
    <Container>
      <div>
        <Logo size="large" />
        <Heading>Ups ha ocurrido un error</Heading>
        <br />
        <Text>Intenta refrescar la página</Text>
        <br />
        <pre>{errorMessage}</pre>
      </div>
    </Container>
  )
}

const Container = styled.div`
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
`

export default Error
