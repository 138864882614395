import React from 'react'
import styled from 'styled-components'
import Item from './Item'

export type typeCols = 1 | 2 | 3 | 4 | 5 | 6

interface Props {
  /** The number of cols. Default value is 4 */
  cols?: typeCols
  /** The content to display inside the gallery. */
  children?: React.ReactNode | React.ReactNodeArray
}

function transformChild(
  children: React.ReactNode,
  index: number,
  cols: typeCols,
) {
  return (
    <Item cols={cols} key={index}>
      {children}
    </Item>
  )
}

function transformChildren({ children, cols = 4 }: Props) {
  if (Array.isArray(children)) {
    return children.map((item: any, index: number) =>
      transformChild(item, index, cols),
    )
  } else if (children) {
    return transformChild(children, 1, cols)
  }
}

const GalleryLayout = ({ cols, children }: Props) => (
  <Wrapper data-testid="wrapper-div">
    {transformChildren({ children, cols })}
  </Wrapper>
)

const Wrapper = styled.div<Props>`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`

export default GalleryLayout
