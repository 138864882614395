import { combineReducers } from 'redux'

const actual = (state = null, action) => {
  switch (action.type) {
    case 'FETCH_COURSE_SUCCESS':
      return action.response._id
    case 'FETCH_COURSE_SUCCESS_COMPATIBILITY':
      return action.response.actual
    case 'ACTIVATE_COURSE_SUCCESS':
      return action.response.course._id
    case 'RESET_AUTH_SUCCESS':
      return null
    default:
      return state
  }
}

const nombre = (state = null, action) => {
  switch (action.type) {
    case 'FETCH_COURSE_SUCCESS':
      return action.response.nombre
    case 'FETCH_COURSE_SUCCESS_COMPATIBILITY':
      return action.response.nombre
    case 'ACTIVATE_COURSE_SUCCESS':
      return action.response.course.name
    default:
      return state
  }
}

const slug = (state = '', action) => {
  switch (action.type) {
    case 'FETCH_COURSE_SUCCESS_COMPATIBILITY':
      return action.response.slug
    case 'FETCH_COURSE_SUCCESS':
      return action.response.slug
    default:
      return state
  }
}

const isFetching = (state = false, action) => {
  switch (action.type) {
    case 'FETCH_CURSO_REQUEST':
      return true
    case 'FETCH_CURSO_SUCCESS':
    case 'FETCH_CURSO_FAILURE':
      return false
    default:
      return state
  }
}

const errorMessage = (state = null, action) => {
  switch (action.type) {
    case 'FETCH_CURSO_FAILURE':
      return action.message
    case 'FETCH_CURSO_REQUEST':
    case 'FETCH_CURSO_SUCCESS':
      return null
    default:
      return state
  }
}

const curso = combineReducers({
  actual,
  nombre,
  slug,
  isFetching,
  errorMessage,
})

export default curso
