import React from 'react'

import OffsetInCircunference from './OffsetInCircunference'

interface Props {
  color: string
  percentage: number
}

const Circles = ({ color, percentage }: Props) => {
  const offsetInCircunference = OffsetInCircunference()
  const lengthOfBlankPart = offsetInCircunference.calculateLengthOfBlankPart(
    percentage,
  )
  return (
    <svg width="60px" height="60px">
      <circle
        data-testid="circunference"
        r="28"
        cx="30"
        cy="30"
        strokeWidth="4"
        stroke={color}
        strokeLinecap="round"
        fill="transparent"
        strokeDasharray="175.92918860102841 175.92918860102841"
        strokeDashoffset={lengthOfBlankPart}
      >
        <animate
          data-testid="animation"
          attributeType="XML"
          attributeName="stroke-dashoffset"
          from="175.92918860102841"
          to={lengthOfBlankPart}
          dur="1s"
          repeatCount="1"
        ></animate>
      </circle>
      <circle r="24" cx="30" cy="30" fill={color} data-testid="circle"></circle>
    </svg>
  )
}

export default Circles
