import React from 'react'

const BgZigzag = () => (
  <svg
    width="37px"
    height="45px"
    viewBox="0 0 37 45"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g
      id="Page-1"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
      opacity="0.300000012"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <g
        id="1.-login"
        transform="translate(-388.000000, -464.000000)"
        stroke="#A0AAAD"
        strokeWidth="9"
      >
        <polyline
          id="Stroke-6"
          points="420 469 399.323539 476.096993 416.375611 481.434892 396.088388 490.734058 413.798763 494.460557 393 504"
        ></polyline>
      </g>
    </g>
  </svg>
)

export default BgZigzag
