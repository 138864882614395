import {
  INITIALIZE_PRACTICE,
  CHOOSE_ANSWER,
  VALIDATE_ANSWER_REQUEST,
  VALIDATE_ANSWER_SUCCESS,
  SHOW_NEXT_QUESTION,
} from '../types'

const practiceReducer = (state, action) => {
  switch (action.type) {
    case INITIALIZE_PRACTICE:
      return {
        ...state,
        exercises: action.payload.ejercicios,
        simulator: action.payload.simulador,
        total: action.payload.ejercicios.length,
        question: action.payload.ejercicios[0],
        isFetching: false,
      }
    case CHOOSE_ANSWER: {
      return {
        ...state,
        chosenAnswer: action.payload,
      }
    }
    case VALIDATE_ANSWER_REQUEST:
      return {
        ...state,
        isFetching: true,
      }
    case VALIDATE_ANSWER_SUCCESS: {
      return {
        ...state,
        correctAnswer: action.payload,
        isFetching: false,
      }
    }
    case SHOW_NEXT_QUESTION: {
      return {
        ...state,
        index: action.payload,
        question: state.exercises[action.payload],
        correctAnswer: null,
        chosenAnswer: null,
      }
    }
    default:
      return state
  }
}

export default practiceReducer
