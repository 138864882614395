import { combineReducers } from 'redux'

const resetPassword = (state = false, action) => {
  switch (action.type) {
    case 'FETCH_AUTH_SUCCESS':
      return action.response.resetPassword || state
    case 'FETCH_AUTH_FAILURE':
    case 'RESET_AUTH_SUCCESS':
      return false
    default:
      return state
  }
}

const validToken = (state = false, action) => {
  switch (action.type) {
    case 'FETCH_AUTH_SUCCESS':
      return true
    case 'RESET_AUTH_SUCCESS':
      return false
    default:
      return state
  }
}

const changedPassword = (state = false, action) => {
  switch (action.type) {
    case 'FETCH_AUTH_SUCCESS':
      return true
    case 'FETCH_AUTH_FAILURE':
      return false
    case 'RESET_CHANGED_PASSWORD':
      return false
    default:
      return state
  }
}

const froalaSignature = (state = null, action) => {
  switch (action.type) {
    case 'FETCH_COURSE_SUCCESS':
      return action.response.froalaSignature || state
    case 'FETCH_COURSE_SUCCESS_COMPATIBILITY':
      return action.response.froalaSignature
    default:
      return state
  }
}

const isFetching = (state = false, action) => {
  switch (action.type) {
    case 'FETCH_AUTH_REQUEST':
      return true
    case 'FETCH_AUTH_SUCCESS':
    case 'FETCH_AUTH_FAILURE':
    case 'RESET_AUTH_SUCCESS':
      return false
    default:
      return state
  }
}

const errorMessage = (state = null, action) => {
  switch (action.type) {
    case 'FETCH_AUTH_FAILURE':
      return action.message
    case 'FETCH_AUTH_REQUEST':
    case 'FETCH_AUTH_SUCCESS':
    case 'RESET_AUTH_SUCCESS':
      return null
    default:
      return state
  }
}

const auth = combineReducers({
  resetPassword,
  validToken,
  changedPassword,
  froalaSignature,
  isFetching,
  errorMessage,
})

export default auth
