import React from 'react'

const BrainSmall = () => {
  return (
    <svg
      width="31px"
      height="34px"
      viewBox="0 0 31 34"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xlink="http://www.w3.org/1999/xlink"
    >
      <g
        id="Page-1"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g id="dashboard-v2" transform="translate(-237.000000, -450.000000)">
          <g id="Group-31" transform="translate(155.000000, 435.000000)">
            <g
              id="thin-1039_brain_thinking_mind_knowledge"
              transform="translate(81.000000, 16.000000)"
            >
              <g
                id="Group"
                transform="translate(2.688000, 0.533333)"
                stroke="#FFFFFF"
                strokeWidth="1"
              >
                <path
                  d="M16.9996501,23.596 C17.9249941,21.1821333 23.8535125,21.4388 22.3800853,26.5725333 C20.5163605,33.0658667 13.7783509,29.4436 13.7783509,29.4436 L13.7783509,1.20693333 C13.7783509,1.20693333 19.6187029,-2.29413333 21.2833813,5.50933333 C21.2833813,5.50933333 24.5732245,5.95133333 24.8474005,10.5749333 C24.8474005,10.5749333 29.6489749,14.3833333 25.5328405,19.1422667 C24.1158613,20.7805333 22.3804885,19.8364 21.3207445,18.952"
                  id="Shape"
                />
                <path
                  d="M1.82481493,18.9041333 C-2.29131947,14.1452 2.70930133,10.5749333 2.70930133,10.5749333 C2.98347733,5.95133333 6.27332053,5.50933333 6.27332053,5.50933333 C8.17306453,-2.294 13.7783509,1.20693333 13.7783509,1.20693333 L13.7783509,29.4436 C10.5899797,31.2304 6.84895573,31.1085333 5.37821653,27.47 C4.52491093,25.3590667 5.57712853,23.7658667 5.57712853,23.7658667"
                  id="Shape"
                />
                <path
                  d="M22.3800853,26.5725333 C22.3800853,26.5725333 27.3148501,24.752 25.5328405,19.1424"
                  id="Shape"
                />
                <path
                  d="M13.7783509,12.2204 C13.7783509,12.2204 17.2898197,10.1230667 19.5098389,11.5366667"
                  id="Shape"
                />
                <path
                  d="M20.8379797,8.85946667 C20.0330581,9.85173333 18.8649877,11.8248 19.6428949,14.2805333"
                  id="Shape"
                />
                <path
                  d="M5.57712853,15.3909333 C5.57712853,15.3909333 -3.44205227,19.0189333 5.29892053,27.2556"
                  id="Shape"
                />
                <path
                  d="M6.27332053,5.50933333 C6.27332053,5.50933333 8.71173973,5.60373333 8.48151253,8.76866667"
                  id="Shape"
                />
                <path
                  d="M5.8218688,11.2333333 C5.8218688,11.2333333 9.6700096,13.0293333 8.7161728,16.3170667"
                  id="Shape"
                />
                <path
                  d="M10.2574741,20.9068 C10.2574741,20.9068 7.12729813,23.1824 9.59972053,25.6310667"
                  id="Shape"
                />
              </g>
              <rect
                id="Rectangle-path"
                x="0.258920533"
                y="0"
                width="32.256"
                height="32"
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  )
}

export default BrainSmall
