import React from 'react'
import styled from 'styled-components'
import Text from '../../../components/Text'
import { spacing } from '../../../tokens'
import ImagePichincha from '../../../imagenes/logo-pichincha.png'
import ImageProdubanco from '../../../imagenes/logo-produbanco.png'
import ImageBancolombia from '../../../imagenes/logo-bancolombia.png'

interface Props {
  account: string
  description: string
  bank: 'pichincha' | 'produbanco' | 'bancolombia'
}

const BankAccount = ({ account, description, bank }: Props) => {
  let bankImage
  switch (bank) {
    case 'pichincha':
      bankImage = ImagePichincha
      break
    case 'produbanco':
      bankImage = ImageProdubanco
      break
    default:
      bankImage = ImageBancolombia
      break
  }
  return (
    <Wrapper>
      <img src={bankImage} alt={bank} />
      <div>
        <Text>{account}</Text>
        <br />
        <Text>{description}</Text>
      </div>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  flex-direction: column;
  padding: ${spacing.small} 0px;
`

export default BankAccount
