import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import styled, { css } from 'styled-components'
import { useAsync } from 'react-async'
import { useHistory, useParams } from 'react-router-dom'

import { colors, mediaQueries } from '../../tokens'
import ContentLeft from './components/ContentLeft'
import CourseBanner from './components/CourseBanner'
import CourseContent from './components/CourseContent'
import {
  CourseFromApi,
  CourseWithContents,
  ContentWithLessons,
} from '../../lib/formatApiObjects'
import { getCourse, getCourseContent } from '../../lib/api'
import Layout from '../../components/Layout'
import Loading from '../Loading'
import NotEnrolled from '../NotEnrolled'
import TriggerMobileMenu from './components/TriggerMobileMenu'
import Page from '../../components/Page'
import { saveState, loadState } from '../../util/localStorage'
import { useNotification } from '../../context/notificationContext'
import {
  CourseProvider,
  useCourseContext,
  useContentContext,
} from './context/CourseProvider'
import {
  INITIALIZE_COURSE,
  SET_CURRENT_CONTENT,
} from './reducers/courseReducer'
import { INITIALIZE_CONTENT, FETCHING_CONTENT } from './reducers/contentReducer'

interface Props {
  [propName: string]: any
}

const CourseComponent = ({ addDataToCursoReduxState }: Props) => {
  const history: any = useHistory()
  const params: any = useParams()
  const { notify } = useNotification()

  const [toggleMobileMenu, setToggleMobileMenu] = useState<boolean>(true)
  const { courseWithContents, dispatchCourse } = useCourseContext()
  const { dispatchContent } = useContentContext()

  const getLessonsOfContent = useAsync({
    deferFn: args => getCourseContent(args[0]),
    onReject: error => notify(error.message, 'error'),
    onResolve: (data: ContentWithLessons) => {
      dispatchContent({
        type: INITIALIZE_CONTENT,
        payload: data,
      })
    },
  })

  useEffect(() => {
    let currentContent: string | null
    if (thereIsContentInParams() || thereIsAtLeastOneContent()) {
      currentContent = params.content || courseWithContents.contents[0]._id
      dispatchContent({
        type: FETCHING_CONTENT,
      })
      dispatchCourse({
        type: SET_CURRENT_CONTENT,
        payload: currentContent,
      })
      getLessonsOfContent.run(currentContent)
      setToggleMobileMenu(false)
    }
  }, [courseWithContents.currentContent, params.content])

  function thereIsContentInParams() {
    return params && params.content
  }

  function thereIsAtLeastOneContent() {
    return courseWithContents.contents && courseWithContents.contents.length > 0
  }

  function handleClickContent(contentId: string) {
    history.push(`/cursos/${params.slug}/${contentId}`)
  }

  const {
    data,
    error,
    isLoading,
  }: {
    data: CourseFromApi | undefined
    error: any
    isLoading: boolean
  } = useAsync({
    promiseFn: getCourse,
    slug: params.slug,
    onReject: error => {
      notify(error.message, 'error')
    },
    onResolve: (data: CourseFromApi) => {
      const payload: CourseWithContents = {
        id: data._id,
        title: data.title,
        slug: data.slug,
        contents: data.contents,
        currentContent: data.contents.length ? data.contents[0]._id : '',
        hasFinalQuiz: data.hasFinalQuiz,
        finalQuiz: data.finalQuiz,
        urlWebSite: data.urlWebSite,
        filledRequirements: data.filledRequirements,
      }
      dispatchCourse({
        type: INITIALIZE_COURSE,
        payload,
      })
      //backward compatibility with Lesson Detail and Froala
      const compatibilityWithReduxCourse = {
        actual: data._id,
        slug: data.slug,
        nombre: data.title,
        froalaSignature: data.froalaSignature,
      }
      addDataToCursoReduxState(compatibilityWithReduxCourse)
      //end of backward compatibility with Lesson Detail
    },
  })

  function handleMenu(): void {
    toggleMobileMenu ? setToggleMobileMenu(false) : setToggleMobileMenu(true)
  }

  if (isLoading) {
    return <Loading />
  }

  if (error && error.name === 'Forbidden') {
    const {
      custom: { redirectTo },
    } = error

    return <NotEnrolled redirectTo={redirectTo} message={error.message} />
  }

  return (
    <Page useFullWidth={true} hasFooter={false}>
      <Layout>
        <Layout.Section background={true} padding={false} secondary={true}>
          <TriggerMobileMenu
            handleMenu={handleMenu}
            toggleMenu={toggleMobileMenu}
          />
          <WrapperLeft toggleMenu={toggleMobileMenu}>
            <ContentLeft onClickContent={handleClickContent} />
          </WrapperLeft>
        </Layout.Section>
        <Layout.Section heightOfViewPort={true}>
          <MaxWidthContent>
            <CourseBanner course={data ? data._id : ''} />
            <CourseContent />
          </MaxWidthContent>
        </Layout.Section>
      </Layout>
    </Page>
  )
}

const WrapperLeft = styled.div<{ toggleMenu: boolean }>`
  border-right: 1px solid ${colors.base.grayLightest};
  height: 100%;
  position: relative;
  @media ${mediaQueries.mediumMax} {
    ${props =>
      !props.toggleMenu &&
      css`
        height: 0px;
      `}
  }
`

const MaxWidthContent = styled.div`
  margin: 0 auto;
  max-width: 850px;
`

/* Backward compability with redux */
const mapDispatchToProps = (dispatch: any) => ({
  addDataToCursoReduxState: (data: any) => {
    dispatch({
      type: 'FETCH_COURSE_SUCCESS_COMPATIBILITY',
      response: data,
    })
    saveState('curso', {
      actual: data.actual,
      slug: data.slug,
      nombre: data.nombre,
    })
    const authFromState = loadState('auth')
    if (authFromState) {
      authFromState.froalaSignature = data.froalaSignature
      saveState('auth', authFromState)
    }
  },
})

/* Backward compability with redux */
const Course = connect(null, mapDispatchToProps)(CourseComponent)

const CourseWithProvider = () => (
  <CourseProvider>
    <Course />
  </CourseProvider>
)

export default CourseWithProvider
