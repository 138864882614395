import React from 'react'
import styled, { css } from 'styled-components'
import { colors } from '../../tokens'
import { icons } from './icons.json'

interface Props {
  /** Icon background Color */
  background?:
    | 'default'
    | 'white'
    | 'light'
    | 'primary'
    | 'success'
    | 'warning'
    | 'danger'
  /** Icon name */
  name?:
    | 'play-arrow'
    | 'left-arrow'
    | 'right-arrow'
    | 'close'
    | 'avatar'
    | 'check'
    | 'facebook'
    | 'instagram'
    | 'padlock'
    | 'twitter'
    | 'uncheck'
    | 'watch'
    | 'youtube'
    | 'users'
    | 'quiz'
    | 'questions'
    | 'results'
    | 'practice'
    | 'menu'
    | 'credit-card'
    | 'bill'
    | 'warning'
    | 'certificate'
    | 'download'
  /** Icon size */
  size?: 'xsmall' | 'small' | 'medium' | 'large'
  /** Icon Color */
  status?:
    | 'default'
    | 'white'
    | 'light'
    | 'primary'
    | 'success'
    | 'warning'
    | 'danger'
}

interface Size {
  [propName: string]: any
}

const sizes: Size = {
  xsmall: { width: 24, height: 24 },
  small: { width: 36, height: 36 },
  medium: { width: 48, height: 48 },
  large: { width: 64, height: 64 },
}

const Icon = ({
  background,
  name = 'avatar',
  size = 'xsmall',
  status = 'light',
}: Props) => {
  return (
    <Wrapper background={background} size={size}>
      <Svg viewBox="0 0 20 20" status={status}>
        {icons[name].paths.map((path: any, index: number) => (
          <path key={index} d={path} />
        ))}
      </Svg>
    </Wrapper>
  )
}

const Wrapper = styled.div<Props>`
  display: inline-block;
  ${props =>
    props.background &&
    css`
      background-color: ${colors[props.background]().color};
      border-radius: 50%;
    `}

  ${props =>
    props.size &&
    css`
      height: ${sizes[props.size].height}px;
      width: ${sizes[props.size].width}px;
    `}
`

const Svg = styled.svg<Props>`
  height: 100%;
  width: 100%;
  ${props =>
    props.status &&
    css`
      fill: ${colors[props.status]().color};
    `}
`

export default Icon
