import React, { Component } from 'react'
import styled from 'styled-components'
import { SyncLoader } from 'react-spinners'
import Alert from './Alert'
import { H1 } from './textos/Heading'
import Button from './Button'
import Header from '../pages/Practice/components/Header'
import ResultadoCalificacion from './paneles/ResultadoCalificacion'
import IconoError from './iconos/figuras/IconoError'
import IconoCorrecto from './iconos/figuras/IconoCorrecto'
import IconoNose from './iconos/figuras/IconoNose'
import { colors } from '../tokens'

const CenterContainer = styled.div`
  margin-top: 40px;
  width: 100%;
  display: flex;
  justify-content: center;
`

const ResultadoContainer = styled.div`
  padding-top: 40px;
`

const Botones = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 50px;
  @media screen and (max-width: 767px) {
    flex-direction: column;
    margin-top: 20px;
    & > div {
      margin: 5px 0;
    }
  }
  @media screen and (max-width: 567px) {
    & > div {
      width: 100%;
    }
  }
`

const Respuestas = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media screen and (max-width: 767px) {
    flex-direction: column;
  }
`

const ItemRespuesta = styled.div`
  display: flex;
  align-items: center;
  p {
    margin-left: 10px;
  }
  @media screen and (max-width: 767px) {
    p {
      margin: 5px 0 10px 10px;
    }
    svg {
      width: 20px !important;
      height: 20px !important;
    }
  }
`

const ItemRespuestaCorrecta = styled(ItemRespuesta)`
  color: ${colors.base.green};
`

const ItemRespuestaIncorrecta = styled(ItemRespuesta)`
  color: ${colors.base.red};
`

const ItemRespuestaNose = styled(ItemRespuesta)`
  color: ${colors.base.gray};
`

class Resultado extends Component {
  componentDidMount() {
    this.props.fetchResultado(this.props.match.params.simulador)
  }

  render() {
    const { attemp, isFetching, errorMessage } = this.props

    if (isFetching) {
      return (
        <CenterContainer>
          <SyncLoader color={colors.base.purple} loading={isFetching} />
        </CenterContainer>
      )
    }

    return (
      <ResultadoContainer>
        <Alert error={errorMessage} />

        <Header
          index={attemp.respuestasTotal || 0}
          slug={this.props.slug}
          total={attemp.respuestasTotal || 1}
          content={attemp.contenido}
        />

        <div className="content pure-g">
          <div className="pure-u-1 pure-u-md-1-5" />
          <div className="pure-u-1 pure-u-md-3-5">
            <div>
              <ResultadoCalificacion
                respuestasCorrectas={attemp.respuestasCorrectas}
                respuestasTotal={attemp.respuestasTotal}
                nota={`${attemp.respuestasCorrectas}/${attemp.respuestasTotal}`}
              />
              <p className="text-center">Tu calificación</p>
              <H1 className="text-center">
                ¡Has llegado al final de la práctica!
              </H1>
              <Respuestas>
                <ItemRespuestaCorrecta>
                  <IconoCorrecto />
                  <p>Correctas: {attemp.respuestasCorrectas}</p>
                </ItemRespuestaCorrecta>
                <ItemRespuestaIncorrecta>
                  <IconoError />
                  <p>Incorrectas: {attemp.respuestasIncorrectas}</p>
                </ItemRespuestaIncorrecta>
                <ItemRespuestaNose>
                  <IconoNose />
                  <p>No se: {attemp.respuestasNose}</p>
                </ItemRespuestaNose>
              </Respuestas>
              <Botones>
                <div className="mx1">
                  <Button
                    to={`/simulador/${this.props.slug}/${attemp.contenido}`}
                    status="light"
                  >
                    Repetir
                  </Button>
                </div>
                <div className="mx1">
                  <Button to={`/cursos/${this.props.slug}/${attemp.contenido}`}>
                    Continuar
                  </Button>
                </div>
              </Botones>
            </div>
          </div>
          <div className="pure-u-1 pure-u-md-1-5" />
        </div>
      </ResultadoContainer>
    )
  }
}

export default Resultado
