import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { secondsToTime } from '../../util/date'
import { colors, fonts } from '../../tokens'

const Container = styled.div`
  background: ${colors.base.white};
  text-align: center;
`
const TotalBar = styled.div`
  height: 8px;
  background: ${colors.base.grayLightest};
  flex: 1;
  position: relative;
  overflow: hidden;
  padding: 0px !important;
`
const ProgressBar = styled.div`
  position: absolute;
  background-image: linear-gradient(-224deg, #c074c7 0%, #895ee4 100%);
  width: ${props => props.percentage}%;
  height: 100%;
  transition: width 2s;
`
const Time = styled.div`
  font-family: ${fonts.family.default};
  color: ${colors.base.gray};
  line-height: 1.3;
  font-size: 36px;
  font-weight: 600;
  padding: 15px 5px;

  div {
    font-size: 14px;
    color: ${colors.base.grayLight};
  }
`

class Timer extends Component {
  static propTypes = {
    seconds: PropTypes.number.isRequired,
    onEnd: PropTypes.func.isRequired,
    start: PropTypes.bool.isRequired,
  }

  constructor(props) {
    super(props)
    this.state = { time: {}, seconds: props.seconds }
    this.timer = 0
    this.startTimer = this.startTimer.bind(this)
    this.countDown = this.countDown.bind(this)
  }

  componentDidMount() {
    let timeLeftVar = secondsToTime(this.state.seconds)
    this.setState({ time: timeLeftVar })
  }

  componentDidUpdate(prevProps) {
    if (this.props.start !== prevProps.start) {
      this.startTimer()
    }
  }

  componentWillUnmount() {
    clearInterval(this.timer)
  }

  startTimer() {
    if (this.timer === 0) {
      this.timer = setInterval(this.countDown, 1000)
    }
  }

  countDown() {
    // Remove one second, set state so a re-render happens.
    let seconds = this.state.seconds - 1
    this.setState({
      time: secondsToTime(seconds),
      seconds: seconds,
    })

    // Check if we're at zero.
    if (seconds === 0) {
      clearInterval(this.timer)
      this.props.onEnd()
    }
  }

  render() {
    const { h, m, s } = this.state.time
    const percentage = Math.round(
      (this.state.seconds * 100) / this.props.seconds,
    )

    return (
      <Container>
        <TotalBar>
          <ProgressBar percentage={percentage} />
        </TotalBar>
        <Time>
          <span>{h}</span>:<span>{m}</span>:<span>{s}</span>
          <div>Tiempo restante</div>
        </Time>
      </Container>
    )
  }
}

export default Timer
