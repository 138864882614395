import React, { createContext, useContext, useReducer } from 'react'
import courseReducer from '../reducers/courseReducer'
import contentReducer from '../reducers/contentReducer'

const CourseContext = createContext()
const ContentContext = createContext()

function CourseProvider({ children }) {
  const [courseWithContents, dispatchCourse] = useReducer(courseReducer, [])
  const [contentWithLessons, dispatchContent] = useReducer(contentReducer, [])
  return (
    <CourseContext.Provider value={{ courseWithContents, dispatchCourse }}>
      <ContentContext.Provider value={{ contentWithLessons, dispatchContent }}>
        {children}
      </ContentContext.Provider>
    </CourseContext.Provider>
  )
}

function useCourseContext() {
  const context = useContext(CourseContext)
  if (context === undefined) {
    throw new Error('useCourseContext must be used within a CourseProvider')
  }
  return context
}

function useContentContext() {
  const context = useContext(ContentContext)
  if (context === undefined) {
    throw new Error('useContentContext must be used within a CourseProvider')
  }
  return context
}

export { CourseProvider, useCourseContext, useContentContext }
