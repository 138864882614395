import React from 'react'
import styled from 'styled-components'

import BackgroundFigures from './BackgroundFigures'
import { spacing } from '../../tokens'

interface Props {
  /** Set true to add background figures. */
  background?: boolean
  /** The content to display inside the column. */
  children?: React.ReactNode
}

const Column = ({ background, children }: Props) => {
  function showBackgroundFigures() {
    if (background) {
      return <BackgroundFigures />
    }
  }

  return (
    <Wrapper data-testid="wrapper-div">
      {children}
      {showBackgroundFigures()}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: ${spacing.large};
`

export default Column
