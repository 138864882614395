//export interface MyCoursesProps {}
import React from 'react'
import { useAsync } from 'react-async'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { getMyCourses } from '../../lib/api'
import { useNotification } from '../../context/notificationContext'
import Card from '../../components/Card'
import GalleryLayout from '../../components/GalleryLayout'
import Heading from '../../components/Heading'
import Loading from '../Loading'
import Page from '../../components/Page'
import Text from '../../components/Text'
import ImageCourseDefault from '../../imagenes/course-default.png'
import { spacing } from '../../tokens'
import { formatDate } from '../../util/date'

interface Course {
  _id: string
  image: string
  name: string
  description: boolean
  slug: string
  validUntil: string
  inscriptionDate: string
}

const MyCourses = () => {
  const { notify } = useNotification()
  const { data = [], isLoading } = useAsync({
    promiseFn: getMyCourses,
    onReject: error => notify(error.message, 'error'),
  })

  if (isLoading) {
    return <Loading />
  }

  return (
    <Page>
      <TitleCourses>
        <Heading size={2}>Mis Cursos</Heading>
      </TitleCourses>
      <GalleryLayout cols={4}>
        {data.map((course: Course, index: number) => (
          <WrapperLink
            data-testid="wrapper-div-course"
            key={index}
            to={`/cursos/${course.slug}`}
          >
            <Card fillContent={true}>
              <Card.Header
                backgroundImage={course.image || ImageCourseDefault}
              />
              <Card.Body>
                <Detail>
                  <div>
                    {course.validUntil && (
                      <Text status="light">
                        Acceso hasta: {formatDate(new Date(course.validUntil))}
                      </Text>
                    )}
                    <Heading size={2}>{course.name}</Heading>
                    <Text>{course.description}</Text>
                  </div>
                </Detail>
              </Card.Body>
            </Card>
          </WrapperLink>
        ))}
      </GalleryLayout>
      <Separator />
    </Page>
  )
}

const TitleCourses = styled.div`
  padding: ${spacing.large} ${spacing.xsmall} ${spacing.medium};
`

const WrapperLink = styled(Link)`
  display: block;
  height: 100%;
  position: relative;
  text-decoration: none;
  transition: all 0.25s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  &:hover {
    transform: translateY(-1px);
    opacity: 0.9;
  }
`

const Detail = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
`

const Separator = styled.div`
  margin: ${spacing.xlarge} 0px;
`

export default MyCourses
