import React, { useContext } from 'react'
import { useAsync } from 'react-async'
import { getUser } from '../lib/api'
import ErrorPage from '../pages/Error'
import LoadingPage from '../pages/Loading'

interface UserInterface {
  user: {
    _id: string
    email: string
    fbid?: string
    nombre: string
    cursos: any[]
    pro: boolean
    fechaHasta: string
    nombreCertificado: string
  }
  reloadUser(): void
}

interface Props {
  children: React.ReactNode
}

const UserContext = React.createContext<UserInterface | undefined>(undefined)

async function bootstrapUserData() {
  const data = await getUser()
  if (!data) {
    return null
  }
  return data
}

function UserProvider({ children }: Props) {
  const { data, error, isLoading, isRejected, reload } = useAsync({
    promiseFn: bootstrapUserData,
  })

  if (isLoading) {
    return <LoadingPage logo />
  }

  if (isRejected) {
    return <ErrorPage error={error} />
  }

  const reloadUser = reload

  return (
    <UserContext.Provider value={{ user: data, reloadUser }}>
      {children}
    </UserContext.Provider>
  )
}

function useUser() {
  const context = useContext(UserContext)
  if (context === undefined) {
    throw new Error(`useUser must be used within a UserProvider`)
  }
  return context
}

export { UserProvider, UserContext, useUser }
