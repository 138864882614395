export const GA_TRACKING_ID = 'UA-45606948-3'

// https://developers.google.com/analytics/devguides/collection/gtagjs/pages
export const pageview = url => {
  window.gtag('config', GA_TRACKING_ID, {
    page_location: url,
  })
}

// https://developers.google.com/analytics/devguides/collection/gtagjs/events
export const event = ({ action, sent_to, value, transaction_id = '' }) => {
  window.gtag('event', action, {
    sent_to,
    value,
    currency: 'USD',
    transaction_id,
  })
}
