import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { useLocation, useParams, Redirect } from 'react-router-dom'
import { useAsync } from 'react-async'
import { BarLoader } from 'react-spinners'
import Button from '../../components/Button'
import Icon from '../../components/Icon'
import Page from '../../components/Page'
import GalleryLayout from '../../components/GalleryLayout'
import TextInput from '../../components/TextInput'
import Heading from '../../components/Heading'
import Text from '../../components/Text'
import { useUser } from '../../context/userContext'
import { updateUserNameCertificate, getPreviewCertificate } from '../../lib/api'
import { colors, mediaQueries, spacing } from '../../tokens'

const VerifyCertificate = () => {
  const { user, reloadUser } = useUser()
  const params: any = useParams()
  const { courseSlug } = params
  const location: any = useLocation()
  const [userNameCertificate, setUserNameCertificate] = useState<string>(
    user ? user.nombre : '',
  )
  const [imagePreviewOfCertificate, setImagePreviewOfCertificate] = useState<
    string | null
  >('')

  const {
    error: errorPreview,
    isLoading: isLoadingPreview,
    run: runPreview,
  } = useAsync({
    deferFn: args => getPreviewCertificate(args[0], args[1]),
    onResolve: (data: string) => {
      setImagePreviewOfCertificate(data)
    },
  })

  const { error, isLoading, run } = useAsync({
    deferFn: args => updateUserNameCertificate(args[0]),
    onResolve: () => {
      reloadUser()
    },
  })

  useEffect(() => {
    runPreview(courseSlug, userNameCertificate)
  }, [])

  function getURLBeforeVerify() {
    let URLTo = '/'
    if (location.state && location.state.from.pathname) {
      URLTo = location.state.from.pathname
    }
    return URLTo
  }

  if (user.nombreCertificado) {
    const URLTo = getURLBeforeVerify()
    return <Redirect push to={URLTo} />
  }

  function handlePreview() {
    runPreview(courseSlug, userNameCertificate)
  }

  function handleUserNameCertificate(value: string) {
    setUserNameCertificate(value)
  }

  function handleSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault()
    run(userNameCertificate)
  }

  return (
    <Page hasFooter={false}>
      <Wrapper>
        <GalleryLayout cols={2}>
          <Column>
            <Heading size={3}>
              Antes de dar el examen de certificación por favor verifica tu
              nombre, este se mostrará en el certificado.
            </Heading>

            <Form onSubmit={handleSubmit}>
              <TextInput
                label="El nombre que escribas aparecerá en todos tus certificados de alaU"
                name="userNameCertificate"
                onChange={handleUserNameCertificate}
                required={true}
                type="text"
                value={userNameCertificate}
                maxLength={64}
              />
              <WarningContainer>
                <Icon name="warning" status="danger" />
                <Text>Solo puedes cambiarlo una vez</Text>
              </WarningContainer>
              <WrapperButtons>
                <Button slim disabled={isLoading} type="submit">
                  Guardar Nombre
                </Button>
                <PreviewLink onClick={handlePreview}>Vista previa</PreviewLink>
              </WrapperButtons>
            </Form>
            {error && (
              <MessageContainer>
                <Text status="danger">
                  Lo sentimos, hubo un inconveniente. Por favor intenta
                  nuevamente
                </Text>
              </MessageContainer>
            )}
          </Column>
          <Column>
            {errorPreview && (
              <MessageContainer>
                <Text status="danger">
                  Lo sentimos, hubo un inconveniente al mostra la vista previa
                  de tu certificado. Por favor intenta nuevamente
                </Text>
              </MessageContainer>
            )}
            {isLoadingPreview && (
              <WrapperLoading>
                <BarLoader
                  color={colors.base.purple}
                  width={139}
                  widthUnit="px"
                />
              </WrapperLoading>
            )}
            {imagePreviewOfCertificate && !isLoadingPreview && (
              <ImageCertificate
                src={`data:image/png;base64, ${imagePreviewOfCertificate}`}
              />
            )}
          </Column>
        </GalleryLayout>
      </Wrapper>
    </Page>
  )
}

const Wrapper = styled.div`
  margin: 0 auto;
  @media ${mediaQueries.xlargeMin} {
    max-width: 992px;
  }
`

const Column = styled.div`
  padding-top: ${spacing.xxlarge};
`

const WrapperLoading = styled.div`
  display: flex;
  justify-content: center;
`

const ImageCertificate = styled.img`
  background-color: ${colors.base.white};
  border-radius: 3px;
  box-shadow: 0 0 0 1px rgba(63, 63, 68, 0.05),
    0 1px 3px 0 rgba(63, 63, 68, 0.15);
  display: block;
  height: auto;
  max-width: 480px;
  margin: 0 auto;
  width: 100%;
  @media ${mediaQueries.mediumMax} {
    max-width: 640px;
    margin: 0 auto;
  }
`
const Form = styled.form`
  display: flex;
  flex-direction: column;
  margin-top: ${spacing.medium};
  justify-content: center;
  width: 75%;
  @media ${mediaQueries.smallMax} {
    flex-direction: column;
  }
`

const PreviewLink = styled.div`
  color: ${colors.base.purple};
  cursor: pointer;
  margin: 0 ${spacing.xsmall};
`

const WarningContainer = styled.div`
  display: flex;
`

const WrapperButtons = styled.div`
  align-items: center;
  display: flex;
  margin: ${spacing.xsmall} 0px;
`

const MessageContainer = styled.div`
  margin-top: ${spacing.xsmall};
`

export default VerifyCertificate
