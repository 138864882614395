import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { fetchResultado } from '../actions/practice'
import ResultadoComponent from '../components/Resultado'

const mapStateToProps = state => ({
  slug: state.curso.slug,
  attemp: state.practice.attemp,
  isFetching: state.practice.isFetching,
  errorMessage: state.practice.errorMessage,
})

const mapDispatchToProps = dispatch => ({
  fetchResultado: practice => {
    dispatch(fetchResultado(practice))
  },
})

const Resultado = withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ResultadoComponent),
)

export default Resultado
