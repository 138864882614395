import React from 'react'
import styled from 'styled-components'
import { spacing } from '../../tokens'

interface Props {
  /** The content to put padding on */
  children: React.ReactNode
  /** The size of the padding */
  paddingSize?: 'small' | 'medium' | 'large' | 'none'
}

const Body = ({ children, paddingSize = 'small' }: Props) => (
  <Wrapper paddingSize={paddingSize}>{children}</Wrapper>
)

const Wrapper = styled.div<Props>`
  flex: 1;
  overflow: hidden;
  padding: ${props => {
    if (props.paddingSize === 'large') {
      return spacing.large
    }
    if (props.paddingSize === 'medium') {
      return spacing.medium
    }
    if (props.paddingSize === 'none') {
      return '0px'
    }
    return spacing.small
  }};
`

export default Body
