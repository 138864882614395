import { createQuizAttempt, fetchQuiz, resetQuiz } from '../actions/quiz'

import QuizComponent from '../components/Quiz'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'

const mapStateToProps = state => ({
  attempt: state.quiz.attempt,
  id: state.quiz.details.id || '',
  name: state.quiz.details.name || '',
  description: state.quiz.details.description || '',
  time: state.quiz.details.time || 0,
  showResults:
    state.quiz.details.showResults != null
      ? state.quiz.details.showResults
      : true,
  randomOptions:
    state.quiz.details.randomOptions != null
      ? state.quiz.details.randomOptions
      : true,
  userCanFillQuiz:
    state.quiz.details.userCanFillQuiz != null
      ? state.quiz.details.userCanFillQuiz
      : true,
  content: state.quiz.details.content,
  category: state.quiz.details.category,
  showFinalQuiz: state.quiz.details.showFinalQuiz,
  approved: state.quiz.details.approved,
  timeToRetake: state.quiz.details.timeToRetake,
  isValidTimeToRetake: state.quiz.details.isValidTimeToRetake,
  minutesRemainingToRetake: state.quiz.details.minutesRemainingToRetake,
  questions: state.quiz.questions,
  answers: state.quiz.answers,
  certificateKey: state.quiz.certificateKey,
  isFetching: state.quiz.isFetching,
  errorMessage: state.quiz.errorMessage,
  redirectTo: state.quiz.redirectTo,
  courseSlug: state.quiz.details.courseSlug,
})

const mapDispatchToProps = dispatch => ({
  fetchQuiz: quiz => {
    dispatch(fetchQuiz(quiz))
  },
  createQuizAttempt: (quiz, startAt, answers) => {
    dispatch(createQuizAttempt(quiz, startAt, answers))
  },
  addAnswer: (index, option) => {
    dispatch({
      type: 'ADD_ANSWER_SUCCESS',
      index,
      option,
    })
  },
  resetQuizAndRedirect: history => {
    dispatch(resetQuiz(history))
  },
  resetQuiz: () => {
    dispatch({
      type: 'RESET_QUIZ_SUCCESS',
    })
  },
})

const Quiz = withRouter(
  connect(mapStateToProps, mapDispatchToProps)(QuizComponent),
)

export default Quiz
