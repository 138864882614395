import React from 'react'
import styled from 'styled-components'
import { typeCols } from './GalleryLayout'
import { mediaQueries, spacing } from '../../tokens'

interface Props {
  /** The content to display inside the item. */
  children?: React.ReactNode
  /** The number of cols. Default value is 4 */
  cols: typeCols
}

const Item = ({ children, cols }: Props) => (
  <Wrapper cols={cols} data-testid="wrapper-div-item">
    {children}
  </Wrapper>
)

const Wrapper = styled.div<Props>`
  flex: 0 1 100%;
  position: relative;
  padding: ${spacing.xsmall};
  box-sizing: border-box;

  @media ${mediaQueries.smallMin} {
    flex: 0 1 ${({ cols }) => (cols > 1 ? 50 : (100 / cols).toFixed(3))}%;
  }
  @media ${mediaQueries.mediumMin} {
    flex: 0 1 ${({ cols }) => (cols > 2 ? 33.33 : (100 / cols).toFixed(3))}%;
  }
  @media ${mediaQueries.largeMin} {
    flex: 0 1 ${({ cols }) => (cols > 3 ? 25 : (100 / cols).toFixed(3))}%;
  }
  @media ${mediaQueries.xlargeMin} {
    flex: 0 1
      ${({ cols }) =>
        cols > 4
          ? cols > 5
            ? (100 / cols).toFixed(3)
            : 20
          : (100 / cols).toFixed(3)}%;
  }
`

export default Item
