import React from 'react'
import styled from 'styled-components'
import { useAsync } from 'react-async'
import { useParams } from 'react-router-dom'
import { useNotification } from '../../context/notificationContext'
import { getCertificate } from '../../lib/api'
import Button from '../../components/Button'
import Loading from '../Loading'
import Heading from '../../components/Heading'
import GalleryLayout from '../../components/GalleryLayout'
import Icon from '../../components/Icon'
import Page from '../../components/Page'
import Text from '../../components/Text'
import { colors, mediaQueries, spacing } from '../../tokens'
import { useUser } from '../../context/userContext'

const Certificate = () => {
  const { user } = useUser()
  const params: any = useParams()
  const { notify } = useNotification()
  const { key } = params
  const { data: userCertificate, error, isLoading } = useAsync({
    promiseFn: getCertificate,
    key,
    onReject: error => notify(error.message, 'error'),
  })

  if (isLoading) {
    return <Loading />
  }

  if (error) {
    return <Text status="danger">{error.message}</Text>
  }

  const handleClickDownload = () => {
    window.location.href = userCertificate.pdf
  }

  return (
    <Page hasFooter={false}>
      <Wrapper>
        <GalleryLayout cols={2}>
          <Column>
            <Heading>Tu certificado está listo</Heading>
            <Heading size={3}>
              De parte del equipo de alaU te felicitamos por tu esfuerzo. ¡Sigue
              aprendiendo todos los días!.
            </Heading>
            {user && user._id === userCertificate.user ? (
              <>
                <Separator />
                <Text>
                  Comparte tu certificado a y da a conocer tus habilidades al
                  mundo. ¡Celebra tus logros!
                </Text>
                <WrapperDownload>
                  <Button slim={true} onClick={handleClickDownload}>
                    <i>
                      <Icon name="download" status="white" />
                    </i>
                    Descargar Certificado
                  </Button>
                </WrapperDownload>
              </>
            ) : null}
          </Column>
          <Column>
            <ImageCertificate src={userCertificate.image} />
          </Column>
        </GalleryLayout>
      </Wrapper>
    </Page>
  )
}

const Wrapper = styled.div`
  margin: 0 auto;
  @media ${mediaQueries.xlargeMin} {
    max-width: 992px;
  }
`

const Column = styled.div`
  padding-top: ${spacing.xxlarge};
`

const Separator = styled.hr`
  background: ${colors.base.grayLightest};
  border: none;
  height: 1px;
  margin: ${spacing.small} 0px;
  width: 100%;
`
const WrapperDownload = styled.div`
  margin-top: ${spacing.small};
`

const ImageCertificate = styled.img`
  background-color: ${colors.base.white};
  border-radius: 3px;
  box-shadow: 0 0 0 1px rgba(63, 63, 68, 0.05),
    0 1px 3px 0 rgba(63, 63, 68, 0.15);
  display: block;
  height: auto;
  max-width: 480px;
  margin: 0 auto;
  width: 100%;
  @media ${mediaQueries.mediumMax} {
    max-width: 640px;
    margin: 0 auto;
  }
`

export default Certificate
