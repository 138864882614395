import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Avatar from '../../imagenes/avatar.png'
import Like from '../iconos/figuras/Like'
import Dislike from '../iconos/figuras/Dislike'
import Label from '../Label'
import { colors, fonts } from '../../tokens'

const Container = styled.div`
  :not(:last-child) {
    border-bottom: 1px solid ${colors.base.grayLight};
    padding-bottom: 20px;
    margin-bottom: 20px;
  }
`

const Description = styled.span`
  font-family: ${fonts.family.default};
  color: ${colors.base.gray};

  a {
    color: ${colors.base.purple};
  }

  img {
    max-width: 100% !important;
    max-height: 100% !important;
    width: auto !important;
    height: auto !important;
    display: block;
    margin: auto;
  }
`

const AvatarContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 20px;
  .avatar {
    width: 30px;
    height: 30px;
    margin-right: 5px;
    border-radius: 50%;
    border: 2px solid ${colors.base.grayLightest};
  }
`

const HeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
`

const UserContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 10px;
`

const ReactionContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  .icon {
    cursor: pointer;
  }
`

const IconContainer = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 12px;
  margin-right: 20px;
`

function Comment({
  _id,
  description,
  dislikesNumber,
  likesNumber,
  type,
  user,
  userLike,
  userDislike,
  verified,
  onClickReaction,
}) {
  const image = user.fbid
    ? `https://graph.facebook.com/v3.1/${user.fbid}/picture?type=square&width=50`
    : Avatar
  let solution
  if (type) {
    if (verified) {
      solution = <Label success>Solución verificada</Label>
    } else {
      solution = <Label warning>Solución</Label>
    }
  }
  return (
    <Container>
      <AvatarContainer>
        <img className="avatar" src={image} alt={user.nombre} />
        <HeaderContainer>
          <UserContainer>
            <span>{user.nombre} </span>
            <span>{verified} </span>
          </UserContainer>
          {solution}
        </HeaderContainer>
      </AvatarContainer>
      <Description dangerouslySetInnerHTML={{ __html: description }} />
      <ReactionContainer>
        <IconContainer>
          <div className="icon" onClick={() => onClickReaction(_id, 1)}>
            <Like active={userLike} />
          </div>
          <span>{likesNumber}</span>
        </IconContainer>
        <IconContainer>
          <div className="icon" onClick={() => onClickReaction(_id, -1)}>
            <Dislike active={userDislike} />
          </div>
          <span>{dislikesNumber}</span>
        </IconContainer>
      </ReactionContainer>
    </Container>
  )
}

Comment.propTypes = {
  _id: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  dislikesNumber: PropTypes.number.isRequired,
  likesNumber: PropTypes.number.isRequired,
  meta: PropTypes.object.isRequired,
  type: PropTypes.number.isRequired,
  user: PropTypes.object.isRequired,
  userLike: PropTypes.bool.isRequired,
  userDislike: PropTypes.bool.isRequired,
  verified: PropTypes.bool.isRequired,
  onClickReaction: PropTypes.func.isRequired,
}

export default Comment
