import React from 'react'

const BgTriangle = () => (
  <svg
    width="56px"
    height="57px"
    viewBox="0 0 56 57"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g
      id="Page-1"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
      opacity="0.124830163"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <g
        id="1.-login"
        transform="translate(-984.000000, -144.000000)"
        stroke="#A0AAAD"
        strokeWidth="14"
      >
        <polygon
          id="Stroke-4"
          points="1012 187.42229 991 194 995.811259 172.499366 1000.62379 151 1016.81126 165.922925 1033 180.84585"
        ></polygon>
      </g>
    </g>
  </svg>
)

export default BgTriangle
