const OffsetInCircunference = () => {
  const WholeCircunferenceLength = 175.92918860102841

  function calculateProportionOfInvertedPercentage(invertedPercentage: number) {
    return (WholeCircunferenceLength * invertedPercentage) / 100
  }

  return {
    calculateLengthOfBlankPart: (percentage: number) => {
      const invertedPercentage = 100 - percentage
      return calculateProportionOfInvertedPercentage(invertedPercentage)
    },
  }
}

export default OffsetInCircunference
