import React from 'react'
import styled from 'styled-components'
import Button from '../components/Button'
import Link from '../components/Link'
import Logo from '../components/Logo'
import { spacing } from '../tokens'

function NotEnrolled({
  message,
  redirectTo,
}: {
  message?: string
  redirectTo?: string
}) {
  return (
    <Container>
      <Logo size="large" />
      <p>{message}</p>
      <Button
        onClick={() =>
          window.open(redirectTo || 'https://cursos.alau.org/', '_blank')
        }
      >
        Pero te podemos ayudar aquí
      </Button>
      <Link to={'/'}>Regresar</Link>
    </Container>
  )
}

const Container = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100vh;
  justify-content: center;
  text-align: center;
  p,
  a {
    margin: ${spacing.small} 0px;
  }
`

export default NotEnrolled
