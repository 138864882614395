import React from 'react'
import styled, { css } from 'styled-components'
import { Link as LinkReact } from 'react-router-dom'
import { colors, fonts, spacing } from '../../tokens'

interface TProps {
  /** The disabled tag for the button. */
  disabled?: boolean
  /** Destination to call when the button is clicked */
  to?: string
  /** Renders a button that looks slim */
  slim?: boolean | string
  /** Text Status */
  status?:
    | 'default'
    | 'light'
    | 'white'
    | 'primary'
    | 'success'
    | 'warning'
    | 'danger'
  /** The (HTML) type for the button. */
  type?: 'button' | 'submit'
  /** If replace the current entry in the history stack  */
  replace?: boolean
  /** Handler to be called when the button is clicked */
  onClick?(): void
}

const Button: React.FC<TProps> = ({
  children,
  disabled,
  slim,
  status = 'primary',
  to = '',
  type = 'button',
  replace = false,
  onClick,
}) => {
  if (to !== '') {
    return (
      <WrapperLinkReact
        replace={replace}
        status={status}
        slim={`${slim ? '1' : ''}`}
        to={to}
      >
        {children}
      </WrapperLinkReact>
    )
  }
  return (
    <Wrapper
      status={status}
      slim={slim}
      type={type}
      disabled={disabled}
      onClick={onClick}
    >
      {children}
    </Wrapper>
  )
}

const styles = css<TProps>`
  ${props =>
    props.status &&
    css`
      background-color: ${colors[props.status]().color};
    `}
  border: none;
  border-radius: 100px;
  ${props =>
    props.status &&
    css`
      color: ${colors[props.status]().buttonColor};
    `}
  cursor: pointer;
  display: inline-block;
  font-family: ${fonts.family.default};
  font-size: ${fonts.size.default};
  font-weight: ${fonts.weight.medium};
  letter-spacing: 2.33px;
  opacity: 1;
  padding: ${spacing.xsmall} ${spacing.medium};
  text-transform: uppercase;
  transition: all 0.25s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  text-decoration: none;
  ${props =>
    props.slim &&
    css`
      text-transform: none;
      letter-spacing: normal;
      padding: ${spacing.xsmall} ${spacing.medium};
    `}
  &:focus {
    outline: 0;
    transform: translateY(-1px);
    opacity: 0.9;
  }
  &:hover {
    transform: translateY(-1px);
    opacity: 0.9;
  }
  &:disabled {
    background-color: ${colors.base.grayLightest};
    cursor: not-allowed;
  }
  i {
    vertical-align: middle;
    margin-right: ${spacing.xsmall};
  }
`
const Wrapper = styled.button<TProps>`
  ${styles};
`

const WrapperLinkReact = styled(LinkReact)<TProps>`
  ${styles};
`

export default Button
