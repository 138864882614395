import React from 'react'
import styled from 'styled-components'
import Button from './Button'
import { H1, H2 } from './textos/Heading'
import { colors, fonts } from '../tokens'

const Container = styled.div`
  color: ${colors.base.white};
  background: ${colors.base.purple};
  font-family: ${fonts.family.default};
  height: 100vh;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .desc {
    display: inline-block;
    text-align: left;
    line-height: 49px;
    height: 49px;
    vertical-align: middle;
  }

  h1 {
    display: inline-block;
    border-right: 1px solid rgba(255, 255, 255, 0.3);
    margin: 0;
    margin-right: 20px;
    padding: 10px 23px 10px 0;
    font-size: 24px;
    font-weight: 500;
    vertical-align: top;
  }

  h2 {
    font-size: 14px;
    font-weight: normal;
    line-height: inherit;
    margin: 0;
    padding: 0;
  }
`

const NotFound = () => (
  <Container>
    <div>
      <H1 color={colors.base.white}>404</H1>
      <div className="desc">
        <H2 color={colors.base.white}>
          No hemos podido encontrar esta página.
        </H2>
      </div>
    </div>
    <div>
      <Button status="white" to={'/'}>
        Pero te podemos ayudar aquí
      </Button>
    </div>
  </Container>
)

export default NotFound
