import React from 'react'
import styled from 'styled-components'

import BgBlurSquare from './figures/BgBlurSquare'
import BgPurple from './figures/BgPurple'
import BgTriangle from './figures/BgTriangle'
import BgYellow from './figures/BgYellow'
import BgZigzag from './figures/BgZigzag'

const BackgroundFigures = () => (
  <>
    <BgYellowWrapper data-testid="svgWrapper">
      <BgYellow />
    </BgYellowWrapper>
    <BgPurpleWrapper data-testid="svgWrapper">
      <BgPurple />
    </BgPurpleWrapper>
    <BgBlurSquareWrapper data-testid="svgWrapper">
      <BgBlurSquare />
    </BgBlurSquareWrapper>
    <BgTriangleWrapper data-testid="svgWrapper">
      <BgTriangle />
    </BgTriangleWrapper>
    <BgZigzagWrapper data-testid="svgWrapper">
      <BgZigzag />
    </BgZigzagWrapper>
  </>
)
const FigureWrapper = styled.div`
  position: absolute;
  z-index: -1;
`
const BgYellowWrapper = styled(FigureWrapper)`
  right: 0px;
`
const BgPurpleWrapper = styled(FigureWrapper)`
  left: 0px;
  bottom: 5%;
`
const BgBlurSquareWrapper = styled(FigureWrapper)`
  top: 70%;
  left: 65%;
`
const BgTriangleWrapper = styled(FigureWrapper)`
  top: 20%;
  left: 70%;
`
const BgZigzagWrapper = styled(FigureWrapper)`
  top: 50%;
  left: 28%;
`

export default BackgroundFigures
