import React from 'react'

const Like = () => (
  <svg
    width="30px"
    height="30px"
    viewBox="0 0 30 30"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xlink="http://www.w3.org/1999/xlink"
  >
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <circle id="Oval" stroke="#A0AAAD" cx="15" cy="15" r="14" />
      <g
        id="Group-2"
        transform="translate(9.000000, 5.000000)"
        stroke="#A0AAAD"
        strokeLinecap="square"
      >
        <path d="M6,0.29203125 L6,17.71875" id="Line" />
        <path d="M6,17.71875 L0.4,12.46875" id="Line-2" />
        <path d="M6,17.71875 L11.7,12.375" id="Line-3" />
      </g>
    </g>
  </svg>
)

export default Like
