import React from 'react'
import styled from 'styled-components'

import Heading from '../Heading'

interface Props {
  title: string
}
const HeaderTitle = ({ title }: Props) => (
  <TitleWrapper data-testid="title-in-header">
    <Heading size={2}>{title}</Heading>
  </TitleWrapper>
)

const TitleWrapper = styled.div`
  flex-grow: 4;
  text-align: left;
`

export default HeaderTitle
