/* eslint react/display-name: 0 */
import React from 'react'
import { Redirect } from 'react-router-dom'
import { useUser } from './context/userContext'

const CreatePrivateRoute = ({ component: Component }) => {
  const { user } = useUser()
  const PrivateRoute = ({ ...rest }) => {
    if (user) {
      return <Component {...rest} />
    }

    return (
      <Redirect
        to={{ pathname: '/registro', state: { from: rest.location } }}
      />
    )
  }

  const ConnectedComponent = PrivateRoute

  return {
    render: props => <ConnectedComponent {...props} />,
  }
}

export default CreatePrivateRoute
