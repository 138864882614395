import React, { Component } from 'react'
import styled from 'styled-components'
import BackgroundImage from '../../imagenes/bg-resultado.png'
import CirculoDegradado from '../iconos/figuras/CirculoDegradado'
import CirculoDegradadoSmall from '../iconos/figuras/CirculoDegradadoSmall'
import BrainSmall from '../iconos/brain/BrainSmall'
import { colors } from '../../tokens'

const ResultadoCalificacionContainer = styled.div`
  position: relative;
  margin: 15px auto;
  background: url(${BackgroundImage}) no-repeat center center;
  background-size: contain;
  max-width: 334px;
  height: 235px;
  display: flex;
  justify-content: center;
  align-items: center;
`
const CirculoContainer = styled.div`
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  #oval {
    stroke-dasharray: 1000;
    stroke-dashoffset: 0;
    animation: dash 1s ease-in 1;
  }
  @keyframes dash {
    from {
      stroke-dashoffset: 1000;
    }
    to {
      stroke-dashoffset: 0;
    }
  }
`

const CirculoBadget = styled.div`
  position: absolute;
  bottom: 5%;
  right: 0%;
`

const BrainContainer = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
`

const NotaFinal = styled.span`
  color: ${props => props.color};
  font-size: 60px;
  z-index: 1;
`

class ResultadoCalificacion extends Component {
  render() {
    const promedio =
      (this.props.respuestasCorrectas * 100) / this.props.respuestasTotal
    let circuloDegradado, circuloDegradadoSmall, textColor
    if (promedio >= 90) {
      circuloDegradado = (
        <CirculoDegradado borderColor1={'#55D7B5'} borderColor2={'#4EB99D'} />
      )
      circuloDegradadoSmall = (
        <CirculoDegradadoSmall
          borderColor1={'#55D7B5'}
          borderColor2={'#4EB99D'}
          background1={'#55D7B5'}
          background2={'#4EB99D'}
        />
      )
      textColor = colors.base.green
    } else if (promedio >= 60 && promedio < 90) {
      circuloDegradado = (
        <CirculoDegradado borderColor1={'#F8B400'} borderColor2={'#F4D215'} />
      )
      circuloDegradadoSmall = (
        <CirculoDegradadoSmall
          borderColor1={'#F8B400'}
          borderColor2={'#F4D215'}
          background1={'#F8B400'}
          background2={'#F4D215'}
        />
      )
      textColor = colors.base.yellow
    } else {
      circuloDegradado = (
        <CirculoDegradado borderColor1={'#A5AAB3'} borderColor2={'#CDD0D5'} />
      )
      circuloDegradadoSmall = (
        <CirculoDegradadoSmall
          borderColor1={'#A5AAB3'}
          borderColor2={'#CDD0D5'}
          background1={'#7C818A'}
          background2={'#AEB2B8'}
        />
      )
      textColor = colors.base.grayLight
    }

    return (
      <ResultadoCalificacionContainer>
        <CirculoContainer>
          {circuloDegradado}
          <CirculoBadget>
            {circuloDegradadoSmall}
            <BrainContainer>
              <BrainSmall />
            </BrainContainer>
          </CirculoBadget>
        </CirculoContainer>
        <NotaFinal color={textColor}>{this.props.nota}</NotaFinal>
      </ResultadoCalificacionContainer>
    )
  }
}

export default ResultadoCalificacion
