export const validateDate = date => {
  // First check for the pattern
  if (!/^\d{4}-\d{2}-\d{2}$/.test(date)) return false

  // Parse the date parts to integers
  const parts = date.split('-')
  const year = parseInt(parts[0], 10)
  const month = parseInt(parts[1], 10)
  const day = parseInt(parts[2], 10)

  // Check the ranges of month and year
  if (year < 1000 || year > 3000 || month === 0 || month > 12) return false

  const monthLength = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31]

  // Adjust for leap years
  if (year % 400 === 0 || (year % 100 !== 0 && year % 4 === 0))
    monthLength[1] = 29

  // Check the range of the day
  return day > 0 && day <= monthLength[month - 1]
}

export const secondsToTime = secs => {
  let hours = Math.floor(secs / (60 * 60))

  let divisor_for_minutes = secs % (60 * 60)
  let minutes = Math.floor(divisor_for_minutes / 60)

  let divisor_for_seconds = divisor_for_minutes % 60
  let seconds = Math.ceil(divisor_for_seconds)

  hours = String(hours).length === 1 ? '0' + hours : hours
  minutes = String(minutes).length === 1 ? '0' + minutes : minutes
  seconds = String(seconds).length === 1 ? '0' + seconds : seconds

  let obj = {
    h: hours,
    m: minutes,
    s: seconds,
  }
  return obj
}

export const formatDate = (date, showTime = false) => {
  let options = {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
  }
  if (showTime) {
    options.timeZone = 'America/Guayaquil'
    options.hour12 = false
    options.hour = '2-digit'
    options.minute = '2-digit'
    options.second = '2-digit'
  }

  return date.toLocaleDateString('es-ES', options)
}

export const createYears = (start = 1940, end = 2008) => {
  const items = []
  for (let i = start; i <= end; i++) {
    items.push({ value: String(i), label: String(i) })
  }
  return items
}

export const createMonths = () => {
  return [
    { value: '0', label: 'Mes' },
    { value: '1', label: 'Enero' },
    { value: '2', label: 'Febrero' },
    { value: '3', label: 'Marzo' },
    { value: '4', label: 'Abril' },
    { value: '5', label: 'Mayo' },
    { value: '6', label: 'Junio' },
    { value: '7', label: 'Julio' },
    { value: '8', label: 'Agosto' },
    { value: '9', label: 'Septiembre' },
    { value: '10', label: 'Octubre' },
    { value: '11', label: 'Noviembre' },
    { value: '12', label: 'Diciembre' },
  ]
}

export const createDays = (year, month) => {
  const items = [{ value: '0', label: 'Día' }]
  const start = 1
  const days = new Date(Number(year), Number(month), 0).getDate()
  for (let i = start; i <= days; i++) {
    items.push({ value: String(i), label: String(i) })
  }
  return items
}

export const createStringDate = (year, month, day) => {
  const d = ('0' + day).slice(-2)
  const m = ('0' + month).slice(-2)
  return year + '-' + m + '-' + d
}
