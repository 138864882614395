import axios from 'axios'
import { loadState } from '../util/localStorage'
import {
  FETCH_PRACTICE_FAILURE,
  FETCH_PRACTICE_REQUEST,
  FETCH_PRACTICE_SUCCESS,
} from './types'

const API_URL = process.env.REACT_APP_API_URL

export const fetchResultado = practice => async dispatch => {
  try {
    dispatch({
      type: FETCH_PRACTICE_REQUEST,
    })

    const token = loadState('__alau_token__')

    const response = await axios.get(`${API_URL}/simuladores/${practice}`, {
      headers: {
        Authorization: `bearer ${token}`,
      },
    })

    dispatch({
      type: FETCH_PRACTICE_SUCCESS,
      response: response.data,
    })
  } catch (e) {
    dispatch({
      type: FETCH_PRACTICE_FAILURE,
      message: e.response.data.message || 'Algo salió mal.',
    })
  }
}
