import styled from 'styled-components'
import { colors } from '../../tokens'

const Panel = styled.div`
  position: relative;
  background: ${colors.base.white};
  border-radius: 8px;
  box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.06);
  padding: ${props => props.padding || '20px'};
  border-color: ${props => props.borderColor || colors.base.grayLightest};
  border-width: ${props => props.borderWidth || '1px'};
  border-style: solid;
`

export default Panel
