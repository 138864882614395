import { combineReducers } from 'redux'

const course = (state = {}, action) => {
  switch (action.type) {
    case 'VALIDATE_COURSE_SUCCESS':
      return action.response.course
    default:
      return state
  }
}

const isValidKey = (state = false, action) => {
  switch (action.type) {
    case 'VALIDATE_COURSE_SUCCESS': {
      return action.response.isValidKey
    }
    default:
      return state
  }
}

const isBillingInformationRequestedToUser = (state = false, action) => {
  switch (action.type) {
    case 'VALIDATE_COURSE_SUCCESS': {
      return action.response.isBillingInformationRequestedToUser
    }
    default:
      return state
  }
}

const isEcuador = (state = false, action) => {
  switch (action.type) {
    case 'VALIDATE_COURSE_SUCCESS': {
      return action.response.isEcuador
    }
    default:
      return state
  }
}

const activated = (state = false, action) => {
  switch (action.type) {
    case 'ACTIVATE_COURSE_SUCCESS':
      return action.response.activated
    default:
      return state
  }
}

const isFetching = (state = false, action) => {
  switch (action.type) {
    case 'VALIDATE_COURSE_REQUEST':
    case 'ACTIVATE_COURSE_REQUEST':
      return true
    case 'VALIDATE_COURSE_SUCCESS':
    case 'VALIDATE_COURSE_FAILURE':
    case 'ACTIVATE_COURSE_SUCCESS':
    case 'ACTIVATE_COURSE_FAILURE':
      return false
    default:
      return state
  }
}

const errorMessage = (state = null, action) => {
  switch (action.type) {
    case 'VALIDATE_COURSE_FAILURE':
    case 'ACTIVATE_COURSE_FAILURE':
      return action.message
    case 'VALIDATE_COURSE_REQUEST':
    case 'VALIDATE_COURSE_SUCCESS':
    case 'ACTIVATE_COURSE_REQUEST':
    case 'ACTIVATE_COURSE_SUCCESS':
      return null
    default:
      return state
  }
}

const purchase = combineReducers({
  course,
  isValidKey,
  isBillingInformationRequestedToUser,
  isEcuador,
  activated,
  isFetching,
  errorMessage,
})

export default purchase
