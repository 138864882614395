import React from 'react'
import styled from 'styled-components'
import { colors, fonts, spacing } from '../../tokens'

interface Props {
  /** The element name to use for the heading */
  size?: 1 | 2 | 3
  /** The content to display inside the heading */
  children?: React.ReactNode
}

const Heading = ({ size = 1, children }: Props) => {
  const Component = Heading.Element[size]
  return <Component>{children}</Component>
}

const BaseHeading = styled.h1`
  font-family: ${fonts.family.default};
  font-weight: ${fonts.weight.normal};
  color: ${colors.base.default};
  margin: ${spacing.xxsmall} 0px;
  line-height: normal;
`
Heading.Element = new Array<any>()

Heading.Element[1] = styled(BaseHeading.withComponent('h1'))`
  font-size: ${fonts.size.xlarge};
`
Heading.Element[2] = styled(BaseHeading.withComponent('h2'))`
  font-size: ${fonts.size.large};
`
Heading.Element[3] = styled(BaseHeading.withComponent('h3'))`
  font-size: ${fonts.size.medium};
`

export default Heading
