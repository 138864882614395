import { combineReducers } from 'redux'
import auth from './auth'
import comment from './comment'
import curso from './curso'
import purchase from './purchase'
import practice from './practice'
import quiz from './quiz'

const rootReducer = combineReducers({
  auth,
  comment,
  curso,
  purchase,
  practice,
  quiz,
})

export default rootReducer
