import { combineReducers } from 'redux'

const attempt = (state = {}, action) => {
  switch (action.type) {
    case 'CREATE_QUIZ_SUCCESS':
      return { ...action.response.quizAttempt }
    case 'RESET_QUIZ_SUCCESS':
      return {}
    default:
      return state
  }
}

const details = (state = {}, action) => {
  switch (action.type) {
    case 'FETCH_QUIZ_SUCCESS':
      return { ...action.response.quiz }
    case 'RESET_QUIZ_SUCCESS':
      return {}
    default:
      return state
  }
}

const questions = (state = [], action) => {
  switch (action.type) {
    case 'FETCH_QUIZ_SUCCESS':
      return [...action.response.questions]
    case 'RESET_QUIZ_SUCCESS':
      return []
    default:
      return state
  }
}

const answers = (state = [], action) => {
  switch (action.type) {
    case 'FETCH_QUIZ_SUCCESS':
      return action.response.questions.map(question => ({
        question: question._id,
      }))
    case 'ADD_ANSWER_SUCCESS': {
      const newAnswers = [...state]
      newAnswers[action.index] = {
        ...newAnswers[action.index],
        option: action.option,
      }
      return newAnswers
    }
    case 'RESET_QUIZ_SUCCESS':
      return []
    default:
      return state
  }
}

const certificateKey = (state = null, action) => {
  switch (action.type) {
    case 'CREATE_QUIZ_SUCCESS':
    case 'FETCH_QUIZ_SUCCESS':
      return action.response.certificateKey
    case 'RESET_QUIZ_SUCCESS':
      return null
    default:
      return state
  }
}

const isFetching = (state = false, action) => {
  switch (action.type) {
    case 'FETCH_QUIZ_REQUEST':
    case 'CREATE_QUIZ_REQUEST':
      return true
    case 'FETCH_QUIZ_SUCCESS':
    case 'FETCH_QUIZ_FAILURE':
    case 'CREATE_QUIZ_SUCCESS':
    case 'CREATE_QUIZ_FAILURE':
      return false
    default:
      return state
  }
}

const errorMessage = (state = null, action) => {
  switch (action.type) {
    case 'FETCH_QUIZ_FAILURE':
    case 'CREATE_QUIZ_FAILURE':
      return action.message
    case 'FETCH_QUIZ_REQUEST':
    case 'FETCH_QUIZ_SUCCESS':
    case 'CREATE_QUIZ_REQUEST':
    case 'CREATE_QUIZ_SUCCESS':
      return null
    default:
      return state
  }
}

const redirectTo = (state = '', action) => {
  switch (action.type) {
    case 'FETCH_QUIZ_FAILURE':
      return action.redirectTo
    default:
      return state
  }
}

const quiz = combineReducers({
  attempt,
  details,
  questions,
  answers,
  certificateKey,
  isFetching,
  errorMessage,
  redirectTo,
})

export default quiz
