import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import {
  createComment,
  fetchComment,
  reactionComment,
} from '../actions/comment'
import CommentsComponent from '../components/Comments'

const mapStateToProps = (state, props) => ({
  froalaSignature: state.auth.froalaSignature,
  question: props.question,
  comments: state.comment.comments,
  isFetching: state.comment.isFetching,
  errorMessage: state.comment.errorMessage,
})

const mapDispatchToProps = dispatch => ({
  createComment: (question, description, type) => {
    dispatch(createComment(question, description, type))
  },
  fetchComment: question => {
    dispatch(fetchComment(question))
  },
  reactionComment: (comment, reaction) => {
    dispatch(reactionComment(comment, reaction))
  },
})

const Comments = withRouter(
  connect(mapStateToProps, mapDispatchToProps)(CommentsComponent),
)

export default Comments
