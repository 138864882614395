import React, { createContext, useContext, useReducer } from 'react'
import practiceReducer from '../reducers/practiceReducer'

const PracticeContext = createContext()

const initialState = {
  exercises: [],
  simulator: null,
  index: 0,
  total: 0,
  question: null,
  chosenAnswer: null,
  correctAnswer: null,
  isFetching: false,
}

function PracticeProvider({ children }) {
  const [practice, dispatchPractice] = useReducer(practiceReducer, initialState)
  return (
    <PracticeContext.Provider value={{ practice, dispatchPractice }}>
      {children}
    </PracticeContext.Provider>
  )
}

function usePracticeContext() {
  const context = useContext(PracticeContext)
  if (context === undefined) {
    throw new Error('usePracticeContext must be used within a PracticeProvider')
  }
  return context
}

export { PracticeProvider, usePracticeContext }
