import React from 'react'

const Like = ({ active = false }) => (
  <svg
    width="19px"
    height="18px"
    viewBox="0 0 19 18"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xlink="http://www.w3.org/1999/xlink"
  >
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g
        id="thin-0663_like_thumb_up_vote"
        transform="translate(1.000000, 0.000000)"
      >
        <g
          id="Group"
          transform="translate(0.000000, 0.750000)"
          stroke={active ? '#4E5974' : '#A0AAAD'}
        >
          <path d="M15.45495,9.40665 L14.06925,9.40665" id="Shape" />
          <path d="M14.6958,11.6739 L13.700475,11.6739" id="Shape" />
          <path
            d="M4.875,14.67225 C8.903775,16.047525 13.700475,15.96285 13.700475,15.96285 C15.5409,15.96285 15.1497,13.941075 13.8192,13.941075 L13.28835,13.941075"
            id="Shape"
          />
          <path
            d="M4.875,7.825275 C4.875,7.825275 8.67345,4.8393 8.400975,1.716075 C8.194275,-0.65325 12.592725,1.15875 10.144425,7.13955 L15.572175,7.13955 C17.0862,7.13955 17.0817,9.406725 15.572175,9.406725 L15.45495,9.406725 C16.98105,9.406725 16.940925,11.6739 15.45495,11.6739 L14.6958,11.6739 C16.209375,11.6739 16.205325,13.941075 14.6958,13.941075 L13.81935,13.941"
            id="Shape"
          />
          <rect
            id="Rectangle-path"
            x="0.375"
            y="7.125"
            width="4.5"
            height="9"
          />
        </g>
        <rect id="Rectangle-path" x="0" y="0" width="18" height="18" />
      </g>
    </g>
  </svg>
)

export default Like
