import React from 'react'
import styled from 'styled-components'
import Content from './Content'
import Header from '../Header'
import Footer from '../Footer'

interface Props {
  /** The content to display inside the header. */
  children?: React.ReactNode
  /* Content width 100% */
  useFullWidth?: boolean
  /* Show header */
  hasHeader?: boolean
  /* Show footer */
  hasFooter?: boolean
}

function Page({
  children,
  useFullWidth,
  hasFooter = true,
  hasHeader = true,
}: Props) {
  return (
    <Wrapper>
      {hasHeader && <Header />}
      <Content useFullWidth={useFullWidth}>{children}</Content>
      {hasFooter && <Footer />}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

export default Page
