import React from 'react'
import { useHistory, useParams } from 'react-router-dom'
import SuscriptionPlan from './components/SubscriptionPlan'
import styled from 'styled-components'
import Icon from '../../components/Icon'
import { colors, mediaQueries, spacing } from '../../tokens'

function Suscription() {
  const history = useHistory()
  const params: any = useParams()

  const onClose = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation()
    if (history.action === 'PUSH') {
      history.goBack()
    } else {
      history.push('/')
    }
  }

  return (
    <Wrapper>
      <SuscriptionPlan countryParam={params.country} />
      <CloseButton onClick={onClose}>
        <Icon name="close" size="small" />
      </CloseButton>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  background: ${colors.base.white};
  display: flex;
  position: relative;
  min-height: 100%;
  width: 100%;
  @media ${mediaQueries.mediumMax} {
    height: auto;
  }
`

const CloseButton = styled.div`
  background: ${colors.base.white};
  border-radius: 50%;
  cursor: pointer;
  position: fixed;
  right: ${spacing.xsmall};
  top: ${spacing.xsmall};
`

export default Suscription
