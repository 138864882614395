import React from 'react'
import styled from 'styled-components'
import Card from '../../../components/Card'
import Heading from '../../../components/Heading'
import Icon from '../../../components/Icon'
import Text from '../../../components/Text'
import { mediaQueries, spacing } from '../../../tokens'

interface Props {
  title: string
  description: string
  price: string
  onClick(): void
}

const MonthlyPlan = ({ title, description, price, onClick }: Props) => {
  return (
    <Wrapper onClick={onClick}>
      <Card>
        <Card.Body>
          <Body>
            <Description>
              <Detail>
                <Heading size={3}>{title}</Heading>
                <Text>{description}</Text>
              </Detail>
              <Price>
                <Heading size={2}>{price}</Heading>
                <Text>&nbsp;x mes</Text>
              </Price>
            </Description>
            <Arrow>
              <Icon name="right-arrow" size={'small'} status={'default'} />
            </Arrow>
          </Body>
        </Card.Body>
      </Card>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  cursor: pointer;
  display: block;
  margin-top: ${spacing.large};
  text-decoration: none;
`

const Body = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
`

const Description = styled.div`
  display: flex;
  flex: 1;
  justify-content: space-between;
  @media ${mediaQueries.xsmallMax} {
    flex-direction: column;
  }
`

const Detail = styled.div`
  flex: 1;
  padding-right: ${spacing.xxsmall};
`

const Price = styled.div`
  align-items: center;
  display: flex;
`

const Arrow = styled.div`
  margin-left: ${spacing.xsmall};
`

export default MonthlyPlan
