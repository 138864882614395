import React from 'react'
import styled, { css } from 'styled-components'

import { mediaQueries } from '../../tokens'

interface Props {
  /* Content */
  children?: React.ReactNode
  /* Content with 100% */
  useFullWidth?: boolean
}

const Content = ({ children, useFullWidth = false }: Props) => (
  <Wrapper useFullWidth={useFullWidth}>{children}</Wrapper>
)

const Wrapper = styled.div<Props>`
  padding: 0px;
  margin: 0 auto;
  flex: 1;
  width: 100%;
  ${props =>
    !props.useFullWidth &&
    css`
      @media ${mediaQueries.smallMin} {
        max-width: 576px;
      }

      @media ${mediaQueries.mediumMin} {
        max-width: 768px;
      }

      @media ${mediaQueries.largeMin} {
        max-width: 992px;
      }

      @media ${mediaQueries.xlargeMin} {
        max-width: 1200px;
      }
    `}
`

export default Content
