import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Alert from '../Alert'
import Panel from '../paneles/Panel'
import Create from './Create'
import Comment from './Comment'
import UserAvatar from './UserAvatar'

class Comments extends Component {
  static propTypes = {
    question: PropTypes.string.isRequired,
    comments: PropTypes.array.isRequired,
    isFetching: PropTypes.bool.isRequired,
    errorMessage: PropTypes.string,
    createComment: PropTypes.func.isRequired,
    reactionComment: PropTypes.func.isRequired,
  }

  constructor(props) {
    super(props)

    this.state = {
      description: '',
      type: null,
    }

    this.handleChange = this.handleChange.bind(this)
    this.handleEditorChange = this.handleEditorChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleClickReaction = this.handleClickReaction.bind(this)
  }

  handleEditorChange(description) {
    this.setState({
      description,
    })
  }

  handleChange(event) {
    const target = event.target
    const value = target.type === 'checkbox' ? target.checked : target.value
    const name = target.name
    this.setState({
      [name]: value,
    })
  }

  handleSubmit(event) {
    event.preventDefault()
    const question = this.props.question
    const description = this.state.description.trim()
    const type = this.state.type

    if (description !== '' && type !== null && question !== null) {
      this.props.createComment(question, description, type)
      this.setState({
        description: '',
        type: null,
      })
    }
  }

  handleClickReaction(comment, type) {
    this.props.reactionComment(comment, type)
  }

  componentDidMount() {
    this.props.fetchComment(this.props.question)
  }

  render() {
    const { comments, froalaSignature, isFetching, errorMessage } = this.props

    const listComments = !comments.length ? (
      <CenterContainer>
        Aún no se han registrado comentarios, sé el primero
      </CenterContainer>
    ) : (
      comments.map((comment, index) => {
        return (
          <Comment
            key={index}
            _id={comment._id}
            description={comment.description}
            dislikesNumber={comment.dislikesNumber}
            likesNumber={comment.likesNumber}
            meta={comment.meta}
            type={comment.type}
            user={comment.user}
            userLike={comment.userLike}
            userDislike={comment.userDislike}
            verified={comment.verified}
            onClickReaction={this.handleClickReaction}
          />
        )
      })
    )

    if (errorMessage && !isFetching) {
      return <Alert error={errorMessage} />
    }
    return (
      <Container className="px1 center-block">
        <Panel>
          <UserAvatar />
          <Create
            froalaSignature={froalaSignature}
            description={this.state.description}
            type={this.state.type}
            isFetching={isFetching}
            onChange={this.handleChange}
            onChangeEditor={this.handleEditorChange}
            onSubmit={this.handleSubmit}
          />
        </Panel>
        <Panel className="my2">{listComments}</Panel>
      </Container>
    )
  }
}

const Container = styled.div`
  margin-top: 30px;
  display: block;

  @media screen and (min-width: 820px) {
    width: 820px;
  }
`

const CenterContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`

export default Comments
