import React from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import Layout from '../../../components/Layout'
import { colors, spacing } from '../../../tokens'

function Header({ index = 0, slug = '', total = 1, content = '' }) {
  const percentage = Math.round((index * 100) / total)
  return (
    <Wrapper>
      <Layout>
        <Layout.Section centerContent={true} padding={false}>
          <BarContainer>
            <Link to={`/cursos/${slug}/${content}`}>Salir</Link>
            <TotalBar>
              <ProgressBar percentage={percentage} />
            </TotalBar>
          </BarContainer>
        </Layout.Section>
      </Layout>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: ${colors.base.white};
  position: fixed;
  height: 40px;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 999;
`

const BarContainer = styled.div`
  align-items: center;
  justify-content: space-between;
  display: flex;
  a {
    margin: 0 ${spacing.xsmall};
    text-decoration: none;
    color: ${colors.base.grayLight};
  }
`

const TotalBar = styled.div`
  height: 8px;
  background: ${colors.base.grayLightest};
  flex: 1;
  position: relative;
  overflow: hidden;
  padding: 0px !important;
  margin-right: 15px;
`

const ProgressBar = styled.div<{ percentage: number }>`
  position: absolute;
  background-image: linear-gradient(
    -224deg,
    ${colors.base.purpleLight} 0%,
    ${colors.base.purple} 100%
  );
  width: ${props => props.percentage}%;
  height: 100%;
  transition: width 2s;
`

export default Header
