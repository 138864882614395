import React from 'react'

const CirculoDegradadoSmall = ({
  borderColor1 = '#C074C7',
  borderColor2 = '#895EE4',
  background1 = '#9748B9',
  background2 = '#662482',
}) => {
  return (
    <svg
      width="68px"
      height="68px"
      viewBox="0 0 68 68"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xlink="http://www.w3.org/1999/xlink"
    >
      <defs>
        <linearGradient
          x1="50%"
          y1="100%"
          x2="50%"
          y2="2.07053829%"
          id="linearGradient-small-1"
        >
          <stop stopColor={background1} stopOpacity="0.988988904" offset="0%" />
          <stop stopColor={background2} offset="100%" />
        </linearGradient>
        <linearGradient
          x1="102.046326%"
          y1="-5.15976005%"
          x2="-1.79383201%"
          y2="100%"
          id="linearGradient-small-2"
        >
          <stop stopColor={borderColor1} offset="0%" />
          <stop stopColor={borderColor2} offset="100%" />
        </linearGradient>
      </defs>
      <g
        id="Page-1"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="circulo-degradado-small"
          transform="translate(-749.000000, -242.000000)"
          fill="url(#linearGradient-small-1)"
          stroke="url(#linearGradient-small-2)"
          strokeWidth="3"
        >
          <circle id="oval-small" cx="783" cy="276" r="32.5" />
        </g>
      </g>
    </svg>
  )
}

export default CirculoDegradadoSmall
