import { loadState } from '../util/localStorage'
import { CustomError } from '../util/error'

const API_URL = process.env.REACT_APP_API_URL

interface ConfigParams {
  body?: object
  [propName: string]: any
}

interface Config {
  method: string
  headers: Headers | string[][] | Record<string, string> | undefined
  body?: string
  [propName: string]: any
}

async function client(
  endpoint: string,
  { body, ...customConfigs }: ConfigParams = {},
) {
  const headers: Config['headers'] = {
    'content-type': 'application/json',
  }
  const token = loadState('__alau_token__')
  if (token) {
    headers.Authorization = `Bearer ${token}`
  }

  const config: Config = {
    method: body ? 'POST' : 'GET',
    ...customConfigs,
    headers: {
      ...headers,
      ...customConfigs.headers,
    },
  }

  if (body) {
    config.body = JSON.stringify(body)
  }

  let data, err
  try {
    const response = await fetch(`${API_URL}/${endpoint}`, config)
    data = await response.json()

    if (!response.ok) {
      const errorName = data.error ? data.error : 'Error'
      const errorMessage = data.message
        ? data.message
        : `Error inesperado (${endpoint})`
      const customPayload = data.custom
      err = new CustomError()
      err.name = errorName
      err.message = errorMessage
      err.custom = customPayload
    }
  } catch (error) {
    err = new Error('Error de la red, por favor reintente')
    err.name = 'network_error'
  }

  if (!err) {
    return data
  }
  throw err
}

export default client
