import React from 'react'
import styled, { css } from 'styled-components'

import ContentMenu from '../ContentMenu'
import FooterLeft from '../FooterLeft'
import { mediaQueries, spacing } from '../../../../tokens'
import Heading from '../../../../components/Heading'
import { useCourseContext } from '../../context/CourseProvider'

interface Props {
  onClickContent(contentId: string): void
}

interface PropsStyles {
  showFooter?: boolean
}

const ContentLeft = ({ onClickContent }: Props) => {
  const { courseWithContents } = useCourseContext()
  return (
    <>
      <ContentWithScroll showFooter={courseWithContents.hasFinalQuiz}>
        <CourseTitle>
          <Heading size={2}>{courseWithContents.title}</Heading>
        </CourseTitle>
        <ContentMenu
          contents={courseWithContents.contents}
          onClickContent={onClickContent}
        />
      </ContentWithScroll>
      {courseWithContents.hasFinalQuiz && <FooterLeft />}
    </>
  )
}

const ContentWithScroll = styled.div<PropsStyles>`
  overflow-y: auto;
  height: 100%;
  @media ${mediaQueries.largeMin} {
    height: calc(100vh - 80px);
    ${props =>
      props.showFooter &&
      css`
        height: calc(100vh - 170px);
      `}
    overflow-y: scroll;
  }
`

const CourseTitle = styled.div`
  padding: ${spacing.small} ${spacing.large};
`

export default ContentLeft
