import React from 'react'
import styled from 'styled-components'
import { colors, spacing } from '../../../tokens'

interface Props {
  letter: string
  image?: string
  value: string
  checked: boolean
  disabled: boolean
  isValid?: boolean
  onChange(event: React.ChangeEvent<HTMLInputElement>): void
}

interface StatusProps {
  status?: 'primary' | 'success' | 'danger' | null
}

const Option: React.FC<Props> = ({
  children,
  letter,
  image,
  value,
  checked,
  disabled,
  isValid,
  onChange,
}) => {
  function getStatus(valid: boolean | undefined, checked: boolean) {
    if (valid) {
      return 'success'
    }

    if (valid === false && checked) {
      return 'danger'
    }

    return checked ? 'primary' : null
  }

  const status = getStatus(isValid, checked)

  return (
    <Wrapper status={status}>
      <p>{letter}</p>
      <Card status={status}>
        {image && (
          <div>
            <img
              className="img-responsive"
              src={require(`../../../imagenes/${image}`)}
              alt={letter}
            />
          </div>
        )}
        <Description
          dangerouslySetInnerHTML={{
            __html: typeof children === 'string' ? children : '',
          }}
        />
      </Card>
      <input
        type="radio"
        name="answer"
        value={value}
        checked={checked}
        disabled={disabled}
        onChange={event => onChange(event)}
        required
      />
      <span className="checkmark" />
    </Wrapper>
  )
}

const Wrapper = styled.label<StatusProps>`
  position: relative;
  padding-left: 10px;
  padding-right: 10px;
  width: 100%;
  min-height: 335px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media screen and (max-width: 767px) {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
    padding-left: 0px;
    padding-right: 0px;
    flex-direction: row;
    min-height: 110px;
    height: auto;
    align-items: center;
  }

  /* Hide the browser's default checkbox */
  input {
    width: 0px;
    position: absolute;
    opacity: 0;
    cursor: pointer;
  }

  /* Create a custom checkbox */
  .checkmark {
    background-color: ${colors.base.white};
    border: 1px solid ${colors.base.grayLight};
    cursor: pointer;
    margin: 10px auto;
    height: 21px;
    width: 21px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;

    @media screen and (max-width: 767px) {
      display: none;
    }
  }

  /* When the checkbox is checked, add a white background */
  input:checked ~ .checkmark {
    background-color: ${colors.base.white};
  }

  /* Create the checkmark/indicator (hidden when not checked) */
  .checkmark:after {
    content: '';
    position: absolute;
    display: none;
  }

  /* Show the checkmark when checked */
  input:checked ~ .checkmark:after {
    display: block;
  }

  /* Style the checkmark/indicator */
  .checkmark:after {
    width: 11px;
    height: 11px;
    border-radius: 50%;
    background-color: ${props =>
      props.status ? colors[props.status]().color : colors.base.purple};
    }
  }
`

const Description = styled.div`
  color: ${colors.base.gray};

  b {
    font-weight: normal;
  }

  img.formula {
    height: 30px !important;
    margin-bottom: -5px;
    display: inline-block !important;
  }

  img.formula-lg {
    height: 60px !important;
    display: inline-block !important;
    margin-bottom: -5px;
  }

  img {
    max-width: 100% !important;
    max-height: 100% !important;
    width: auto !important;
    height: auto !important;
    display: block;
    margin: auto;
  }

  @media screen and (max-width: 767px) {
    img {
      height: 75px !important;
      width: auto !important;
      display: block;
      margin: auto;
    }
  }
`

const Card = styled.div<StatusProps>`
  position: relative;
  background: ${colors.base.white};
  border-radius: 8px;
  box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.06);
  padding: 20px;
  border-style: solid;
  border-color: ${props =>
    props.status ? colors[props.status]().color : 'transparent'};
  border-width: ${props => (props.status !== 'primary' ? '2px' : '1px')};
  cursor: pointer;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  min-height: 210px;
  margin-top: ${spacing.small};
  @media screen and (max-width: 767px) {
    height: auto;
    min-height: 100px;
    margin-left: 15px;
  }
`

export default Option
