import React from 'react'
import { useAsync } from 'react-async'
import Carousel, { getResponsiveConfig } from '../../../components/Carousel'
import Heading from '../../../components/Heading'
import Button from '../../../components/Button'
import ImageCourses1 from '../../../imagenes/image-courses-1.png'
import ImageCourses2 from '../../../imagenes/image-courses-2.png'

import { getBanners } from '../../../lib/api'
import Loading from '../../Loading'
import { useNotification } from '../../../context/notificationContext'
import { mediaQueries, spacing } from '../../../tokens'
import { isURL } from '../../../util/url'
import styled from 'styled-components'

const headerImageArray = [ImageCourses1, ImageCourses2]

const randomHeaderImage = () => {
  return headerImageArray[Math.floor(Math.random() * headerImageArray.length)]
}

function Banner() {
  const { notify } = useNotification()
  const { data = [], isLoading } = useAsync({
    promiseFn: getBanners,
    onReject: error => notify(error.message, 'error'),
  })

  if (isLoading) {
    return <Loading />
  }

  return (
    <Carousel
      responsive={getResponsiveConfig({
        largeDesktop: 1,
        desktop: 1,
        tablet: 1,
        mobile: 1,
      })}
      autoPlay
      autoPlaySpeed={5000}
      removeArrowOnDeviceType={['tablet', 'mobile']}
      showDots
      infinite
      renderDotsOutside
      renderButtonGroupOutside
    >
      {data.map((item: any) => (
        <HeaderSection key={item._id}>
          <Column>
            <Description>
              <ImageMobile
                alt={item.title}
                src={item.imageBig || randomHeaderImage()}
              />
              <Heading size={1}>{item.title}</Heading>
              <Subtitle>{item.description}</Subtitle>
              <WrapperButton>
                {isURL(item.url) ? (
                  <Button
                    status="warning"
                    onClick={() => window.open(item.url)}
                  >
                    {item.button || 'Ver más'}
                  </Button>
                ) : (
                  <Button status={'warning'} to={item.url || '/home'}>
                    {item.button || 'Ver más'}
                  </Button>
                )}
              </WrapperButton>
            </Description>
          </Column>
          <Column>
            <Image
              alt={item.title}
              src={item.imageBig || randomHeaderImage()}
            />
          </Column>
        </HeaderSection>
      ))}
    </Carousel>
  )
}

const HeaderSection = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  margin: ${spacing.medium} auto 0px;
  padding: 0px ${spacing.xsmall};
  height: 100%;
  width: 100%;
  @media ${mediaQueries.mediumMin} {
    flex-direction: row;
    width: 92%;
  }
`

const Column = styled.div`
  display: flex;
  flex: 50%;
  flex-direction: column;
  justify-content: center;
  padding: 0px;
  @media ${mediaQueries.mediumMin} {
    padding: 0px 20px;
  }
`

const Image = styled.img`
  display: none;
  @media ${mediaQueries.mediumMin} {
    max-width: 770px;
    width: 100%;
    height: auto;
    display: block;
  }
`

const ImageMobile = styled(Image)`
  display: block;
  margin: 0 auto;
  max-width: 100%;
  margin-bottom: 10px;
  @media ${mediaQueries.mediumMin} {
    display: none;
  }
`

const Description = styled.div`
  width: 100%;
  margin: 0 auto;
  text-align: center;
  @media ${mediaQueries.mediumMin} {
    text-align: left;
  }
`

const Subtitle = styled.div`
  margin-top: ${spacing.xsmall};
  line-height: 1.4rem;
  @media ${mediaQueries.mediumMin} {
    margin: ${spacing.small} 0px ${spacing.medium};
  }
`

const WrapperButton = styled.div`
  margin: ${spacing.small} 0px;
`

export default Banner
