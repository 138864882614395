import React from 'react'
import Heading from '../../../../components/Heading'
import Text from '../../../../components/Text'
import WhatsAppLink from '../../../../components/WhatsAppLink'
import PayPal from '../PayPal'

interface Props {
  paypalId: string
}

function TypesOfPayment({ paypalId }: Props) {
  return paypalId === '' ? (
    <Heading size={3}>
      Por favor escríbenos a{' '}
      <strong>
        <a href="mailto:l.gomez@alau.org">l.gomez@alau.org</a>
      </strong>{' '}
      y uno de nuestros asesores educativos se pondrá en contacto en seguida.
    </Heading>
  ) : (
    <>
      <PayPal id={paypalId} />
      <Text>Confirma tu pago al WhatsApp: +593968098077</Text>
      <WhatsAppLink
        title="Confirma tu pago aquí"
        messageBody="Quiero confirmar el pago de mi suscripción"
      />
    </>
  )
}

export default TypesOfPayment
