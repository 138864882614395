import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { CSSTransition } from 'react-transition-group'
import { BarLoader } from 'react-spinners'
import { usePracticeContext } from '../context/PracticeProvider'
import ArrowDown from '../../../components/iconos/figuras/ArrowDown'
import Option from './Option'
import Button from '../../../components/Button'
import Message from '../../../components/Message'
import { colors, fonts, spacing } from '../../../tokens'
import { shuffle } from '../../../util/array'

interface Props {
  onChangeAnswer(event: React.ChangeEvent<HTMLInputElement>): void
  onSubmitAnswer?(event: React.FormEvent<HTMLFormElement>): void
  onShowNextQuestion?(): void
}

interface QuestionProps {
  descripcion: string
  opciones: OptionsProps[]
  opcionesRandom: boolean
}

interface OptionsProps {
  _id: string
  numero: number
  descripcion: string
}

const Question: React.FC<Props> = ({
  onChangeAnswer,
  onSubmitAnswer,
  onShowNextQuestion,
}) => {
  const { practice } = usePracticeContext()
  const { chosenAnswer, correctAnswer, isFetching } = practice
  const question: QuestionProps = practice.question

  const [messageStatus, setMessageStatus] = useState<any>(null)
  const [options, setOptions] = useState<any[]>([])

  useEffect(() => {
    function getOptionsProcessed(question: QuestionProps) {
      if (!question) {
        return []
      }
      if (question.opcionesRandom) {
        return shuffle([...question.opciones])
      } else {
        return question.opciones
      }
    }

    const optionsProcessed = getOptionsProcessed(question)
    setOptions(optionsProcessed)
  }, [question])

  useEffect(() => {
    function getCorrectAnswerPosition() {
      let correctAnswerPosition = null

      if (!correctAnswer) {
        return correctAnswerPosition
      }

      options.forEach((option: OptionsProps, index: number) => {
        if (correctAnswer === option._id) {
          correctAnswerPosition = index
        }
      })

      return correctAnswerPosition
    }

    function getMessageStatus(correctAnswerPosition: number | null) {
      let title = ''
      let description = ''

      if (!chosenAnswer) {
        return { primary: true, title, description }
      }

      if (chosenAnswer === '0') {
        description = `La respuesta correcta es ${correctAnswerPosition !==
          null && String.fromCharCode(correctAnswerPosition + 65)}`
        return { neutral: true, title, description }
      }

      if (chosenAnswer === correctAnswer) {
        title = 'Tu respuesta es correcta'
        return { success: true, title, description }
      }
      if (chosenAnswer !== correctAnswer) {
        title = 'Tu respuesta es incorrecta'
        description = `La respuesta correcta es ${correctAnswerPosition !==
          null && String.fromCharCode(correctAnswerPosition + 65)}`
        return { danger: true, title, description }
      }

      return { primary: true, title, description }
    }

    const correctAnswerPosition = getCorrectAnswerPosition()
    const currentMessageStatus = getMessageStatus(correctAnswerPosition)
    setMessageStatus(currentMessageStatus)
  }, [correctAnswer])

  const ButtonAction = correctAnswer ? (
    <ButtonContainer id="comments">
      <div>
        <Button onClick={onShowNextQuestion}>Continuar</Button>
      </div>
      <a href="#comments" className="bounce">
        <ArrowDown />
      </a>
    </ButtonContainer>
  ) : (
    <Button type="submit">Calificar</Button>
  )

  return (
    <form onSubmit={onSubmitAnswer}>
      <Description
        dangerouslySetInnerHTML={{
          __html: question.descripcion,
        }}
      />

      <Options>
        {options.map((option: OptionsProps, key: number) => (
          <Option
            key={option._id}
            letter={String.fromCharCode(key + 65)}
            value={option._id}
            checked={chosenAnswer === option._id}
            disabled={correctAnswer ? true : false}
            isValid={correctAnswer && correctAnswer === option._id}
            onChange={onChangeAnswer}
          >
            {option.descripcion}
          </Option>
        ))}

        <Option
          letter={String.fromCharCode(options.length + 65)}
          image={'op-nose.png'}
          value={'0'}
          checked={chosenAnswer === '0'}
          disabled={correctAnswer ? true : false}
          onChange={onChangeAnswer}
        >
          No sé la respuesta
        </Option>
      </Options>

      <CenterContainer>
        <CSSTransition
          in={correctAnswer ? true : false}
          timeout={300}
          classNames="message"
          unmountOnExit
        >
          <div>
            {correctAnswer && (
              <Message key={Math.random()} {...messageStatus}>
                {messageStatus.description}
              </Message>
            )}
          </div>
        </CSSTransition>

        {!isFetching && ButtonAction}

        <BarLoader color={colors.primary().color} loading={isFetching} />
      </CenterContainer>
    </form>
  )
}

const CenterContainer = styled.div`
  margin: 20px 0px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .message-enter {
    opacity: 0.01;
  }
  .message-enter.message-enter-active {
    opacity: 1;
    transition: opacity 500ms ease-in;
  }
  .message-exit {
    opacity: 1;
  }
  .message-exit.message-exit-active {
    opacity: 0.01;
    transition: opacity 300ms ease-in;
  }
`

const Description = styled.p`
  font-family: ${fonts.family.default};
  color: ${colors.default().color};
  text-align: center;
  line-height: 1.3;
  font-weight: 400;
  padding-top: 50px;

  table {
    margin: 10px auto;
  }

  img {
    max-width: 100%;
    display: block;
    height: auto;
    margin: auto;
  }

  img.formula {
    height: 30px !important;
    margin-bottom: -5px;
    display: inline-block !important;
  }

  img.formula-lg {
    height: 60px !important;
    display: inline-block !important;
    margin-bottom: -5px;
  }

  ul,
  ol {
    display: inline-block;
    text-align: left;
  }

  li {
    margin: 5px 0px;
  }
`

const Options = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: ${spacing.medium};
  @media screen and (max-width: 767px) {
    flex-direction: column;
  }
`

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;

  .bounce {
    padding: 0px 10px;
    -moz-animation: bounce 2s 4;
    -webkit-animation: bounce 2s 4;
    animation: bounce 2s 4;
  }

  @keyframes bounce {
    0%,
    20%,
    50%,
    80%,
    100% {
      transform: translateY(0);
    }
    40% {
      transform: translateY(-30px);
    }
    60% {
      transform: translateY(-15px);
    }
  }
`

export default Question
