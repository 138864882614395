import axios from 'axios'
import { loadState } from '../util/localStorage'
import {
  FETCH_QUIZ_REQUEST,
  FETCH_QUIZ_SUCCESS,
  FETCH_QUIZ_FAILURE,
  CREATE_QUIZ_REQUEST,
  CREATE_QUIZ_SUCCESS,
  CREATE_QUIZ_FAILURE,
  RESET_QUIZ_SUCCESS,
} from './types'
const API_URL = process.env.REACT_APP_API_URL

// Quiz
export const fetchQuiz = quiz => async (dispatch, getState) => {
  try {
    dispatch({
      type: FETCH_QUIZ_REQUEST,
    })

    const token = loadState('__alau_token__')

    const response = await axios.get(`${API_URL}/quizzes/${quiz}`, {
      headers: {
        Authorization: `bearer ${token}`,
      },
    })

    dispatch({
      type: FETCH_QUIZ_SUCCESS,
      response: response.data,
    })
  } catch (e) {
    dispatch({
      type: FETCH_QUIZ_FAILURE,
      message: e.response.data.message || 'Algo salió mal.',
      redirectTo: e.response.data.redirectTo || '/',
    })
  }
}

export const createQuizAttempt = (quiz, startAt, answers) => async (
  dispatch,
  getState,
) => {
  try {
    dispatch({
      type: CREATE_QUIZ_REQUEST,
    })

    const token = loadState('__alau_token__')

    const response = await axios.post(
      `${API_URL}/quizzes/attempts`,
      { quiz, startAt, answers },
      {
        headers: {
          Authorization: `bearer ${token}`,
        },
      },
    )

    dispatch({
      type: CREATE_QUIZ_SUCCESS,
      response: response.data,
    })
  } catch (e) {
    dispatch({
      type: CREATE_QUIZ_FAILURE,
      message: e.response.data.message || 'Algo salió mal.',
    })
  }
}

export const resetQuiz = history => async (dispatch, getState) => {
  try {
    dispatch({
      type: RESET_QUIZ_SUCCESS,
    })

    if (getState().curso && getState().curso.actual) {
      history.push(`/cursos/${getState().curso.slug}`)
    } else {
      history.push('/')
    }
  } catch (e) {
    // Error
  }
}
