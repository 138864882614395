import React from 'react'
import styled, { css } from 'styled-components'
import { Link as LinkReact } from 'react-router-dom'
import { colors, fonts, spacing } from '../../tokens'

interface Props {
  /** The content to display inside the <a> tag */
  children?: React.ReactNode
  dataTestId?: string
  /** The path to external link */
  href?: string
  /** The path to internal link */
  to?: string
  /** Renders a button that looks slim */
  slim?: boolean | string
  /** If style is  */
  type?: 'button' | 'link'
  /** If relace the current entry in the history stack  */
  replace?: boolean
  /** Handler to be called when the link is clicked */
  onClick?(e: React.MouseEvent<HTMLAnchorElement>): void
}

const Link = ({
  dataTestId,
  children,
  href = '',
  to = '',
  slim = false,
  type = 'link',
  replace = false,
  onClick,
}: Props) => {
  return to !== '' ? (
    <WrapperLinkReact
      data-testid={dataTestId}
      to={to}
      type={type}
      slim={`${slim}`}
      replace={replace}
    >
      {children}
    </WrapperLinkReact>
  ) : (
    <WrapperLink
      data-testid={dataTestId}
      href={href}
      target="_blank"
      type={type}
      slim={`${slim}`}
      onClick={onClick}
    >
      {children}
    </WrapperLink>
  )
}

const styles = css`
  color: ${colors.base.purple};
  cursor: pointer;
  font-family: ${fonts.family.default};
  font-size: ${fonts.size.default};
  font-weight: ${fonts.weight.normal};
  opacity: 1;
  text-decoration: none;
  transition: all 0.25s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  &:hover,
  &:focus {
    opacity: 0.7;
  }
`

const stylesButton = css<Props>`
  background-color: ${colors.base.purple};
  border: none;
  border-radius: 100px;
  color: ${colors.base.white};
  cursor: pointer;
  display: inline-block;
  font-family: ${fonts.family.default};
  font-size: ${fonts.size.default};
  font-weight: ${fonts.weight.medium};
  letter-spacing: 2.33px;
  opacity: 1;
  padding: ${spacing.xsmall} ${spacing.medium};
  text-transform: uppercase;
  transition: all 0.25s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  text-decoration: none;
  ${props =>
    props.slim &&
    css`
      text-transform: none;
      letter-spacing: normal;
      padding: ${spacing.xsmall} ${spacing.medium};
    `}
  &:focus {
    outline: 0;
    transform: translateY(-1px);
    opacity: 0.9;
  }
  &:hover {
    transform: translateY(-1px);
    opacity: 0.9;
  }
  &:disabled {
    background-color: ${colors.base.grayLightest};
    cursor: not-allowed;
  }
`

const WrapperLinkReact = styled(LinkReact)<Props>`
  ${props => {
    if (props.type === 'button') {
      return stylesButton
    }
    return styles
  }};
`

const WrapperLink = styled.a<Props>`
  ${props => {
    if (props.type === 'button') {
      return stylesButton
    }
    return styles
  }};
`

export default Link
