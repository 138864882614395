import styled from 'styled-components'
import { colors, fonts } from '../../tokens'

export const H1 = styled.h1`
  font-family: ${fonts.family.default};
  line-height: 1.3;
  font-size: 36px;
  font-weight: 400;
  color: ${props => props.color || colors.base.gray};
  font-weight: ${props => props.weight || '400'};
`

export const H2 = styled(H1)`
  font-size: 30px;
`

export const H3 = styled(H1)`
  font-size: 24px;
`

export const H4 = styled(H1)`
  font-size: 20px;
`

export const H5 = styled(H1)`
  font-size: 16px;
`

export const H6 = styled(H1)`
  font-size: 12px;
`
