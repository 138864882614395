import React from 'react'
import styled from 'styled-components'
import Icon from '../Icon'
import Link from '../Link'
import Logo from '../Logo'
import Text from '../Text'
import { colors, mediaQueries, spacing } from '../../tokens'

function Footer() {
  return (
    <Wrapper data-testid="wrapper-div">
      <WrapperMenu>
        <Logo />
        <MenuFooter>
          <Link href="https://wa.link/qdat7p">+593968098077</Link>
          <Link href="https://docs.google.com/document/d/1oHzo6H_yqqGpqJIUiSBa3bzYuGe3-fsA1EiS90AFBwg/edit?usp=sharing">
            Preguntas Frecuentes
          </Link>
          <Link href="https://www.youtube.com/watch?v=Ia-zwby0dsw&feature=youtu.be">
            ¿Cómo funciona?
          </Link>
          <Link href="https://app.alau.org/terminos-y-condiciones">
            Términos y Condiciones
          </Link>
          <Link href="https://forms.gle/rHdC3hgEXjW7kMCR6">Enseña en alaU</Link>
        </MenuFooter>
      </WrapperMenu>
      <WrapperIcons>
        <WrapperIcon>
          <Link href="https://www.youtube.com/user/alauec">
            <Icon name="youtube" />
          </Link>
          <Text>Youtube</Text>
        </WrapperIcon>
        <WrapperIcon>
          <Link href="https://www.facebook.com/alau.org">
            <Icon name="facebook" />
          </Link>
          <Text>Facebook</Text>
        </WrapperIcon>
        <WrapperIcon>
          <Link href="https://www.instagram.com/alauoficial/">
            <Icon name="instagram" />
          </Link>
          <Text>Instagram</Text>
        </WrapperIcon>
        <WrapperIcon>
          <Link href="https://twitter.com/alauec">
            <Icon name="twitter" />
          </Link>
          <Text>Twitter</Text>
        </WrapperIcon>
      </WrapperIcons>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  background-color: ${colors.base.white};
  box-shadow: 0px 0px 3px 0px ${colors.base.grayLightest};
  display: flex;
  flex-direction: row;
  margin: 0 auto;
  min-height: 80px;
  padding: 15px;
  width: 100%;
  z-index: 999;

  @media ${mediaQueries.mediumMax} {
    flex-direction: column;
  }
`

const WrapperMenu = styled.div`
  align-items: center;
  display: flex;
  flex: 1;
  @media ${mediaQueries.mediumMax} {
    flex-direction: column;
  }
`

const MenuFooter = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-left: ${spacing.small};
  @media ${mediaQueries.mediumMax} {
    margin: ${spacing.xsmall} 0px;
    margin-left: 0px;
    justify-content: center;
  }
  & > a {
    margin: ${spacing.xsmall} 0px;
    padding: 0px ${spacing.xsmall};
    text-align: center;
  }
  & > a:not(:last-child) {
    border-right: 1px solid ${colors.light().background};
  }
`

const WrapperIcons = styled.div`
  align-items: center;
  display: flex;
  justify-content: flex-end;
  @media ${mediaQueries.mediumMax} {
    justify-content: center;
  }
`

const WrapperIcon = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0px 15px;
  > * {
    margin: 3px 0px;
  }
  @media ${mediaQueries.xsmallMax} {
    margin: 0px 5px;
  }
`

export default Footer
