import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Alert from './Alert'
import Button from './Button'
import LogoSimulador from './iconos/LogoSimulador'
import Panel from './paneles/Panel'
import { H1 } from './textos/Heading'
import { Link } from 'react-router-dom'
import { SyncLoader } from 'react-spinners'
import { colors } from '../tokens'

const Container = styled.div`
  margin-bottom: 50px;
  display: block;

  @media screen and (min-width: 568px) {
    width: 568px;
  }

  *:focus {
    outline: none;
  }

  .field {
    margin-bottom: 10px;
  }

  .control {
    &::placeholder {
      /* Chrome, Firefox, Opera, Safari 10.1+ */
      color: ${colors.base.grayLight};
      opacity: 1; /* Firefox */
    }
    &:-ms-input-placeholder {
      /* Internet Explorer 10-11 */
      color: ${colors.base.grayLight};
    }
    &::-ms-input-placeholder {
      /* Microsoft Edge */
      color: ${colors.base.grayLight};
    }
  }

  /* Estilos para el elemento input */
  input.input {
    display: block;
    width: 100%;
    font-size: inherit;
    padding: 14px 12px;
    background-color: ${colors.base.white};
    background-image: none;
    border: 1px solid ${colors.base.grayLightest};
    border-radius: 8px;
  }
`

const Title = styled(H1)`
  margin-bottom: 0px;
`

const Login = styled.div`
  font-size: 12px;
  a {
    text-decoration: none;
  }
`

class ResetPassword extends Component {
  static propTypes = {
    resetPassword: PropTypes.bool.isRequired,
    isFetching: PropTypes.bool.isRequired,
    errorMessage: PropTypes.string,
    resetFormPassword: PropTypes.func.isRequired,
    sendResetPassword: PropTypes.func.isRequired,
  }

  constructor(props) {
    super(props)

    this.state = {
      email: '',
    }

    this.handleChange = this.handleChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
  }

  handleChange(event) {
    const target = event.target
    const value = target.value
    const name = target.name
    this.setState({
      [name]: value,
    })
  }

  handleSubmit(event) {
    event.preventDefault()
    const { email } = this.state
    if (email.length > 0) {
      this.props.sendResetPassword(email)
    }
  }

  componentDidMount() {
    this.props.resetFormPassword()
  }

  render() {
    const { resetPassword, isFetching, errorMessage } = this.props

    return (
      <Container className="px1 center-block">
        <Alert error={errorMessage} />

        <div className="my2">
          <LogoSimulador />
        </div>

        <Panel borderColor={'transparent'} className="text-center">
          {resetPassword && (
            <div>
              <Title>Recuperar contraseña</Title>
              <p>
                Te hemos enviado un correo electrónico a {this.state.email} con
                las instrucciones para cambiar tu contraseña, recibirás un
                correo electrónico en los siguientes minutos.
              </p>
              <p>
                Si no recibes ningún correo electrónico, por favor verifica que
                el correo electrónico sea el que corresponde a tu cuenta, revisa
                también tu carpeta de spam.
              </p>
            </div>
          )}
          {!resetPassword && (
            <div>
              <Title>¿Olvidaste tu contraseña?</Title>
              <p>
                Para restaurar tu contraseña, ingresa tu dirección de correo
                electrónico. Es posible que tengas que consultar tu carpeta de
                spam o desbloquear la dirección info@alau.org.
              </p>
              <div className="mx1">
                <form className="form" onSubmit={this.handleSubmit}>
                  <div className="field">
                    <div className="control">
                      <input
                        className="input"
                        placeholder="Email"
                        type="email"
                        name="email"
                        value={this.state.email}
                        onChange={this.handleChange}
                        required
                      />
                    </div>
                  </div>

                  <div className="field my2">
                    <div className="control">
                      {!isFetching && (
                        <Button type="submit">Recuperar contraseña</Button>
                      )}
                    </div>
                  </div>
                </form>
              </div>
            </div>
          )}
          <Login>
            <Link to={'/'}>Ir al inicio</Link>
          </Login>
          <div className="my1">
            <SyncLoader color={colors.base.purple} loading={isFetching} />
          </div>
        </Panel>
      </Container>
    )
  }
}

export default ResetPassword
