import React from 'react'
import styled, { css } from 'styled-components'

import ProgressOfContent from '../../../../components/ProgressOfContent'
import Text from '../../../../components/Text'
import { colors, mediaQueries, spacing } from '../../../../tokens'

interface StyledProps {
  isCurrent: boolean
}

export interface Props extends StyledProps {
  _id?: string
  title: string
  percentage: number
  progress: string
  color: string
  onClick?(): void
}

const ContentWithProgress = ({
  _id,
  title,
  percentage,
  progress,
  color,
  onClick,
  isCurrent = false,
}: Props) => {
  return (
    <Wrapper data-testid={_id} onClick={onClick} isCurrent={isCurrent}>
      <Content>
        <ProgressOfContent
          color={color}
          percentage={percentage}
          progress={progress}
        />
        <Description>
          <Text>{title}</Text>
        </Description>
      </Content>
    </Wrapper>
  )
}

const Wrapper = styled.div<StyledProps>`
  cursor: pointer;
  padding: 0px ${spacing.large};
  &:hover {
    background: ${colors.base.grayLightest};
    opacity: 0.9;
  }
  ${props =>
    props.isCurrent &&
    css`
      background: ${colors.base.grayLightest};
    `}
`

const Content = styled.div`
  align-items: center;
  border-bottom: 1px solid ${colors.base.grayLightest};
  display: flex;
  padding: ${spacing.xsmall} 0px;
  @media ${mediaQueries.mediumMin} {
    padding: ${spacing.small} 0px;
  }
`

const Description = styled.div`
  flex-direction: column;
  display: flex;
  justify-content: center;
  margin-left: ${spacing.xsmall};
`

export default ContentWithProgress
