import React from 'react'

export default function Annual50() {
  return (
    <form
      method="post"
      action="https://gateway.payulatam.com/ppp-web-gateway/pb.zul"
      acceptCharset="UTF-8"
    >
      <input
        type="image"
        border="0"
        alt=""
        src="http://www.payulatam.com/img-secure-2015/boton_pagar_mediano.png"
        onClick="this.form.urlOrigen.value = window.location.href;"
      />
      <input
        name="buttonId"
        type="hidden"
        value="mVC4DAkO+xuvCXxB46bCRBqAtb5ODvWzJssWkLx5gtbLbtBJ0YwunA=="
      />
      <input name="merchantId" type="hidden" value="648157" />
      <input name="accountId" type="hidden" value="650624" />
      <input name="description" type="hidden" value="Suscripción anual alaU" />
      <input name="referenceCode" type="hidden" value="Descuento del 50" />
      <input name="amount" type="hidden" value="90000.00" />
      <input name="tax" type="hidden" value="0.00" />
      <input name="taxReturnBase" type="hidden" value="0.00" />
      <input name="currency" type="hidden" value="COP" />
      <input name="lng" type="hidden" value="es" />
      <input
        name="approvedResponseUrl"
        type="hidden"
        value="https://app.alau.org/payment_success"
      />
      <input
        name="declinedResponseUrl"
        type="hidden"
        value="https://app.alau.org/payment_failed"
      />
      <input
        name="pendingResponseUrl"
        type="hidden"
        value="https://app.alau.org/payment_failed"
      />
      <input name="sourceUrl" id="urlOrigen" value="" type="hidden" />
      <input name="buttonType" value="SIMPLE" type="hidden" />
      <input
        name="signature"
        value="9e55ff8d7ec69554c5d178e20a640eeda766427ffe63a4433e2d5d779b702e4f"
        type="hidden"
      />
    </form>
  )
}
