import React from 'react'
import styled from 'styled-components'

import Icon from '../../../../components/Icon'
import Heading from '../../../../components/Heading'
import Text from '../../../../components/Text'
import { colors, mediaQueries, spacing } from '../../../../tokens'
import { formatDate } from '../../../../util/date'

interface StyledProps {
  completed: boolean
}

interface Props {
  questions: number
  startAt: string
  correct: number
  onClick(): void
}

function QuizLesson({ questions, startAt, correct, onClick }: Props) {
  const completed = startAt ? true : false
  return (
    <Wrapper onClick={onClick}>
      <IconContainer>
        <Icon name="practice" size="large" status="white" />
      </IconContainer>
      <Info>
        <Heading size={3}>
          Practica con nuestros simuladores y mejora tu nivel
        </Heading>
        <Features>
          <Icon name="questions" />
          <FeatureDescription>
            <Text status="light">{`${questions} preguntas`}</Text>
          </FeatureDescription>
        </Features>
        {completed && (
          <Features>
            <Icon name="results" />
            <FeatureDescription>
              <Text status="light">{`Nota: ${correct}/${questions} | `}</Text>
              <Text status="light">{` Última práctica: ${formatDate(
                new Date(startAt),
              )} `}</Text>
            </FeatureDescription>
          </Features>
        )}
      </Info>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  align-items: flex-start;
  cursor: pointer;
  display: flex;
  flex-wrap: wrap;
`

const IconContainer = styled.div`
  align-items: center;
  align-self: center;
  background-color: ${colors.info().color};
  display: flex;
  height: 160px;
  justify-content: center;
  width: 100%;
  @media ${mediaQueries.smallMin} {
    width: 250px;
    height: 130px;
  }
`

const Info = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: flex-start;
  padding: ${spacing.small};
  @media ${mediaQueries.smallMin} {
    padding: ${spacing.xsmall} ${spacing.small};
  }
`

const Features = styled.div`
  align-items: flex-start;
  display: flex;
  margin-top: ${spacing.xxsmall};
`

const FeatureDescription = styled.div`
  margin-left: ${spacing.xxsmall};
`

export default QuizLesson
