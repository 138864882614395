import React, { Component } from 'react'

class IconoError extends Component {
  render() {
    return (
      <svg
        width="41px"
        height="41px"
        viewBox="0 0 41 41"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xlink="http://www.w3.org/1999/xlink"
      >
        <g
          id="Page-1"
          stroke="none"
          strokeWidth="1"
          fill="none"
          fillRule="evenodd"
        >
          <g
            id="1.-login-copy-6"
            transform="translate(-433.000000, -536.000000)"
          >
            <g
              id="thin-0153_delete_exit_remove_close"
              transform="translate(433.000000, 536.000000)"
            >
              <g id="Group" stroke="#FE6068" strokeWidth="2">
                <path
                  d="M28.4082167,12.610575 L12.620825,28.3979667"
                  id="Shape"
                />
                <path
                  d="M12.620825,12.610575 L28.4082167,28.3979667"
                  id="Shape"
                />
                <circle
                  id="Oval"
                  cx="20.5114458"
                  cy="20.5042708"
                  r="19.4445917"
                />
              </g>
              <rect
                id="Rectangle-path"
                x="0"
                y="0.00170833333"
                width="41"
                height="41"
              />
            </g>
          </g>
        </g>
      </svg>
    )
  }
}

export default IconoError
