import { createStore, applyMiddleware, compose } from 'redux'
import thunk from 'redux-thunk'
import logger from 'redux-logger'
import rootReducer from './reducers/index'
import { loadState } from './util/localStorage'

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

//const store = createStore(reducer, initialState, composeEnhancers());

const auth = loadState('auth')
const curso = loadState('curso')

const middleware = [thunk]
if (process.env.NODE_ENV !== 'production') {
  middleware.push(logger)
}

const store = createStore(
  rootReducer,
  {
    auth,
    curso,
  },
  composeEnhancers(applyMiddleware(...middleware)),
)

export default store
