import React from 'react'

const CirculoDegradado = ({
  borderColor1 = '#C074C7',
  borderColor2 = '#895EE4',
  background1 = '#EEF1F6',
  background2 = '#FCFDFD',
}) => {
  return (
    <svg
      width="232px"
      height="232px"
      viewBox="0 0 232 232"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xlink="http://www.w3.org/1999/xlink"
    >
      <defs>
        <linearGradient
          x1="92.3299372%"
          y1="100%"
          x2="19.1178165%"
          y2="-1.52432923%"
          id="linearGradient-1"
        >
          <stop stopColor={background1} offset="0%" />
          <stop stopColor={background2} offset="100%" />
        </linearGradient>
        <linearGradient
          x1="23.3660017%"
          y1="100%"
          x2="75.3051758%"
          y2="2.41001674%"
          id="linearGradient-2"
        >
          <stop stopColor={borderColor1} offset="0%" />
          <stop stopColor={borderColor2} offset="100%" />
        </linearGradient>
      </defs>
      <g
        id="Page-1"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="circulo-degradado"
          transform="translate(-604.000000, -75.000000)"
          fill="url(#linearGradient-1)"
          stroke="url(#linearGradient-2)"
          strokeWidth="7"
        >
          <circle
            id="oval"
            cx="720"
            cy="191"
            r="112"
            transform="rotate(48 720 191)"
          />
        </g>
      </g>
    </svg>
  )
}

export default CirculoDegradado
