import React from 'react'

const BgPurple = () => (
  <svg
    width="159px"
    height="519px"
    viewBox="0 0 159 519"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <defs />
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="home" transform="translate(0.000000, -1875.000000)" fill="#662482">
        <path
          d="M263,1997 C137.734217,2239 49.9264916,2360 -0.423176686,2360 C-50.772845,2360 -138.631786,2323.08005 -264,2249.24015 L-264,2042.95633 L192.233281,2042.95633 L263,1997 Z"
          id="Path-2-Copy"
          transform="translate(-0.500000, 2178.500000) rotate(-57.000000) translate(0.500000, -2178.500000) "
        />
      </g>
    </g>
  </svg>
)

export default BgPurple
