import React, { Component } from 'react'

class BgRight extends Component {
  render() {
    return (
      <svg
        width="92px"
        height="328px"
        viewBox="0 0 92 328"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
      >
        <defs />
        <g
          id="Page-1"
          stroke="none"
          strokeWidth="1"
          fill="none"
          fillRule="evenodd"
        >
          <g
            id="home"
            transform="translate(-1348.000000, -1677.000000)"
            fill="#FFDF0A"
          >
            <path
              d="M1738,1704.16083 C1659.59484,1879.38694 1595.19582,1967 1544.80296,1967 C1494.41009,1967 1406.47577,1930.08514 1281,1856.25541 L1281,1650 L1737.6247,1650 L1738,1704.16083 Z"
              id="Path-2"
              transform="translate(1509.500000, 1808.500000) scale(-1, -1) rotate(-57.000000) translate(-1509.500000, -1808.500000) "
            />
          </g>
        </g>
      </svg>
    )
  }
}

export default BgRight
