import React, { useEffect, useState } from 'react'
import styled, { css } from 'styled-components'
import { animated, config, useTransition } from 'react-spring'

import { mediaQueries } from '../../tokens'

interface StyledProps {
  imagebig: string
  imagesmall: string
}

interface ItemProps {
  _id: string
  imageBig: string
  imageSmall: string
  url?: string
}

interface Props {
  /** List of images to render. */
  items: ItemProps[]
}

const Banner = ({ items }: Props) => {
  const [index, setIndex] = useState(0)
  const transitions = useTransition<ItemProps, { opacity: number }>(
    items[index],
    item => item._id,
    // @ts-ignore: not typed properly in react-spring
    {
      from: { opacity: 0 },
      enter: { opacity: 1 },
      leave: { opacity: 0 },
      config: config.molasses,
    },
  )
  useEffect(() => {
    const interval = setInterval(() => {
      setIndex(state => (state + 1) % items.length)
    }, 5000)
    return () => clearInterval(interval)
  }, [items])
  return (
    <Wrapper data-testid="wrapper-div">
      {transitions.map(
        ({
          item,
          key,
          props,
        }: {
          item: ItemProps
          key: number
          props: object
        }) => (
          <ItemBanner
            data-testid="wrapper-background"
            key={key}
            href={item.url ? item.url : '#'}
            target={item.url ? '_blank' : ''}
            style={{ ...props }}
            imagebig={item.imageBig}
            imagesmall={item.imageSmall}
          ></ItemBanner>
        ),
      )}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  border-radius: 4px;
  height: 100px;
  margin: 0 auto;
  max-width: 850px;
  overflow: hidden;
  position: relative;
  width: 100%;
  @media ${mediaQueries.mediumMin} {
    height: 150px;
  }
`

const ItemBanner = styled(animated.a)<StyledProps>`
  ${props =>
    props.imagesmall &&
    css`
      background-image: url(${props.imagesmall});
    `}
  background-size: contain;
  background-position: center;
  cursor: pointer;
  height: 100px;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  will-change: opacity;
  @media ${mediaQueries.mediumMin} {
    background-size: cover;
    height: 150px;
    ${props =>
      props.imagebig &&
      css`
        background-image: url(${props.imagebig});
      `}
  }
`

export default Banner
