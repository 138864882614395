import React from 'react'
import styled from 'styled-components'

import { colors } from '../../../../tokens'
import Button from '../../../../components/Button'
import { useCourseContext } from '../../context/CourseProvider'

const FooterLeft = () => {
  const { courseWithContents } = useCourseContext()
  return (
    <Wrapper>
      <Button status={'warning'} to={`/quiz/${courseWithContents.finalQuiz}`}>
        OBTÉN TU CERTIFICADO
      </Button>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  align-items: center;
  justify-content: center;
  background-color: ${colors.base.white};
  border-top: 1px solid ${colors.base.grayLightest};
  display: flex;
  height: 90px;
`

export default FooterLeft
