import React from 'react'
import styled from 'styled-components'
import { default as MultiCarousel, CarouselProps } from 'react-multi-carousel'
import 'react-multi-carousel/lib/styles.css'
import { colors, spacing } from '../../tokens'

interface Items {
  [propName: string]: number
}

const defaultItems = {
  largeDesktop: 4,
  desktop: 3,
  tablet: 2,
  mobile: 1,
}

export const getResponsiveConfig = (items: Items = defaultItems) => {
  return {
    largeDesktop: {
      breakpoint: { max: 3000, min: 992 },
      items: items['largeDesktop'] || defaultItems['largeDesktop'],
    },
    desktop: {
      breakpoint: { max: 991, min: 768 },
      items: items['desktop'] || defaultItems['desktop'],
    },
    tablet: {
      breakpoint: { max: 767, min: 577 },
      items: items['tablet'] || defaultItems['tablet'],
    },
    mobile: {
      breakpoint: { max: 576, min: 0 },
      items: items['mobile'] || defaultItems['mobile'],
    },
  }
}

const Carousel = ({
  children,
  responsive = getResponsiveConfig(defaultItems),
  ...props
}: CarouselProps) => {
  return (
    <CarouselContainer>
      <MultiCarousel
        responsive={responsive || getResponsiveConfig(defaultItems)}
        customLeftArrow={<ButtonLeft />}
        customRightArrow={<ButtonRight />}
        containerClass="carousel-container"
        {...props}
      >
        {children}
      </MultiCarousel>
    </CarouselContainer>
  )
}

const CarouselContainer = styled.div`
  position: relative;
  padding: 0 ${spacing.small} 30px;
  .carousel-container {
    position: initial;
    padding: ${spacing.xsmall} 0px;
  }
  .react-multi-carousel-dot button {
    border-color: ${colors.primary().color};
    border-width: 1px;
  }
  .react-multi-carousel-dot--active button {
    background: ${colors.primary().color};
  }
`

const BaseButton = styled.button`
  position: absolute;
  outline: 0;
  transition: all 0.5s;
  border-radius: 35px;
  z-index: 1000;
  border: 0;
  background: ${colors.base.white};
  min-width: 43px;
  min-height: 43px;
  opacity: 1;
  cursor: pointer;
  box-shadow: 0 2px 4px rgb(0 0 0 / 8%), 0 4px 12px rgb(0 0 0 / 8%);
  &::before {
    font-size: 24px;
    color: ${colors.base.purple};
    display: block;
    font-family: revicons;
    text-align: center;
    z-index: 2;
    position: relative;
  }
`

const ButtonLeft = styled(BaseButton as any)`
  left: 3px;
  &::before {
    content: '\\e824';
  }
`

const ButtonRight = styled(BaseButton as any)`
  right: 3px;
  &::before {
    content: '\\e825';
  }
`

export default Carousel
