import React from 'react'
import styled, { css } from 'styled-components'

import HeaderTitle from './HeaderTitle'
import HeaderHours from './HeaderHours'
import { spacing } from '../../tokens'

interface Props {
  /** The path to the background image of header */
  backgroundImage?: string
  /** The title to render */
  title?: string
  /** The number of video content in the topic */
  hours?: number
}

const Header = ({ backgroundImage, title, hours }: Props) => {
  return (
    <>
      <Wrapper
        backgroundImage={backgroundImage}
        data-testid="wrapper-div-header"
      >
        {title && <HeaderTitle title={title} />}
        {(hours || hours === 0) && <HeaderHours hours={hours} />}
      </Wrapper>
      {!backgroundImage && <Separator />}
    </>
  )
}

const Wrapper = styled.div<Props>`
  display: flex;
  flex-direction: row;
  align-items: center;
  ${props =>
    props.backgroundImage &&
    css`
      height: 135px;
      background-image: url(${props.backgroundImage});
      background-repeat: no-repeat;
      background-position: center center;
      background-size: cover;
    `}
  ${props =>
    !props.backgroundImage &&
    css`
      padding-top: ${spacing.medium};
      padding-left: ${spacing.large};
      padding-right: ${spacing.large};
    `}
`

const Separator = styled.div`
  border-bottom: 1px solid #f9fafe;
  margin: ${spacing.medium} ${spacing.large} 0px ${spacing.large};
`

export default Header
