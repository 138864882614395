import React from 'react'

export default function Annual5() {
  return (
    <form
      method="post"
      action="https://gateway.payulatam.com/ppp-web-gateway/pb.zul"
      acceptCharset="UTF-8"
    >
      <input
        type="image"
        border="0"
        alt=""
        src="http://www.payulatam.com/img-secure-2015/boton_pagar_mediano.png"
        onClick="this.form.urlOrigen.value = window.location.href;"
      />
      <input
        name="buttonId"
        type="hidden"
        value="0wkmhmiXSPlk6ITR95wtmCTw3Mf/0BxJpBRLF3Ym/cxgj++2j3pt6g=="
      />
      <input name="merchantId" type="hidden" value="648157" />
      <input name="accountId" type="hidden" value="650624" />
      <input name="description" type="hidden" value="Suscripción anual alaU" />
      <input name="referenceCode" type="hidden" value="004" />
      <input name="amount" type="hidden" value="171000.00" />
      <input name="tax" type="hidden" value="0.00" />
      <input name="taxReturnBase" type="hidden" value="0.00" />
      <input name="currency" type="hidden" value="COP" />
      <input name="lng" type="hidden" value="es" />
      <input
        name="approvedResponseUrl"
        type="hidden"
        value="https://app.alau.org/payment_success"
      />
      <input
        name="declinedResponseUrl"
        type="hidden"
        value="https://app.alau.org/payment_failed"
      />
      <input
        name="pendingResponseUrl"
        type="hidden"
        value="https://app.alau.org/payment_failed"
      />
      <input name="sourceUrl" id="urlOrigen" value="" type="hidden" />
      <input name="buttonType" value="SIMPLE" type="hidden" />
      <input
        name="signature"
        value="c9f1a691d4897422777751449d970a7d6d2b6aa32f46cf7546dc9098bd291f4a"
        type="hidden"
      />
    </form>
  )
}
