import React from 'react'
import styled, { css } from 'styled-components'

import Icon from '../../../../components/Icon'
import Heading from '../../../../components/Heading'
import Text from '../../../../components/Text'
import { colors, mediaQueries, spacing } from '../../../../tokens'
import { secondsToTime } from '../../../../util/date'

interface StyledProps {
  completed: boolean
}

interface Props {
  image: string
  duration: string
  name: string
  isAccessPro: boolean
  completed: boolean
  userIsPro: boolean
  isPonderable: boolean
  onClick(): void
  onRestrictContent(): void
}

function VideoLesson({
  image,
  duration,
  name,
  isAccessPro,
  completed,
  userIsPro,
  isPonderable,
  onClick,
  onRestrictContent,
}: Props) {
  const time = secondsToTime(duration)
  const { h, m, s } = time
  const hours = h.toString() !== '00' ? `${h}:` : ''

  let locked = false
  if (!userIsPro) {
    locked = isAccessPro
  }

  function handleClickItem(): void {
    if (!locked) {
      onClick()
    } else {
      onRestrictContent()
    }
  }

  function getCornerIcon(): React.ReactNode {
    if (locked) {
      return (
        <Status completed={false}>
          <Icon name="padlock" status="default" />
        </Status>
      )
    }

    let cornerIcon: React.ReactNode | null = null
    if (isPonderable) {
      cornerIcon = (
        <Status completed={completed}>
          <Icon name="check" status="white" />
        </Status>
      )
    }
    return cornerIcon
  }

  return (
    <Wrapper onClick={handleClickItem}>
      <ImageContainer>
        {image ? (
          <Image src={image} alt={'nombre'} />
        ) : (
          <Icon name="play-arrow" />
        )}
      </ImageContainer>
      <Info>
        <Heading size={3}>{name}</Heading>
        {duration && (
          <Features>
            <Icon name="watch" />
            <FeatureDescription>
              <Text status="light">{`${hours}${m}:${s}`}</Text>
            </FeatureDescription>
          </Features>
        )}
      </Info>
      {getCornerIcon()}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  align-items: flex-start;
  cursor: pointer;
  display: flex;
  flex-wrap: wrap;
`

const ImageContainer = styled.div`
  align-items: center;
  align-self: center;
  display: flex;
  height: auto;
  justify-content: center;
  overflow: hidden;
  width: 100%;
  @media ${mediaQueries.smallMin} {
    width: 250px;
    height: 130px;
  }
`

const Image = styled.img`
  width: 100%;
  height: auto;
`

const Info = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: flex-start;
  padding: ${spacing.small};
  @media ${mediaQueries.smallMin} {
    padding: ${spacing.xsmall} ${spacing.small};
  }
`

const Features = styled.div`
  align-items: flex-start;
  display: flex;
  margin-top: ${spacing.xxsmall};
`
const FeatureDescription = styled.div`
  margin-left: ${spacing.xxsmall};
`

const Status = styled.div<StyledProps>`
  align-items: center;
  ${props =>
    props.completed
      ? css`
          background-color: ${colors.success().color};
        `
      : css`
          background-color: ${colors.light().background};
        `}
  border-radius: 50%;
  display: flex;
  justify-content: center;
  margin: ${spacing.small};
  @media ${mediaQueries.smallMin} {
    margin: ${spacing.xsmall};
  }
`

export default VideoLesson
