import React from 'react'
import styled from 'styled-components'
import Heading from '../../../components/Heading'
import { colors, spacing } from '../../../tokens'
import ImagePayPal from '../../../imagenes/paypal.jpg'

interface Props {
  id: string
}

const PayPal = ({ id }: Props) => {
  return (
    <Wrapper>
      <Heading size={3}>
        <strong>Pago con tarjeta de crédito</strong>
      </Heading>
      <Image src={ImagePayPal} alt="paypal" />
      <form
        action="https://www.paypal.com/cgi-bin/webscr"
        method="post"
        target="_top"
      >
        <input type="hidden" name="cmd" value="_s-xclick" />
        <input type="hidden" name="hosted_button_id" value={id} />
        <br />
        <input
          type="image"
          src="https://www.paypalobjects.com/es_ES/ES/i/btn/btn_subscribe_LG.gif"
          name="submit"
          alt="PayPal, la forma rápida y segura de pagar en Internet."
        />
        <img
          alt=""
          src="https://www.paypalobjects.com/es_XC/i/scr/pixel.gif"
          width="1"
          height="1"
        />
      </form>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: block;
  padding: 0px 0px ${spacing.small};
  margin-bottom: ${spacing.small};
  :not(:last-child) {
    border-bottom: 1px solid ${colors.light().background};
  }
`

const Image = styled.img`
  margin-top: ${spacing.xsmall};
`

export default PayPal
