import React, { Component } from 'react'

class Figura3 extends Component {
  render() {
    return (
      <svg
        width="120px"
        height="120px"
        viewBox="0 0 120 120"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xlink="http://www.w3.org/1999/xlink"
      >
        <defs>
          <filter
            x="-44.2%"
            y="-44.2%"
            width="188.3%"
            height="188.3%"
            filterUnits="objectBoundingBox"
            id="filter-1"
          >
            <feGaussianBlur
              stdDeviation="7.34375"
              in="SourceGraphic"
            ></feGaussianBlur>
          </filter>
        </defs>
        <g
          id="Page-1"
          stroke="none"
          strokeWidth="1"
          fill="none"
          fillRule="evenodd"
          opacity="0.205502717"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <g
            id="1.-login"
            transform="translate(-865.000000, -623.000000)"
            stroke="#A0AAAD"
            strokeWidth="16"
          >
            <polygon
              id="Stroke-8"
              filter="url(#filter-1)"
              points="959 655.949348 952.05043 717 891 710.050652 897.947402 649"
            ></polygon>
          </g>
        </g>
      </svg>
    )
  }
}

export default Figura3
