import React from 'react'
import QuizContainer from '../../containers/Quiz'
import Main from '../layouts/Main'

const Quiz = () => (
  <Main figuras={false}>
    <QuizContainer />
  </Main>
)

export default Quiz
