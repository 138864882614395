import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { resetFormPassword, sendResetPassword } from '../actions/auth'
import ResetPasswordComponent from '../components/ResetPassword'

const mapStateToProps = state => ({
  resetPassword: state.auth.resetPassword,
  isFetching: state.auth.isFetching,
  errorMessage: state.auth.errorMessage,
})

const mapDispatchToProps = dispatch => ({
  resetFormPassword: () => {
    dispatch(resetFormPassword())
  },
  sendResetPassword: email => {
    dispatch(sendResetPassword(email))
  },
})

const ResetPassword = withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ResetPasswordComponent),
)

export default ResetPassword
