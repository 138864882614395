import React from 'react'
import styled from 'styled-components'
import Button from '../Button'
import { SyncLoader } from 'react-spinners'
import { colors } from '../../tokens'

// Require Editor JS files.
import 'froala-editor/js/froala_editor.pkgd.min.js'

// Require Editor CSS files.
import 'froala-editor/css/froala_style.min.css'
import 'froala-editor/css/froala_editor.pkgd.min.css'

// Require Font Awesome.
import 'font-awesome/css/font-awesome.css'

import FroalaEditor from 'react-froala-wysiwyg'

import $ from 'jquery'
window.$ = $

const ContainerFooter = styled.div`
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  /* Estilos para el elemento radiobutton */
  .radio {
    display: inline-block;
    position: relative;
    padding-left: 30px;
    padding-right: 20px;
    margin-top: 12px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 16px;
    color: ${colors.base.black};
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  /* Hide the browser's default checkbox */
  .radio input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
  }

  /* Create a custom checkbox */
  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 21px;
    width: 21px;
    background-color: ${colors.base.white};
    border-radius: 50%;
    border: 1px solid ${colors.base.grayLightest};
    display: flex;
    justify-content: center;
    align-items: center;
  }

  /* When the checkbox is checked, add background */
  .radio input:checked ~ .checkmark {
    background-color: ${colors.base.white};
  }

  /* Create the checkmark/indicator (hidden when not checked) */
  .checkmark:after {
    content: '';
    position: absolute;
    display: none;
  }

  /* Show the checkmark when checked */
  .radio input:checked ~ .checkmark:after {
    display: block;
  }

  /* Style the checkmark/indicator */
  .radio .checkmark:after {
    width: 11px;
    height: 11px;
    border-radius: 50%;
    background-color: ${colors.base.purple};
  }
`
function Create(props) {
  const {
    froalaSignature,
    description,
    type,
    isFetching,
    onChangeEditor,
    onChange,
    onSubmit,
  } = props

  const config = {
    key: process.env.REACT_APP_FROALA_KEY,
    imageUploadToS3: froalaSignature,
    heightMin: 200,
    placeholderText: 'Ingresa aquí tu solución o comentario en texto o imagen',
    pluginsEnabled: ['image', 'link', 'specialCharacters'],
    linkInsertButtons: ['linkBack'],
    linkEditButtons: ['linkOpen', 'linkEdit', 'linkRemove'],
    toolbarButtons: [
      'insertImage',
      'insertLink',
      'subscript',
      'superscript',
      'insertHR',
      'specialCharacters',
    ],
  }

  return (
    <div>
      <form onSubmit={onSubmit}>
        <FroalaEditor
          tag="textarea"
          config={config}
          model={description}
          onModelChange={onChangeEditor}
        />
        <ContainerFooter>
          <div className="control">
            <div>¿Tu comentario es una solución a este ejercicio?</div>
            <label className="radio">
              Sí
              <input
                type="radio"
                name="type"
                onChange={onChange}
                value="1"
                checked={type === '1'}
              />
              <span className="checkmark" />
            </label>
            <label className="radio">
              No
              <input
                type="radio"
                name="type"
                onChange={onChange}
                value="0"
                checked={type === '0'}
                required
              />
              <span className="checkmark" />
            </label>
          </div>
          <div>
            {!isFetching && <Button type="submit">Enviar</Button>}
            <div className="text-center">
              <SyncLoader color={colors.base.purple} loading={isFetching} />
            </div>
          </div>
        </ContainerFooter>
      </form>
    </div>
  )
}

export default Create
