import { isValidDNI } from '../util/validations'

export function validateDNI(message) {
  return this.test('validateDNI', message, function(value) {
    const { path, createError } = this

    if (!value) {
      return createError({ path, message: message ?? 'Campo requerido' })
    }

    const success = isValidDNI(value)
    if (!success) {
      return createError({ path, message: message ?? 'Cédula inválida' })
    }

    return true
  })
}
