import React, { Component } from 'react'

class IconoNose extends Component {
  render() {
    return (
      <svg
        width="41px"
        height="42px"
        viewBox="0 0 41 42"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xlink="http://www.w3.org/1999/xlink"
      >
        <g
          id="Page-1"
          stroke="none"
          strokeWidth="1"
          fill="none"
          fillRule="evenodd"
        >
          <g
            id="1.-login-copy-11"
            transform="translate(-849.000000, -455.000000)"
            stroke="#A0AAAD"
            strokeWidth="2"
          >
            <g id="Group-7" transform="translate(431.000000, 455.000000)">
              <g id="Group-5" transform="translate(419.000000, 1.000000)">
                <g id="thin-0153_delete_exit_remove_close">
                  <g id="Group">
                    <path d="M11,20 L28,20" id="Shape" />
                    <ellipse
                      id="Oval"
                      cx="19.5114458"
                      cy="19.6443"
                      rx="19.4445917"
                      ry="19.577384"
                    />
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </svg>
    )
  }
}

export default IconoNose
