import React, { Component } from 'react'
import { Redirect } from 'react-router-dom'

import Button from '../Button'
import GlobalHeader from '../Header'
import Header from './Header'
import Instructions from './Instructions'
import Loading from '../../pages/Loading'
import Message from '../Message'
import Navigation from './Navigation'
import PropTypes from 'prop-types'
import Question from './Question'
import Results from './Results'
import Timer from './Timer'
import { secondsToTime } from '../../util/date'
import { shuffle } from '../../util/array'
import styled from 'styled-components'
import { UserContext } from '../../context/userContext'
import { colors } from '../../tokens'

class Quiz extends Component {
  static contextType = UserContext
  static propTypes = {
    attempt: PropTypes.object.isRequired,
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    time: PropTypes.number.isRequired,
    showResults: PropTypes.bool.isRequired,
    randomOptions: PropTypes.bool.isRequired,
    userCanFillQuiz: PropTypes.bool.isRequired,
    content: PropTypes.string,
    category: PropTypes.string,
    showFinalQuiz: PropTypes.bool,
    approved: PropTypes.bool,
    timeToRetake: PropTypes.number,
    isValidTimeToRetake: PropTypes.bool,
    minutesRemainingToRetake: PropTypes.number,
    questions: PropTypes.array.isRequired,
    answers: PropTypes.array.isRequired,
    certificateKey: PropTypes.string,
    isFetching: PropTypes.bool.isRequired,
    errorMessage: PropTypes.string,
    redirectTo: PropTypes.string,
    fetchQuiz: PropTypes.func.isRequired,
    createQuizAttempt: PropTypes.func.isRequired,
    addAnswer: PropTypes.func.isRequired,
  }

  constructor(props) {
    super(props)

    this.state = {
      started: false,
      startAt: null,
      index: 0,
      total: 0,
      question: null,
      options: null,
      answer: null,
    }

    this.start = this.start.bind(this)
    this.handleWindowClose = this.handleWindowClose.bind(this)
    this.handleKeyDown = this.handleKeyDown.bind(this)
    this.handleNavigation = this.handleNavigation.bind(this)
    this.navigate = this.navigate.bind(this)
    this.submitQuiz = this.submitQuiz.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.redirectToContent = this.redirectToContent.bind(this)
  }

  componentDidMount() {
    this.props.fetchQuiz(this.props.match.params.quiz)
    document.addEventListener('keydown', this.handleKeyDown)
    window.scrollTo(0, 0)
    const user = this.context
    this.setState({ user: user.user })
  }

  componentDidUpdate(prevProps) {
    if (this.props.id !== prevProps.id) {
      const index = 0
      const total = this.props.questions.length
      const question = total > 0 ? this.props.questions[index] : null
      const options = question
        ? this.handleRandomOptions(question.opciones)
        : []
      const answer = question ? this.props.answers[index].option : null

      this.setState({
        index,
        total,
        question,
        options,
        answer,
      })
    }
  }

  componentWillUnmount() {
    window.removeEventListener('beforeunload', this.handleWindowClose)
    document.removeEventListener('keydown', this.handleKeyDown)
    this.props.resetQuiz(this.props.history)
  }

  start(event) {
    event.preventDefault()
    window.addEventListener('beforeunload', this.handleWindowClose)
    this.setState({
      started: true,
      startAt: new Date(),
    })
  }

  handleWindowClose(event) {
    const dialogText = 'Los cambios que realizaste no serán guardados.'
    event.returnValue = dialogText
    return dialogText
  }

  handleKeyDown(event) {
    event.preventDefault()
    const { index } = this.state
    if (event.keyCode === 37) {
      this.navigate(index - 1)
    } else if (event.keyCode === 39) {
      this.navigate(index + 1)
    }
    return
  }

  handleNavigation(value, event) {
    event.preventDefault()
    this.navigate(value)
  }

  handleRandomOptions(opciones) {
    if (this.props.randomOptions) {
      return shuffle([...opciones])
    }
    return [...opciones]
  }

  navigate(index) {
    if (!this.state.started || index < 0 || index >= this.state.total) {
      return
    }

    const question = this.props.questions[index]
    const options = question ? this.handleRandomOptions(question.opciones) : []
    const answer = question ? this.props.answers[index].option : null

    this.setState({
      index,
      question,
      options,
      answer,
    })
    window.scrollTo(0, 0)
  }

  handleChange(event) {
    const target = event.target
    const value = target.value

    this.setState({
      answer: value,
    })

    this.props.addAnswer(this.state.index, value)
  }

  submitQuiz() {
    const answers = {}
    this.props.answers.forEach(answer => {
      answers[answer.question] = answer.option
    })

    this.props.createQuizAttempt(this.props.id, this.state.startAt, answers)
    document.removeEventListener('keydown', this.handleKeyDown)
  }

  redirectToContent() {
    this.props.history.push(
      `/cursos/${this.props.courseSlug}/${
        this.props.content ? this.props.content : ''
      }`,
    )
  }

  render() {
    const {
      attempt,
      name,
      description,
      time,
      showResults,
      userCanFillQuiz,
      content,
      category,
      showFinalQuiz,
      approved,
      timeToRetake,
      isValidTimeToRetake,
      minutesRemainingToRetake,
      answers,
      certificateKey,
      isFetching,
      errorMessage,
      redirectTo,
      resetQuizAndRedirect,
      history,
    } = this.props
    const {
      started,
      index,
      total,
      question,
      options,
      answer,
      user,
    } = this.state

    if (isFetching) {
      return <Loading />
    }

    if (errorMessage === 'Forbidden' && redirectTo !== '') {
      window.location.replace(redirectTo)
      return null
    }

    if (category === 'final' && !showFinalQuiz) {
      return (
        <>
          <GlobalHeader />
          <CenterContainer>
            <Message neutral title={'¡Lo sentimos!'}>
              Aún no has completado todos los requerimientos para dar el examen
              de certificación. Recuerda completar el 100% de cada módulo.
            </Message>
            <CenterButton>
              {content ? (
                <Button onClick={this.redirectToContent}>Continuar</Button>
              ) : (
                <Button onClick={() => resetQuizAndRedirect(history)} primary>
                  Salir
                </Button>
              )}
            </CenterButton>
          </CenterContainer>
        </>
      )
    }

    if (category === 'final' && approved) {
      return (
        <>
          <GlobalHeader />
          <CenterContainer>
            <Message success title={'¡Felicidades!'}>
              Ya has dado este examen, ahora puedes descargar tu certificado.
            </Message>
            <CenterButton>
              <div className="mx1">
                <Button
                  onClick={() =>
                    this.props.history.push(`/certificates/${certificateKey}`)
                  }
                >
                  Ver mi Certificado
                </Button>
              </div>
              <div className="mx1">
                <Button onClick={() => resetQuizAndRedirect(history)} primary>
                  Salir
                </Button>
              </div>
            </CenterButton>
          </CenterContainer>
        </>
      )
    }

    if (category === 'final' && !isValidTimeToRetake) {
      const time = secondsToTime(minutesRemainingToRetake * 60)
      const { h, m } = time
      return (
        <>
          <GlobalHeader />
          <CenterContainer>
            <Message neutral title={'¡Lo sentimos!'}>
              Ya has dado este examen, debes esperar {h}:{m} horas.
            </Message>
            <CenterButton>
              {content ? (
                <Button onClick={this.redirectToContent}>
                  Continuar{content}
                </Button>
              ) : (
                <Button onClick={() => resetQuizAndRedirect(history)} primary>
                  Salir
                </Button>
              )}
            </CenterButton>
          </CenterContainer>
        </>
      )
    }

    if (category === 'final' && !user.nombreCertificado) {
      return (
        <Redirect
          to={{
            pathname: `/verificar-certificado/${this.props.courseSlug}`,
            state: { from: this.props.location },
          }}
        />
      )
    }

    if (!userCanFillQuiz) {
      return (
        <>
          <GlobalHeader />
          <CenterContainer>
            <Message neutral title={'¡Lo sentimos!'}>
              Ya has realizado esta evaluación
            </Message>
            <CenterButton>
              {content ? (
                <Button onClick={this.redirectToContent}>Continuar</Button>
              ) : (
                <Button onClick={() => resetQuizAndRedirect(history)} primary>
                  Salir
                </Button>
              )}
            </CenterButton>
          </CenterContainer>
        </>
      )
    }

    if (errorMessage || (!isFetching && total === 0)) {
      return (
        <>
          <GlobalHeader />
          <CenterContainer>
            <Message neutral title={'¡Lo sentimos!'}>
              No existen ejercicios
            </Message>
            <CenterButton>
              {content ? (
                <Button onClick={this.redirectToContent} primary>
                  Continuar
                </Button>
              ) : (
                <Button onClick={() => resetQuizAndRedirect(history)} primary>
                  Salir
                </Button>
              )}
            </CenterButton>
          </CenterContainer>
        </>
      )
    }

    if (attempt.results && attempt.results.length > 0) {
      return (
        <Results
          id={attempt._id}
          name={name}
          showResults={showResults}
          results={attempt.results}
          correct={attempt.correct}
          total={attempt.total}
          onClick={() => resetQuizAndRedirect(history)}
          content={content}
          redirectToContent={this.redirectToContent}
          category={category}
          approved={attempt.approved}
          certificateKey={certificateKey}
          timeToRetake={timeToRetake}
          history={this.props.history}
        />
      )
    }

    return (
      <Container>
        {!started && <GlobalHeader />}
        <div className="pure-g">
          <div className="pure-u-1 pure-u-md-1-4 height100">
            <Timer seconds={time} onEnd={this.submitQuiz} start={started} />
            <Navigation
              index={index}
              items={answers}
              navigate={this.navigate}
            />
          </div>

          {!started ? (
            <div className="pure-u-1 pure-u-md-3-4 px2">
              <Instructions
                name={name}
                description={description}
                seconds={time}
                onClick={this.start}
              />
            </div>
          ) : (
            <div className="pure-u-1 pure-u-md-3-4 px2">
              <Header
                index={index}
                total={total}
                answered={answers.reduce(
                  (answered, item) => answered + (item.option ? 1 : 0),
                  0,
                )}
                name={name}
                onClick={this.handleNavigation}
                onSubmit={this.submitQuiz}
              />
              <Question
                question={question}
                options={options}
                answer={answer}
                onChange={this.handleChange}
              />
            </div>
          )}
        </div>
      </Container>
    )
  }
}

const Container = styled.div`
  div.height100 {
    background: ${colors.base.grayBackground};
    height: 100vh;

    @media screen and (max-width: 767px) {
      height: auto;
    }
  }
`
const CenterContainer = styled.div`
  align-items: center;
  flex-direction: column;
  width: 55%;
  display: flex;
  justify-content: center;
  margin: 40px auto;
  @media screen and (max-width: 767px) {
    width: 100%;
  }

  div.height100 {
    background: ${colors.base.grayBackground};
    height: 100vh;

    @media screen and (max-width: 767px) {
      height: auto;
    }
  }
`
const CenterButton = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  padding-bottom: 20px;
`

export default Quiz
