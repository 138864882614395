import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Alert from './Alert'
import Button from './Button'
import LogoSimulador from './iconos/LogoSimulador'
import Panel from './paneles/Panel'
import { H1 } from './textos/Heading'
import { Link } from 'react-router-dom'
import { SyncLoader } from 'react-spinners'
import { colors } from '../tokens'
import {
  strengthIndicator,
  strengthColor,
  strengthMessage,
} from './../util/password'

const Container = styled.div`
  margin-bottom: 50px;
  display: block;

  @media screen and (min-width: 568px) {
    width: 568px;
  }

  *:focus {
    outline: none;
  }

  .field {
    margin-bottom: 10px;
  }

  .control {
    &::placeholder {
      /* Chrome, Firefox, Opera, Safari 10.1+ */
      color: ${colors.base.grayLight};
      opacity: 1; /* Firefox */
    }
    &:-ms-input-placeholder {
      /* Internet Explorer 10-11 */
      color: ${colors.base.grayLight};
    }
    &::-ms-input-placeholder {
      /* Microsoft Edge */
      color: ${colors.base.grayLight};
    }
  }

  /* Estilos para el elemento input */
  input.input {
    display: block;
    width: 100%;
    font-size: inherit;
    padding: 14px 12px;
    background-color: ${colors.base.white};
    background-image: none;
    border: 1px solid ${colors.base.grayLightest};
    border-radius: 8px;
    margin-top: 10px;
  }

  .input-feedback {
    color: ${colors.base.red};
    font-size: 13px;
    margin-bottom: 10px;
    &.error {
      color: ${colors.base.red};
    }
    &.warning {
      color: ${colors.base.yellow};
    }
    &.success {
      color: ${colors.base.green};
    }
  }
`

const Title = styled(H1)`
  margin-bottom: 0px;
`

const Login = styled.div`
  font-size: 12px;
  a {
    text-decoration: none;
  }
`

class NewPassword extends Component {
  static propTypes = {
    validToken: PropTypes.bool.isRequired,
    isFetching: PropTypes.bool.isRequired,
    errorMessage: PropTypes.string,
    changePassword: PropTypes.func.isRequired,
    validateToken: PropTypes.func.isRequired,
    changedPassword: PropTypes.bool.isRequired,
  }

  constructor(props) {
    super(props)

    this.state = {
      password: '',
      confirmPassword: '',
      strengthPassword: null,
      passwordMatch: true,
    }

    this.handlePasswordChange = this.handlePasswordChange.bind(this)
    this.handleConfirmPassword = this.handleConfirmPassword.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
  }

  handlePasswordChange(event) {
    const target = event.target
    const value = target.value
    const name = target.name

    this.setState({
      [name]: value,
      strengthPassword: strengthIndicator(value),
      passwordMatch: value === this.state.confirmPassword,
    })
  }

  handleConfirmPassword(event) {
    const target = event.target
    const value = target.value
    const name = target.name

    this.setState({
      [name]: value,
      passwordMatch: this.state.password === value,
    })
  }

  handleSubmit(event) {
    event.preventDefault()
    const { password } = this.state
    if (this.state.passwordMatch) {
      this.props.changePassword(this.props.match.params.token, password)
    }
  }

  componentDidMount() {
    this.props.validateToken(this.props.match.params.token)
  }

  render() {
    const { validToken, isFetching, errorMessage, changedPassword } = this.props
    const {
      password,
      strengthPassword,
      confirmPassword,
      passwordMatch,
    } = this.state
    const title = changedPassword
      ? 'Tu contraseña ha sido cambiada'
      : 'Ha pasado mucho tiempo'
    const text = changedPassword
      ? 'Ahora puedes iniciar sesión con tu nueva contraseña.'
      : 'El link enviado a tu correo ya no es válido. Recuerda que, una vez enviado el mail, tienes 3 horas para cambiar tu contraseña.'

    return (
      <Container className="px1 center-block">
        <Alert error={errorMessage} />

        <div className="my2">
          <LogoSimulador />
        </div>

        <Panel borderColor={'transparent'} className="text-center">
          {validToken && (
            <div>
              <Title>Escribe tu nueva contraseña</Title>
              <div className="mx1">
                <form className="form" onSubmit={this.handleSubmit}>
                  <div className="field">
                    <div className="control">
                      <input
                        className="input"
                        placeholder="Contrasena"
                        type="password"
                        name="password"
                        value={password}
                        onChange={this.handlePasswordChange}
                        required
                      />
                      {strengthPassword !== null && password !== '' && (
                        <div
                          className={`input-feedback ${strengthColor(
                            strengthPassword,
                          )}`}
                        >
                          {strengthMessage(strengthPassword)}
                        </div>
                      )}
                      <input
                        className="input"
                        placeholder="Repite la Contrasena"
                        type="password"
                        name="confirmPassword"
                        value={confirmPassword}
                        onChange={this.handleConfirmPassword}
                        required
                      />
                      {!passwordMatch && (
                        <div className="input-feedback error">
                          Las contraseñas no coinciden
                        </div>
                      )}
                    </div>
                  </div>

                  <div className="field my2">
                    <div className="control">
                      {!isFetching && (
                        <Button type="submit">Cambiar contraseña</Button>
                      )}
                    </div>
                  </div>
                </form>
              </div>
            </div>
          )}

          {!validToken && !isFetching && (
            <div>
              <Title>{title}</Title>
              <p>{text}</p>
            </div>
          )}
          <Login>
            <Link to={'/'}>Ir al inicio</Link>
          </Login>
          <div className="my1">
            <SyncLoader color={colors.base.purple} loading={isFetching} />
          </div>
        </Panel>
      </Container>
    )
  }
}

export default NewPassword
