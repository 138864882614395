import React from 'react'
import styled from 'styled-components'

import Icon from '../Icon'
import Text from '../Text'
import { spacing } from '../../tokens'

interface Props {
  hours: number
}
const HeaderHours = ({ hours }: Props) => (
  <IconWrapper>
    <Margin>
      <Icon status="light" name="watch" size="xsmall"></Icon>
    </Margin>
    <Text status="light">{` ${hours.toString()} horas`}</Text>
  </IconWrapper>
)

const IconWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  flex-grow: 1;
  padding-left: ${spacing.xxsmall};
`

const Margin = styled.div`
  margin-right: ${spacing.xxsmall};
`

export default HeaderHours
