import React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import DangerIcon from '../iconos/figuras/IconoError'
import SuccessIcon from '../iconos/figuras/IconoCorrecto'
import NeutralIcon from '../iconos/figuras/IconoNose'
import WarningIcon from '../iconos/figuras/IconoWarning'
import { colors, fonts } from '../../tokens'

const Container = styled.div`
  font-family: ${fonts.family.default};
  background: ${colors.base.white};
  color: ${colors.base.purple};
  border: 1px solid ${colors.base.purple};
  border-radius: 8px;
  margin: 10px;
  padding: 15px;
  display: flex;
  align-items: center;
  text-align: left;
  font-size: ${fonts.size.default};

  ${props =>
    props.primary &&
    css`
      background: ${colors.base.purple};
      color: ${colors.base.white};
      border: 1px solid ${colors.base.white};
    `} ${props =>
  props.success &&
  css`
    background: #f6ffed;
    color: ${colors.base.green};
    border: 1px solid ${colors.base.green};
  `} ${props =>
  props.danger &&
  css`
    background: #fff2f1;
    color: ${colors.base.red};
    border: 1px solid ${colors.base.red};
  `} ${props =>
  props.warning &&
  css`
    background: ${colors.base.white};
    color: ${colors.base.purple};
    border: 1px solid ${colors.base.grayLight};
  `} ${props =>
  props.neutral &&
  css`
    background: transparent;
    color: ${colors.base.grayLight};
    border: 1px solid ${colors.base.grayLight};
  `};
`

const Icon = styled.div`
  padding: 0px 15px;
`

function Message(props) {
  return (
    <Container {...props}>
      <Icon>
        {props.success && <SuccessIcon />}
        {props.danger && <DangerIcon />}
        {props.neutral && <NeutralIcon />}
        {props.warning && <WarningIcon />}
      </Icon>
      <div>
        <strong>{props.title}</strong>
        <div>
          <div>{props.children}</div>
        </div>
      </div>
    </Container>
  )
}

Message.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
  primary: PropTypes.bool,
  success: PropTypes.bool,
  danger: PropTypes.bool,
  neutral: PropTypes.bool,
  warning: PropTypes.bool,
}

export default Message
