import React, { useState } from 'react'
import { Redirect } from 'react-router'
import styled from 'styled-components'
import { useAsync } from 'react-async'
import Button from '../components/Button'
import Card from '../components/Card'
import Heading from '../components/Heading'
import Column from '../components/Column'
import Link from '../components/Link'
import Logo from '../components/Logo'
import Text from '../components/Text'
import TextInput from '../components/TextInput'
import { login } from '../lib/api'
import { mediaQueries, spacing } from '../tokens'
import { useUser } from '../context/userContext'

interface Props {
  [propName: string]: any
}

interface User {
  email: string
  password: string
}

function LoginComponent({ location }: Props) {
  const { user, reloadUser } = useUser()
  const [credentials, setCredentials] = useState<User>({
    email: '',
    password: '',
  })
  const { error, isLoading, run } = useAsync({
    deferFn: args => login(args[0], args[1]),
    onResolve: () => reloadUser(),
  })

  function handleSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault()
    run(credentials)
  }

  function handleChange(value: string, id: string) {
    setCredentials({
      ...credentials,
      [id]: value,
    })
  }

  function getURLBeforeSingIn() {
    let URLTo = '/'
    if (location.state && location.state.from.pathname) {
      URLTo = location.state.from.pathname
    }
    return URLTo
  }

  if (user) {
    const URLTo = getURLBeforeSingIn()
    return <Redirect to={URLTo} />
  }

  return (
    <Column background>
      <Logo />
      <LoginWrapper>
        <Card alignCenter>
          <Card.Body paddingSize="large">
            <Heading>Inicia sesión</Heading>
            <Text>¿No tienes una cuenta? </Text>
            <Link to={'/registro'}>Crea una cuenta</Link>
            <WrapperForm>
              <form onSubmit={handleSubmit}>
                <TextInput
                  label="Email"
                  name="email"
                  onChange={handleChange}
                  required={true}
                  type="email"
                  value={credentials.email}
                />
                <TextInput
                  label="Contraseña"
                  name="password"
                  onChange={handleChange}
                  required={true}
                  type="password"
                  value={credentials.password}
                />
                {error && (
                  <MessageContainer>
                    <Text status="danger">{error.message}</Text>
                  </MessageContainer>
                )}
                <Button disabled={isLoading} type="submit">
                  Iniciar Sesión
                </Button>
              </form>
            </WrapperForm>
            <p>
              <Link to={'/reset_password'}>¿Olvidaste tu contraseña?</Link>
            </p>
          </Card.Body>
        </Card>
      </LoginWrapper>
    </Column>
  )
}

const LoginWrapper = styled.div`
  width: 420px;
  margin-top: ${spacing.large};

  @media ${mediaQueries.xsmallMax} {
    width: 100%;
  }
`

const WrapperForm = styled.div`
  margin: 15px 0px;
`

const MessageContainer = styled.div`
  margin-bottom: ${spacing.small};
`

export default LoginComponent
