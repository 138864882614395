import React from 'react'
import styled from 'styled-components'

import Circles from '../ProgressInPercentage/Circles'
import Text from '../Text'

interface Props {
  color: string
  percentage: number
  progress: string
}

const ProgressOfContent = ({ color, percentage, progress }: Props) => {
  return (
    <Wrapper>
      <Circles color={color} percentage={percentage} />
      <Textcontainer>
        <Text status="white">{`${progress}`}</Text>
      </Textcontainer>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  width: 60px;
  height: 60px;
  transform: rotate(-90deg);
`

const Textcontainer = styled.span`
  position: absolute;
  display: flex;
  top: 0px;
  right: 0px;
  left: 0px;
  bottom: 0px;
  align-self: center;
  justify-content: center;
  align-items: center;
  width: 60px;
  height: 60px;
  transform: rotate(90deg);
`

export default ProgressOfContent
