import React from 'react'
import styled from 'styled-components'
import { BarLoader } from 'react-spinners'
import Logo from '../components/Logo'
import { colors } from '../tokens'

function Loading({ logo = false }: { logo?: boolean }) {
  return (
    <Container>
      <div aria-label="cargando">
        {logo && <Logo size="large" />}
        <BarLoader color={colors.base.purple} width={139} widthUnit="px" />
      </div>
    </Container>
  )
}

const Container = styled.div`
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
`

export default Loading
