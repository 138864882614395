import React from 'react'

const Dislike = ({ active = false }) => (
  <svg
    width="19px"
    height="18px"
    viewBox="0 0 19 18"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xlink="http://www.w3.org/1999/xlink"
  >
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g
        id="thin-0664_dislike_thumb_down_vote"
        transform="translate(1.000000, 0.000000)"
      >
        <g
          id="Group"
          transform="translate(0.000000, 0.750000)"
          stroke={active ? '#4E5974' : '#A0AAAD'}
        >
          <path d="M15.457275,7.1433 L14.0715,7.1433" id="Shape" />
          <path d="M14.69805,4.873575 L13.702725,4.873575" id="Shape" />
          <path
            d="M4.87725,1.87185 C8.906025,0.495075 13.702725,0.579825 13.702725,0.579825 C15.54975,0.579825 15.14745,2.60385 13.82145,2.60385 L13.2906,2.60385"
            id="Shape"
          />
          <path
            d="M4.87725,8.72655 C4.87725,8.72655 8.6757,11.7159 8.403225,14.84265 C8.196525,17.2146 12.594975,15.400575 10.146675,9.4131 L15.574425,9.4131 C17.08845,9.4131 17.08395,7.143375 15.574425,7.143375 L15.4572,7.143375 C16.970775,7.143375 16.966725,4.87365 15.4572,4.87365 L14.69805,4.87365 C16.211625,4.87365 16.207575,2.603925 14.69805,2.603925 L13.8216,2.604"
            id="Shape"
          />
          <rect
            id="Rectangle-path"
            x="0.375"
            y="0.375"
            width="4.5"
            height="9"
          />
        </g>
        <rect id="Rectangle-path" x="0" y="0" width="18" height="18" />
      </g>
    </g>
  </svg>
)

export default Dislike
