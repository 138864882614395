import client from './client-api'
import { deleteStorage, loadState, saveState } from '../util/localStorage'
import {
  SignUpCredentials,
  formatCredentialsToSendAPI,
} from './formatApiObjects'
interface Credentials {
  email: string
  password: string
}

const localStorageKey = '__alau_token__'

const SERVICES_URL = process.env.REACT_APP_SERVICES_URL

async function login(credentials?: Credentials, accessToken?: string) {
  let user
  if (credentials) {
    user = await client('signin', { body: credentials })
  } else {
    user = await client('usuarios', { body: { ['access_token']: accessToken } })
  }

  saveState(localStorageKey, user.jwt)

  return user
}

async function signup(credentials?: SignUpCredentials, accessToken?: string) {
  let user
  if (credentials) {
    const credentialsToSendAPI = formatCredentialsToSendAPI(credentials)
    user = await client('signup', { body: credentialsToSendAPI })
  } else {
    user = await client('usuarios', { body: { ['access_token']: accessToken } })
  }

  saveState(localStorageKey, user.jwt)

  return user
}

function logout(removeDataToAuthReduxState: () => void) {
  deleteStorage()
  removeDataToAuthReduxState()
}

async function getCourses() {
  const courses = await client('courses').catch(error => {
    return Promise.reject(error)
  })
  return Promise.resolve(courses)
}

async function getCourse({ slug }: any) {
  const course = await client(`courses/${slug}`).catch(error => {
    return Promise.reject(error)
  })
  return Promise.resolve(course)
}

async function getCourseRequirements({ slug }: any) {
  const courseRequirements = await client(`requirements/${slug}`).catch(
    error => {
      return Promise.reject(error)
    },
  )
  return Promise.resolve(courseRequirements)
}

async function getCourseBanners({ course }: any) {
  const bannersOfCourse = await client(`banners/${course}`).catch(error => {
    return Promise.reject(error)
  })

  return Promise.resolve(bannersOfCourse)
}

async function getCourseContent(contentId: string) {
  const content = await client(`contents/${contentId}`).catch(error => {
    return Promise.reject(error)
  })
  return Promise.resolve(content)
}

async function getUser() {
  const token = loadState(localStorageKey)
  if (!token) {
    return Promise.resolve(null)
  }

  return client('me').catch(error => {
    deleteStorage()
    return Promise.reject(error)
  })
}

async function getCountries() {
  const countries = await client('countries/').catch(error => {
    return Promise.reject(error)
  })

  return Promise.resolve(countries)
}

async function getCities(country: any) {
  const cities = await client(`cities/${country}`).catch(error => {
    return Promise.reject(error)
  })

  return Promise.resolve(cities)
}

async function courseRequirementsOfUser(body: any, course: string) {
  const requirementsOfUser = await client(`courses/${course}/requirements`, {
    body,
  }).catch(error => {
    return Promise.reject(error)
  })
  return Promise.resolve(requirementsOfUser)
}

async function markLessonAsCompleted(course: string, lesson: string) {
  const res = await client(`progresses/${course}/${lesson}`).catch(error => {
    return Promise.reject(error)
  })

  return Promise.resolve(res)
}

async function validateDiscountCode(code: string) {
  const discountCode = await client(`discount_code/${code}`).catch(error => {
    return Promise.reject(error)
  })
  return Promise.resolve(discountCode)
}

async function getCertificate({ key }: any) {
  const certificate = await client(`user_certificates/${key}`).catch(error => {
    return Promise.reject(error)
  })
  return Promise.resolve(certificate)
}

async function updateUserNameCertificate(nameCertificate: string) {
  const res = await client('update_user_name_certificate', {
    body: { nameCertificate },
  }).catch(error => {
    return Promise.reject(error)
  })
  return Promise.resolve(res)
}

async function getPreviewCertificate(courseSlug: string, name: string) {
  const res = await fetch(
    `${SERVICES_URL}/api/certificate/preview/${courseSlug}?name=${name}`,
  )
  const data = await res.json()
  if (!res.ok) {
    return Promise.reject(data.message)
  }
  return Promise.resolve(data)
}

async function getMyCourses() {
  const myCourses = await client('my-courses').catch(error => {
    return Promise.reject(error)
  })
  return Promise.resolve(myCourses)
}

async function createPractice({ body }: any) {
  const practice = await client('simuladores', { body }).catch(error => {
    return Promise.reject(error)
  })
  return Promise.resolve(practice)
}

async function createAnswer({ body }: any) {
  const answer = await client('respuestas', { body }).catch(error => {
    return Promise.reject(error)
  })
  return Promise.resolve(answer)
}

async function getBanners() {
  const banners = await client('banners-home').catch(error => {
    return Promise.reject(error)
  })

  return Promise.resolve(banners)
}

export {
  login,
  signup,
  logout,
  getCourse,
  getCourses,
  getCourseBanners,
  getCourseContent,
  getUser,
  getCourseRequirements,
  getCountries,
  getCities,
  courseRequirementsOfUser,
  markLessonAsCompleted,
  validateDiscountCode,
  getCertificate,
  updateUserNameCertificate,
  getPreviewCertificate,
  getMyCourses,
  createPractice,
  createAnswer,
  getBanners,
}
