import React, { Component } from 'react'
import * as gtag from '../util/gtag'
import ReactPixel from 'react-facebook-pixel'

const withAnalyticsTracking = Page => {
  class ComponentWithAnalytics extends Component {
    componentDidMount() {
      gtag.pageview(this.props.match.url)
      ReactPixel.init('317135625114508')
      ReactPixel.pageView()
    }
    render() {
      return <Page {...this.props} />
    }
  }
  return ComponentWithAnalytics
}

export default withAnalyticsTracking
