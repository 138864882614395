import React from 'react'
import BgIzquierda from '../iconos/figuras/BgIzquierda'
import BgDerecha from '../iconos/figuras/BgDerecha'
import Figura1 from '../iconos/figuras/Figura1'
import Figura2 from '../iconos/figuras/Figura2'
import Figura3 from '../iconos/figuras/Figura3'
import './Main.css'

class Main extends React.Component {
  render() {
    const { children, figuras = false } = this.props
    return (
      <div>
        {figuras && (
          <div className="bg-container">
            <div className="bg-left">
              <BgIzquierda />
            </div>
            <div className="bg-right">
              <BgDerecha />
            </div>
            <div className="fig1">
              <Figura1 />
            </div>
            <div className="fig2">
              <Figura2 />
            </div>
            <div className="fig3">
              <Figura3 />
            </div>
          </div>
        )}
        <div>{children}</div>
      </div>
    )
  }
}

export default Main
