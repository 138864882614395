import { combineReducers } from 'redux'

const comments = (state = [], action) => {
  switch (action.type) {
    case 'FETCH_COMMENT_SUCCESS':
      return [...action.response.comments]
    case 'REACTION_COMMENT_SUCCESS': {
      const newComments = []
      state.forEach(comment => {
        if (comment._id === action.comment) {
          if (action.reaction === 1) {
            if (comment.userLike) {
              comment.likesNumber--
              comment.userLike = false
            } else {
              comment.likesNumber++
              comment.userLike = true
            }
            if (comment.userDislike) {
              comment.dislikesNumber--
              comment.userDislike = false
            }
          } else {
            if (comment.userDislike) {
              comment.dislikesNumber--
              comment.userDislike = false
            } else {
              comment.dislikesNumber++
              comment.userDislike = true
            }
            if (comment.userLike) {
              comment.likesNumber--
              comment.userLike = false
            }
          }
        }
        newComments.push(comment)
      })
      return newComments
    }
    case 'CREATE_COMMENT_SUCCESS': {
      const actualState = [...state]
      actualState.unshift(action.response.commentComplete)
      return actualState
    }
    case 'RESET_COMMENT_SUCCESS':
      return []
    default:
      return state
  }
}

const isFetching = (state = false, action) => {
  switch (action.type) {
    case 'CREATE_COMMENT_REQUEST':
    case 'FETCH_COMMENT_REQUEST':
      return true
    case 'CREATE_COMMENT_SUCCESS':
    case 'CREATE_COMMENT_FAILURE':
    case 'FETCH_COMMENT_FAILURE':
    case 'FETCH_COMMENT_SUCCESS':
      return false
    default:
      return state
  }
}

const errorMessage = (state = null, action) => {
  switch (action.type) {
    case 'CREATE_COMMENT_FAILURE':
    case 'FETCH_COMMENT_FAILURE':
      return action.message
    case 'CREATE_COMMENT_REQUEST':
    case 'CREATE_COMMENT_SUCCESS':
    case 'FETCH_COMMENT_REQUEST':
    case 'FETCH_COMMENT_SUCCESS':
      return null
    default:
      return state
  }
}

const comment = combineReducers({
  comments,
  isFetching,
  errorMessage,
})

export default comment
